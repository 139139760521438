import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'

import { faClipboardCheck, faCheckDouble, faStar as faStarFull, faUsers, faUser, faPlus, faCheck, faTimes, faUserAstronaut, faUserPlus, faMinus, faQuoteLeft, faCalendarCheck, faComments, faReply, faTasks, faChartLine, faCloudUploadAlt, faCamera, faCommentDollar, faSearch, faDove, faExclamationTriangle, faLightbulb, faCogs, faUserFriends, faCameraRetro, faGem, faCompass, faUserTie, faCookieBite, faFileContract, faShapes, faClipboardList, faGripHorizontal, faUserNinja, faUniversity, faGraduationCap, faCoins } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { faDocker, faGoogle } from '@fortawesome/free-brands-svg-icons';

import { GsapService } from '../services/gsap.service'
import { HaylieService } from '../services/haylie.service'

import { eClass, ePlan, eAlert, eLead, ePadawan, eQuote, eMsg, eReport, eMedia, eAgenda, ImageSnippet, eGameLog } from '../services/entities'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { AngelService } from '../services/angel.service';
import { MorganaService } from '../services/morgana.service';

@Component({
  selector: 'app-page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.css']
})
export class PageHomeComponent implements OnInit {
  player_compass = '';
  model_search_skill = '';
  current_y = (new Date()).getFullYear().toString();
  current_ly = ((new Date()).getFullYear() - 1).toString();
  current_ly2 = ((new Date()).getFullYear() - 2).toString();
  current_date_label = (new Date()).toLocaleDateString('pt-br') + ' ' + (new Date()).toLocaleTimeString('pt-br');
  totaly : any; 
  totally : any;
  totally2 : any;
  totallly : any;
  totallly2 : any;
  totalyagenda : any;

  player_token:any = '';
  player_live = false;
  player_welcome = false;
  player_nav = false;
  model_docker: any[] = [];
  model_alerts: eAlert[] = [];
  model_suggestions: eAlert[] = [];
  model_leads: eLead[] = [];
  model_padawanslatest: ePadawan[] = [];
  model_padawans: ePadawan[] = [];
  model_players: ePadawan[] = [];
  model_quotes: eQuote[] = [];
  model_msgs: eMsg[] = [];
  model_tip = new eQuote();
  model_rptleads : eReport[] = [];
  model_rptleadsly : eReport[] = [];
  model_rptleadsly2 : eReport[] = [];
  model_rptagenda : eReport[] = [];
  model_rptwhats : eReport[] = [];
  model_rptwhats2 : eReport[] = [];
  model_pics: eMedia[] = [];
  model_promo: eMedia[] = [];
  model_plans: ePlan[] = [];
  model_today: eClass[] = [];
  model_today_result: eClass[] = [];
  model_today_confirmations: number = 0;
  model_classes: eClass[] = [];
  model_game: eGameLog[] = [];
  model_game_result: number = 0;
  
  count_class_jiu = 0;
  count_class_thay = 0;
  count_class_boxe = 0;

  FAIconCoins = faCoins;
  FAIconNav = faCompass;
  FAIconNav2 = faGripHorizontal;
  FAIconAgenda = faCalendarCheck;
  FAIconLight = faLightbulb;
  FAIconGoogle = faGoogle; 
  FAIconAlert = faExclamationTriangle;
  FAIconToday = faCheckDouble;
  FAIconAstronaut = faUserAstronaut;
  FAIconFriends = faUserFriends;
  FAIconPadawan = faUserPlus;
  FAIconMembers = faUsers;
  FAIconStaff = faUserNinja;
  FAIconPlans = faCommentDollar;
  FAIconQuote = faQuoteLeft;
  FAIconMsg = faComments;
  FAIconTask = faTasks;
  FAIconReply = faReply;
  FAIconClasses = faClipboardCheck;
  FAIconPlus = faPlus;
  FAIconSearch = faSearch;
  FAIconMinus = faMinus;
  FAIconDash = faChartLine;
  FAIconGallery = faCameraRetro;
  boxFAIconBtnUp = faCloudUploadAlt;
  FAIconStar = faStar;
  FAIconStarFull = faStarFull;
  FAIconRemove = faTimes;
  FAIconDove = faDove;
  FAIconUser = faUser;
  FAIconSettings = faCogs;
  FAIconGem = faGem;
  FAIconPartners = faUserTie; 
  FAIconProducts = faCookieBite; 
  FAIconOrders = faClipboardList;
  FAIconTeachers = faGraduationCap;
  FAIconDocker = faDocker;
  FAIconCheck = faCheck;
  FAIconDelete = faTimes;
  FAIconWhats = faWhatsapp;

  lmorgana : any;
  lhaylie : any;
  lpermission : any;
  

  constructor(private route: ActivatedRoute, private router: Router,  
    private gsap: GsapService, private haylie: HaylieService, 
    public angel: AngelService, private morgana: MorganaService) { 
      this.lmorgana = morgana;
      this.lhaylie = haylie;
    }

  private catch_docker = (p_response: any) =>  {
    //this.model_docker = [];
    var l_response = p_response;
    console.log('===docker>>>> ' + l_response);
    this.model_docker.push(l_response);

    this.gsap.anima_trg('cronos', '');
    return this.model_docker;
  }
  private catch_alerts = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_alerts = l_response.alerts;

    this.model_suggestions = this.model_alerts.filter(x => x.id_type == 2 && x.alive == 1);
    this.model_alerts = this.model_alerts.filter(x => x.id_type < 2 && x.alive == 1);
    
    this.gsap.anima_trg('cronos', '');
    return this.model_alerts;
  }
  private catch_classes = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_classes = l_response.classes;

    this.count_class_jiu = this.model_classes.filter(x => x.skill === 'jiu').length;
    this.count_class_thay = this.model_classes.filter(x => x.skill === 'thay').length;
    this.count_class_boxe = this.model_classes.filter(x => x.skill === 'boxe').length;

    this.gsap.anima_trg('cronos', '');
    return this.model_classes;
  }
  private catch_today = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_today = l_response.classes;

    let sum: number = 0;
    this.model_today.forEach(a => sum += a._count);
    this.model_today_confirmations = sum;

    this.gsap.anima_trg('cronos', '');
    return this.model_today;
  }
  private catch_game = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_game = l_response.log;

    let sum: number = 0;
    this.model_game.forEach(a => sum += a.amount);
    this.model_game_result= sum;

    this.gsap.anima_trg('cronos', '');
    return this.model_game;
  }
  private catch_plans = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_plans = l_response.plans;

    this.gsap.anima_trg('cronos', '');
    return this.model_plans;
  }
  private catch_quotes = (p_response: any): void =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.quotes> ' + l_response.quotes);

    if (l_response.quotes.length > 0) {
      this.gsap.anima_trg('cronos', '');
      this.model_quotes = l_response.quotes;

      let ltip = Math.floor(Math.random() * this.model_quotes.length);  
      console.log('ltip>' + ltip);
      this.model_tip = this.model_quotes[ltip]; 
      this.model_quotes = []; 
      this.model_quotes.push(this.model_tip);

    }   
  }
  private catch_msgs = (p_response: any): void =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.msgs> ' + l_response.msgs);
    this.model_msgs = [];
    
    if (l_response.msgs.length > 0) {
      this.gsap.anima_trg('cronos', '');
      this.model_msgs = l_response.msgs;
      for(var countm = 0; countm < this.model_msgs.length; countm++) {
        this.model_msgs[countm]._datelabel = this.model_msgs[countm].dateins.substr(10);
        this.model_msgs[countm]._datelabel += this.model_msgs[countm]._datelabel.substr(8,2) + "/";
        this.model_msgs[countm]._datelabel += this.model_msgs[countm]._datelabel.substr(5,2) + "/";
        this.model_msgs[countm]._datelabel += this.model_msgs[countm]._datelabel.substr(0,4) + "/"; //duhflag
        console.log('dt-try==> ' + this.model_msgs[countm]._datelabel);
      }
    }   
  }
  private catch_leads = (p_response: any): void =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.leads> ' + l_response.leads);

    if (l_response.leads.length > 0) {
      this.gsap.anima_trg('cronos', '');
      this.model_leads = l_response.leads;
    }   
  }
  private catch_read = (p_response: any) => {
    console.log('catch.read *');
    this.angel.player_compass(false, "msgs");
    this.gsap.player_pages("msgs");
  }
  private catch_padawans = (p_response: any): void =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.padawans> ' + l_response.padawans);

    if (l_response.padawans.length > 0) {
      this.gsap.anima_trg('cronos', '');
      this.model_padawans = l_response.padawans;
    } 
  }
  private catch_players = (p_response: any): void =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.padawans> ' + l_response.padawans);

    if (l_response.padawans.length > 0) {
      this.gsap.anima_trg('cronos', '');
      this.model_players = l_response.padawans;
      this.model_padawanslatest = l_response.latest;
    } 
  }
  private catch_dash = (p_response: any) => {
    var l_response = JSON.parse(p_response);

    if (l_response.report!= null) { 
      //this.model_dash = l_response.report;
      this.model_rptleads = l_response.rptleads;
      this.model_rptleadsly = l_response.rptleadslastyear;
      this.model_rptleadsly2 = l_response.rptleadslastyear2;
      this.model_rptagenda = l_response.rptagenda;
      this.model_rptwhats = l_response.rptwhats;
      this.model_rptwhats2 = l_response.rptwhatsly;

      var test = new Date();
      var cmonth = test.getMonth() + 1;
      for(let i = 0; i <= this.model_rptleads.length; i++) {
        
        //duhflag
        if(this.model_rptleadsly[i] != null && this.model_rptleads[i] != null) {
        this.model_rptleads[i].color = 
          this.model_rptleadsly[i].total > this.model_rptleads[i].total ? 
          "#FF0000" : "#25D366";

          if (this.model_rptleads[i].label == cmonth.toString()) this.model_rptleads[i].color = "#66b8ff"; 
        }

        if(this.model_rptleadsly[i] != null && this.model_rptleadsly[i] != null) {
          this.model_rptleadsly[i].color = 
            this.model_rptleadsly2[i].total > this.model_rptleadsly[i].total ? 
            "#FF0000" : "#25D366";
  
            if (this.model_rptleadsly[i].label == cmonth.toString()) this.model_rptleadsly[i].color = "#66b8ff"; 
          }
      }

      this.totaly = this.model_rptleads.map(x => x.total).reduce((a, b) => a + b);
      this.totally = this.model_rptleadsly.map(x => x.total).reduce((a, b) => a + b);
      this.totally2 = this.model_rptleadsly2.map(x => x.total).reduce((a, b) => a + b);
      this.totallly = this.model_rptwhats.map(x => x.total).reduce((a, b) => a + b);
      this.totallly2 = this.model_rptwhats2.map(x => x.total).reduce((a, b) => a + b);
      this.totalyagenda = this.model_rptagenda.map(x => x.total).reduce((a, b) => a + b);
    }
    else console.log("dash.catch-error");
  }
  private catch_gallery = (p_response: any) =>  {
    this.model_pics = [];
    var l_response = JSON.parse(p_response);
    this.model_pics = l_response.pictures;

    this.gsap.anima_trg('cronos', '');
    return this.model_pics;
  }
  private catch_promo = (p_response: any) =>  {
    this.model_promo = [];
    var l_response = JSON.parse(p_response);
    this.model_promo = l_response.pictures;

    this.gsap.anima_trg('cronos', '');
    return this.model_promo;
  }

  ngOnInit(): void {
    this.gsap.anima_trg('cronos', 'showup');
    this.lpermission = this.haylie.player_permission(true, 0);

    //this.player_token = this.route.snapshot.paramMap.get('token');
    this.player_token = this.haylie.player_token(true, '');
    
    if (this.player_token.length > 0) {
      console.log('player> ' + this.player_token);

      // this.haylie.get_docker().then(this.catch_docker);
      // this.haylie.get_docker2().then(this.catch_docker);

      this.haylie.get_alerts("1").then(this.catch_alerts);

      this.haylie.get_quotes("1").then(this.catch_quotes);

      this.haylie.get_msgs("1", "0").then(this.catch_msgs);

      if (this.lpermission == 1) {
        this.haylie.get_leads("1").then(this.catch_leads);
      
        this.haylie.get_padawans("2").then(this.catch_padawans);

        this.haylie.get_game("1").then(this.catch_game); 

        this.haylie.get_dash().then(this.catch_dash); 
  
        this.haylie.get_plans().then(this.catch_plans);

      }

      this.haylie.get_padawans("1").then(this.catch_players);

      this.haylie.get_classestoday("").then(this.catch_today);

      this.haylie.get_classes("").then(this.catch_classes);

      this.haylie.get_gallery().then(this.catch_gallery);

      this.haylie.get_promo().then(this.catch_promo);

      this.gsap.magic_trg("#outsider_wrapper", "vanish");

      this.player_live = this.player_token.length > 0;
    }
    else {
      this.player_live = false; 

      this.gsap.magic_trg("#outsider_wrapper", "build");
      this.router.navigateByUrl('octalogin');
    }
    
  }
  ngOnChanges() { /* x: SimpleChanges */
    if (this.angel.player_compass(true, '') == 'home') this.content_refresh();
  }
  content_refresh() {
    this.gsap.anima_trg('cronos', 'showup');
    
    this.player_token = this.haylie.player_token(true, '');
    
    if (this.player_token.length > 0) {
      console.log('player> ' + this.player_token);

      // this.haylie.get_docker().then(this.catch_docker);
      // this.haylie.get_docker2().then(this.catch_docker);

      this.haylie.get_alerts("1").then(this.catch_alerts);

      this.haylie.get_quotes("1").then(this.catch_quotes);

      this.haylie.get_msgs("1", "0").then(this.catch_msgs);

      if (this.lpermission == 1) {
        this.haylie.get_leads("1").then(this.catch_leads);

        this.haylie.get_padawans("0").then(this.catch_padawans);
  
        this.haylie.get_dash().then(this.catch_dash);   
      } 
      
      this.haylie.get_plans().then(this.catch_plans);

      this.haylie.get_classestoday("").then(this.catch_today);

      this.haylie.get_classes("").then(this.catch_classes);

      this.haylie.get_gallery().then(this.catch_gallery);

      this.haylie.get_promo().then(this.catch_promo);

      this.player_live = this.player_token.length > 0;

      this.trg_actions('off');
    }
    else {
      this.player_live = false; 

      this.gsap.magic_trg("#outsider_wrapper", "build");
      this.router.navigateByUrl('octalogin');
    }
  }
  select_change(p_select:string, p_val:string):void {
    this.model_search_skill = '';
    if (p_select == "search") this.model_search_skill = p_val;

    //this.frmskill.focus();
  }
  goHaylie(p_action: string, p_target: string, p_helper: string): void{
  console.log(p_action + ">" + p_target);

    switch(p_action) {
      case "classdetail":
        var l_class = this.model_today.filter((x) => x.id == p_target);
        //console.log("classdetailtarget>" + l_class[0].skill + " - " + l_class[0].time);
        this.angel.class_target(false, l_class[0]); 
        this.angel.player_compass(false, "classdetail");
        this.gsap.player_pages("classes");
        //this.router.navigateByUrl("octaagenda")  
      break;

      case "classcheck":
        let l_class2 = this.model_today.filter((x) => x.id == p_target);
        let l_classcheck = new eAgenda();
        l_classcheck.skill = l_class2[0].skill; 
        l_classcheck.time = l_class2[0].time.replace(":","");
        l_classcheck.padawan = this.haylie.player_id(true, 0).toString();
        console.log('sending classcheck> ' + l_class2);
        this.haylie.send_agenda(l_classcheck).then(this.catch_today); //duhflag
        console.log('done.');

        this.model_today.filter((x) => x.id == p_target)[0]._countme = 1;

      break;

      case "gomsgs":
        console.log('gomsgs-loguser> ' + p_target); 
        this.angel.player_target(false, p_target);
        let buddy = this.model_players.filter(x => x.id == p_target); 
        this.angel.player_padawan(false, buddy[0]);   
        
        this.gsap.magic_trg('#phome-limsgbox' + p_helper, 'easyvanish');
        
        this.haylie.kill_content("msg", p_helper).subscribe(
        (res) => { 
          this.haylie.get_msgs("1", "0").then(this.catch_msgs);
          
          this.haylie.send_msg('').then(this.catch_read);
              
          this.angel.player_compass(false, "msgs");
          this.gsap.player_pages("msgs");
        });
      break;

      case "msgcheck": 
      console.log('haylie.check-msg>' + p_target);
      this.gsap.magic_trg('#phome-limsgbox' + p_target, 'easyvanish');
      setTimeout(() => { 
      this.model_msgs = this.model_msgs.filter((x) => x.id.toString() != p_target);

      this.haylie.kill_content("msg", p_target).subscribe(
        (res) => { 
          this.haylie.get_msgs("1", "0").then(this.catch_msgs);
          
        }); }, 420);
      break;

      case "leadcheck": 
        console.log('haylie.kill-lead>' + p_target);
        this.gsap.magic_trg('#phome-lileadbox' + p_target, 'easyvanish');
        setTimeout(() => { 
        this.model_leads = this.model_leads.filter((x) => x.id != p_target);

        this.haylie.kill_content("lead", p_target).subscribe(
          (res) => { 
            this.haylie.get_leads("1").then(this.catch_leads);
        
          }); }, 420);
        break;

      case "alertcheck": 
        console.log('haylie.kill-alert>' + p_target);
        this.gsap.magic_trg('#phome-lialertbox' + p_target, 'easyvanish');
        setTimeout(() => { 
          this.model_alerts = this.model_alerts.filter((x) => x.id != p_target);

          this.haylie.kill_content("alert", p_target).subscribe(
            (res) => { 
              this.haylie.get_alerts("1").then(this.catch_alerts);
        
          }); }, 420);
        break;

      case "padawancheck": 
      case "padawanrefuse": 
        console.log('haylie.player-check>' + p_target);
        this.gsap.magic_trg('#phome-limemberbox' + p_target, 'easyvanish');
        setTimeout(() => { 
        this.model_padawans = this.model_padawans.filter((x) => x.id != p_target);

        let l_alive = p_action == "padawancheck" ? "1" : "-1";

        this.haylie.player_check(p_target, l_alive).subscribe(
          (res) => { 
            this.haylie.get_padawans("0").then(this.catch_padawans);
        
          }); }, 420);
        break;

        case "killpromo":
          this.gsap.anima_trg('cronos', 'showup');
          this.haylie.kill_content("media", p_target).subscribe(
            (res) => { 
              this.model_promo = []; 
              this.gsap.anima_trg('cronos', '');
            }); 
            
        break;

        case "delete": 
            console.log('haylie.kill>' + p_target);
            
            this.gsap.anima_trg('cronos', 'showup');

            this.haylie.kill_content("media", p_target).subscribe(
              (res) => { 
                this.model_pics = [];
                this.haylie.get_gallery().then(this.catch_gallery); 
              }); 
            
          
        break;
      
    }
  }
  goPage(p_route:string, p_page: string):void {
    this.gsap.anima_trg("sidebar", "close");

    if (p_page.length > 0) {
      if (p_page == 'profile') this.angel.profile_token(false, this.haylie.player_token(true, ''));

      this.angel.player_compass(false, p_page);
      this.gsap.player_pages(p_page);
    }
    else 
      setTimeout(() => { this.router.navigateByUrl(p_route) }, 360);
    
  }
  trg_actions(p_action:string){
    console.log(".> " + p_action);
    
    switch(p_action) {
      case "on": 
        this.player_nav = !this.player_nav;
        var actionhelper = this.player_nav ? 'more-nav' : 'less'; 
        //this.gsap.anima_trg('home', 'more-nav');
        this.gsap.anima_trg('home', actionhelper);
        break;

      case "off": 
        this.gsap.anima_trg('home', 'less');
        break;

      case "agenda":
        this.goPage('octaagenda', 'agenda');

        this.gsap.magic_trg('#frmagenda', 'easybuild');
        //this.gsap.magic_trg('.fixed_action', 'vanish');
        break;

      case "suggestion":
        //this.haylie.outsider_photo(false, 0);
        this.gsap.anima_trg('home', 'less');
        this.goPage('octasuggestion', 'suggestion');
        break;

      case "alert":
        //this.haylie.outsider_photo(false, 0);
        this.gsap.anima_trg('home', 'less');
        this.goPage('octaalert', 'alert');
        break;

      case "member":
        this.haylie.outsider_photo(false, 0);
        this.gsap.anima_trg('home', 'less');
        this.goPage('octaoneofus', 'register');
        break;

      case "partners":
        this.gsap.anima_trg('home', 'less');
        this.goPage('octapartners', 'partners');
        break;

      case "products":
        this.gsap.anima_trg('home', 'less');
        this.goPage('octaproducts', 'products');
        break;

      case "orders":
        this.gsap.anima_trg('home', 'less');
        this.goPage('octaorders', 'orders');
        break;

      case "nav2":
        this.gsap.anima_trg('home', 'less');
        this.goPage('octanav2', 'nav2');
        break;

      case "gallery":
        this.gsap.anima_trg('home', 'less');
        this.goPage('octagallery', 'gallery');
        break;

      case "todayopen": 
        this.gsap.anima_trg('hometoday', 'open');
        break;

      case "todayclose": 
        this.gsap.anima_trg('hometoday', 'close');
        break;

      case "settings":
        //this.haylie.outsider_photo(false, 0);
        this.gsap.anima_trg('home', 'less');
        this.goPage('octasettings', 'settings');
        break;

      case "chat":
         this.gsap.anima_trg('home', 'less');
         this.goPage('octachat', 'chat');
         break;

    }
  }

  runFileHigh(imgInput: any): void {

    const file2: File = imgInput.files[0];
    const reader2 = new FileReader();

    reader2.addEventListener('load', (event: any) => {
      //this.page_loading = true;
      this.gsap.anima_trg('cronos', 'showup');

      let selectedFile = new ImageSnippet(event.target.result, file2);

      this.haylie.send_high(selectedFile.file).subscribe(
        (res) => {
          //this.onSuccess();
          console.log('success.highmedia> ' + res.substr(0,res.indexOf("###mid")));
          let promo : eMedia = new eMedia(); 
          promo.name = res.substr(0,res.indexOf("###mid")).replace("promo/","")
          promo.id = res.substr(res.indexOf("###mid") + 6);
          this.model_promo[0] = promo;
          this.gsap.anima_trg('cronos', '');
          
          this.trg_actions('off');

        },
        (err) => {
          //this.onError();
          console.log('error> ' + err);
          //this.page_loading = false;
        })

    }); 

    reader2.readAsDataURL(file2);

  }

  runFileGallery(imgInput: any): void {
    const file3: File = imgInput.files[0];
    const reader3 = new FileReader();

    reader3.addEventListener('load', (event: any) => {
      this.gsap.anima_trg('cronos', 'showup');

      let selectedFile = new ImageSnippet(event.target.result, file3);

      this.haylie.send_gallery(selectedFile.file).subscribe(
        (res) => {
          //this.onSuccess();
          console.log('success.gallerymedia> ' + res.substr(0,res.indexOf("###mid")));
          let media : eMedia = new eMedia(); 
          // media.name = res.substr(0,res.indexOf("###mid")).replace("promo/","")
          // media.id = res.substr(res.indexOf("###mid") + 6);
          // this.model_pics.push(media);
          this.haylie.get_gallery().then(this.catch_gallery);
          this.gsap.anima_trg('cronos', '');
          
          this.trg_actions('off');

        },
        (err) => {
          //this.onError();
          console.log('error> ' + err);
          //this.page_loading = false;
        })

    });

  }
  

}
