import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { HaylieService } from './services/haylie.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'octa';
  player_pluged  = false;
  player_token = '';
  player_welcome  = false;

  gotoreanimation = 'login';

  constructor(private haylie : HaylieService, private router: Router){

  }
  ngOnInit() {
    this.player_pluged = false;
    
    this.player_token = this.haylie.player_token(true, '');
    
    if (this.player_token.length > 0) {
      this.router.navigateByUrl('octahome');
    }
    else {
      if (window.location.toString().indexOf("reanimation") > 0) {
        this.gotoreanimation = 'reanimation';
        //this.router.navigateByUrl('octareanimation');
      }
      else {
        this.gotoreanimation = 'login';
        this.router.navigateByUrl('octalogin');
      }
    }
  }



}
