import { Component, OnInit } from '@angular/core';

import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

import { GsapService } from '../services/gsap.service'
import { HaylieService } from '../services/haylie.service';

@Component({
  selector: 'app-master-header',
  templateUrl: './master-header.component.html',
  styleUrls: ['./master-header.component.css']
})
export class MasterHeaderComponent implements OnInit {
  FAIcon = faEllipsisV
  player_plugged = false;

  constructor(private gsap: GsapService, private haylie: HaylieService) { }

  ngOnInit(): void {
    this. player_plugged = true;
  }
  header_trg(p_action:string):void {
    if (p_action == "sidebar")
      this.gsap.anima_trg("sidebar", "open");
    else {
      this.haylie.player_compass(false, 'home');

      this.gsap.player_pages(p_action);
    }
  
  }
  

}
