<div id="page_profile" *ngIf="model_avatar.length > 0">
    <div id="box-pages" class="block_pages">
        
        <div id="profilebase" class="content box" *ngIf="page_frm_key == false">
            <img src="{{ 'http://haylie.bosswebapps.net/padawan/' + model_avatar }}" class="photo" />
            <div *ngIf="model_cplayer" 
            style="text-align: center;  border-radius: 900px;
            height: 70px; width: 90px; overflow: hidden;
            position: absolute; bottom: 300px; right: -3px;">
    
                <fa-icon 
                    style="top: 16px;border-radius: 90px;color: #fff;padding: 9px 12px;
                        background: #0095f6;box-shadow: 1px 2px 2px rgb(0, 0, 0, .7);font-size: 36px;position: relative;"
                    [icon]="FAIconBtnUp" class="pageicon"></fa-icon>
    
                    <input #imageInput3 
                        style="left: 0; padding-bottom: 70px;
                            position: absolute; top: -90px; text-indent: 300px;background: transparent; height: 90px; " 
                        type="file"
                        accept="image/*"
                        (change)="runFile3(imageInput3)"
                         />
            </div>

            <div class="info">
                <span class="srt" style="left: 9px;">NOME</span>
                <h4 class="name">{{ model_profile.name }}</h4>
                <p class="status">
                    <span class="srt">PLANO</span>
                    <span>{{ model_plan }}</span><br/><br/>
                    <span class="srt">INÍCIO</span>
                    <span>{{ model_profile.datebirth | date: 'dd/MM/yyyy' }}</span>
                </p>
                <!-- <p>{{ reg. }}</p> -->
                <!-- <img src="../assets/img/logo/revirablackteam.png" class="badge" />
                <img src="../assets/img/logo/almeidajj.png" class="badge"  /> -->
                
                <!-- <ul id="ultags">
                    <li id="skillbadge_jiu" style="border-top: 7px solid #111"><img style="width: 34px;" src="../assets/img/categs/jiu.png" /></li>
                    <li id="skillbadge_thay" style="border-top: 7px solid #111"><img style="width: 30px;" src="../assets/img/categs/thay.png" /></li>
                    <li id="skillbadge_boxe" style="border-top: 7px solid #111"><img style="width: 27px;" src="../assets/img/categs/boxe.png" /></li>
                </ul> -->
                <div style="float: right;width: 90px;">
                    <!-- <a class="btn_check" style="border: 3px solid #333;">
                        <fa-icon [icon]="FAIconBan" class="ico"></fa-icon> 
                    </a>
                    <a class="btn_check" style="border: 3px solid #333;">
                        <fa-icon [icon]="FAIconKey" class="ico"></fa-icon> 
                    </a>
                    <a class="btn_check" style="background: #0061ff;border: 7px solid #0061ff;">
                        <fa-icon [icon]="FAIconAgenda" class="ico"></fa-icon> 
                    </a>
                    <a (click)="goHaylie('gomsgs', model_target.toString())"
                        class="btn_check" style="background: #0061ff;border: 7px solid #0061ff;">
                        <fa-icon [icon]="FAIconMsg" class="ico"></fa-icon> 
                    </a>
                    <a class="btn_check" style="background: #0061ff;border: 7px solid #0061ff;">
                        <fa-icon [icon]="FAIconMail" class="ico"></fa-icon> 
                    </a> -->
                    <a *ngIf="!model_cplayer" (click)="goHaylie('gomsgs', model_target.toString())"
                        class="btn_check wpp" style="background: #ef0;border: 3px solid #ef0;">
                        <fa-icon [icon]="FAIconMsg" class="ico" style="color: #111"></fa-icon> 
                    </a>
                    <a *ngIf="!model_cplayer && model_profile.phone.length > 8"
                        class="btn_check wpp" target="_blank" href="https://api.whatsapp.com/send?phone=+5511{{ model_profile.phone.replace('119', '9') }}&text=">
                        <fa-icon [icon]="FAIconWhats" class="ico"></fa-icon> 
                    </a>
                    
                </div>
                
            </div>

            <a class="btnrecblue" *ngIf="player_permission == 1 || model_cplayer" (click)="switchFrm('profile')">EDITAR PERFIL</a>
            <a class="btnrecblue" *ngIf="model_cplayer == true" (click)="switchFrm('key')">ALTERAR MINHA SENHA</a>
            <a class="btnrectransp" *ngIf="player_permission == 1 && model_cplayer == false && model_target > 3" 
                (click)="goHaylie('kill', model_target.toString())" style="color: #0095f6; border: 1px solid;">
                REMOVER MEMBRO</a>
            <a class="btnrectransp" 
                (click)="goHaylie('goteam', '')" style="color: #0095f6;">
                VOLTAR</a>

        </div>
        <div id="frmkey" *ngIf="page_frm_key" class="content box"
                style="opacity: 1; position: absolute;top: 52px;box-shadow: 1px 2px 2px #000;">
            
            <fa-icon [icon]="FAIconKey" class="pageicon"
                style="display: block; font-size: 52px; margin: 18px 0; color:darkcyan; text-align: center;"
                    ></fa-icon>
            <!-- <h1 class="srt" style="top: 16px; left: 34px;">ALTERAR SENHA DE ACESSO</h1> -->
            <h1 class="srt" 
                style="position: relative;top: 16px;left: 50%;margin-left: -25%;font-family: mysansbold;padding: 9px 0;width: 50%;border: 1px solid #333;font-size: 9px;letter-spacing: 1px;">
                SENHA DE ACESSO</h1>
            <p class="sub" id="msgbox" style="height: 34px;"></p>
            <br/>
            <div class="frm" style="text-align: center;min-height: 666px;">
                <input class="inputtxt" type="text" #key_1 name="pwd" 
                    value="{{ model_frm_pwd }}" placeholder="Nova senha" />
                <input class="inputtxt" type="text" #key_2 name="pwd2" 
                    value="{{ model_frm_pwd2 }}" placeholder="Confirme sua nova senha" />
                

                <button class="btn-splatter" (click)="goHaylie('newkeys', '0')"
                    style="top: 63px; font-size: 9px; letter-spacing: 3px;">
                    SALVAR</button>
                <a class="btnReturn" (click)="switchFrm('off')">
                    VOLTAR</a>

            </div>
        </div>
        <div id="frmprofile" *ngIf="page_frm_profile" class="content box"
                style="opacity: 1; position: absolute;top: 52px;box-shadow: 1px 2px 2px #000;">
            
            <fa-icon [icon]="FAIconEdit" class="pageicon"
                    style="font-size: 18px; display: block; margin: 9px;  
                            text-align: left; color: #666;"></fa-icon>
            <h1 class="srt" style="top: 16px; left: 34px;">EDITAR PERFIL</h1>
            <p class="sub" id="msgbox"></p>
            <br/>
            <div class="frm" style="text-align: center;min-height: 666px;">
                <img src="{{ 'http://haylie.bosswebapps.net/padawan/' + model_avatar }}" 
                            style="margin: 0;width: 160px;height: 160px;border-radius: 100%;box-shadow: 1px 2px 2px rgb(255, 255, 255, .3);display: block;left: 50%;
                            position: relative;margin-left: -21%;" />
                <br/><br/>

                <input class="inputtxt" type="text" #frmprofile_name name="pwd" 
                    value="{{ model_name }}" placeholder="Nome" />
                
                <select #frmprofile_plan class="inputtxt" placeholder="Plano" >
                    <option value="">Plano</option>
                    <option *ngFor="let p of model_plans" [value]="p.id" [selected]="p.id == model_planid">{{ p.name }}</option>
                </select>

                <input class="inputtxt" type="number" maxlength="11" 
                    #frmprofile_phone name="phone" value="{{ model_phone }}" placeholder="Telefone" />

                <input class="inputtxt" type="text" #frmprofile_mail name="mail" 
                    value="{{ model_mail }}" placeholder="Email" />

                <input class="inputtxt" type="text" #frmprofile_since name="since" 
                    maxlength="10"
                    value="{{ model_since | date: 'dd/MM/yyyy' }}" placeholder="Início" /> 
            

                <button class="btn-splatter" (click)="goHaylie('review', model_target.toString())"
                    style="top: 63px; font-size: 9px; letter-spacing: 3px;">
                    SALVAR</button>
                <a class="btnReturn" (click)="switchFrm('off')">
                    VOLTAR</a>

            </div>
        </div>

    </div>
</div>