import { Component, Input, OnInit } from '@angular/core';
import { faCalendarCheck, faCameraRetro, faChartLine, faCheckDouble, faClipboardCheck, faClipboardList, faCloudUploadAlt, faCogs, faCommentDollar, faComments, faCookieBite, faDove, faExclamationTriangle, faLightbulb, faMinus, faPlus, faQuoteLeft, faReply, faSearch, faStar, faTasks, faTimes, faUser, faUserAstronaut, faUserPlus, faUsers, faUserTie } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-master-nav',
  templateUrl: './master-nav.component.html',
  styleUrls: ['./master-nav.component.css']
})
export class MasterNavComponent implements OnInit {

  FAIconAgenda = faCalendarCheck;
  FAIconLight = faLightbulb; 
  FAIconAlert = faExclamationTriangle;
  FAIconToday = faCheckDouble;
  FAIconAstronaut = faUserAstronaut;
  FAIconPadawan = faUserPlus;
  FAIconMembers = faUsers;
  FAIconPlans = faCommentDollar;
  FAIconQuote = faQuoteLeft;
  FAIconMsg = faComments;
  FAIconTask = faTasks;
  FAIconReply = faReply;
  FAIconClasses = faClipboardCheck;
  FAIconPlus = faPlus;
  FAIconSearch = faSearch;
  FAIconMinus = faMinus;
  FAIconDash = faChartLine;
  FAIconGallery = faCameraRetro;
  boxFAIconBtnUp = faCloudUploadAlt;
  FAIconStar = faStar;
  //FAIconStarFull = faStarFull;
  FAIconRemove = faTimes;
  FAIconDove = faDove;
  FAIconUser = faUser;
  FAIconSettings = faCogs;
  FAIconPartners = faUserTie; 
  FAIconProducts = faCookieBite; 
  FAIconOrders = faClipboardList;

  @Input() activated: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  trg_actions(p_action:string){
    console.log(".> " + p_action);
    
    switch(p_action) {
      case "on": 
      break;
    }
  }


}
