<div id="fakelayer_sidebar" class="modal-bg" (click)="sidebar_trg('close')"
	style="background: transparent; z-index: 9999999; display: none;"></div>
<div id="mastersidebar" class="sidebar">
	<a (click)="sidebar_trg('close')"></a>
	

	<a (click)="goPage('octaprofile', 'profile')" class="avatarbox">
        <img class="avatar" src="{{ 'http://haylie.bosswebapps.net/padawan/' + player_avatar }}" 
            style="width: 100%;" /> 
    </a>
	<ul>
        <!-- <li><a *ngIf="player_permission == 1" (click)="goPage('octaleads', 'dash')">DASHBOARD</a></li> -->
		<li><a *ngIf="player_permission == 1" (click)="goPage('octaleads', 'leads')">PRÉ-CADASTROS</a></li>
        <li><a (click)="goPage('octaagenda', 'agenda')">AGENDA</a></li>
		<li><a (click)="goPage('octateam', 'team')">MEMBROS</a></li>
		<li><a (click)="goPage('octagallery', 'gallery')">GALERIA</a></li>
        <li><a (click)="goPage('octaplans', 'plans')">PLANOS</a></li>
		<li><a (click)="goPage('octaclasses', 'classes')">AULAS</a></li>
        <li></li>
        <li *ngIf="player_permission == 1" style="margin-top: 30px;">
            <a (click)="goPage('octapartners', 'partners')">FORNECEDORES</a></li>
		<li><a (click)="goPage('octaproducts', 'products')">PRODUTOS</a></li>
        <li><a (click)="goPage('octaorders', 'orders')">PEDIDOS</a></li>
        <li></li>
        <li *ngIf="player_permission == 1" style="margin-top: 36px;">
            <a (click)="goPage('octaalerts', 'alerts')">ALERTAS</a></li>
        <li *ngIf="player_permission == 1">
            <a (click)="goPage('octasuggestions', 'suggestions')">SUGESTÕES</a></li>
        <li style="margin-top: 61px;display: none;"><a (click)="goOut()">
            <!-- <fa-icon [icon]="FAIcon" class="shutdown"></fa-icon> -->
                SAIR
            </a></li> 
	</ul>
	
    <br/>

    <a *ngIf="player_permission == 1" 
        style="display: inline-block;">
        <fa-icon class="level" [icon]="FAIconMaster" 
            style="top: 108px;position: absolute;left: 70%;color: #000;font-size: 25px;padding: 9px;background: #fff;border-radius: 100%;"></fa-icon>
    </a>
    <a *ngIf="player_permission == 2" 
        style="display: inline-block;">
        <fa-icon class="level" [icon]="FAIconMember" 
            style="top: 108px;position: absolute;left: 70%;color: #000;font-size: 25px;padding: 9px;background: #fff;border-radius: 100%;"></fa-icon>
    </a>

    <br/>

    <ul id="mastersidebar_b">
        <!-- <li><a (click)="goPage('octaprofile', 'profile')">MEU PERFIL</a> -->
        <li><a (click)="goPage('octasettings', 'settings')">CONFIGURAÇÕES</a></li>
        <li><a (click)="goOut()">SAIR</a></li>
    </ul> 
</div>
