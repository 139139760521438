<div id="page_partners">
    <div id="box-pages" class="block_pages">
        <div class="head">
            <fa-icon [icon]="pageFAIcon" class="pageicon"></fa-icon>
            <h1 class="title">FORNECEDORES</h1>
            <br/>
            <p class="total" *ngIf="model_partners.length > 0">{{ model_partners.length }} registros</p>
        </div>

        <div id="frmsearch" class="frm" style="min-height: 34px;">
            <!---  --->
            <input #search_name class="inputtxt" type="text" 
                placeholder="Pesquisar"  
                (keyup)="searchList($event)"
             />
        </div>

        <div class="window" style="opacity: 1;">
            <ul *ngIf="model_partners">
                <li *ngFor="let reg of model_partners" class="content box" style="opacity: 1;padding-bottom: 9px;" >
                    <div id="alertbox" class="alertbox" 
                        style=" position: relative; min-height: 108px; max-height: 399px;overflow:hidden;">
                        <fa-icon [icon]="FAIconNinja" class="boxicon"
                        style="margin: 3px 9px;font-size: 36px;height: 90px;border-radius: 100%;box-shadow: 1px 2px 2px rgb(255, 255, 255, .7);float: left;display: inline-block;"></fa-icon> 
        
                        <div class="info">
                            <h4 class="name">{{ reg.name }}</h4>
                            <p class="status"></p>
                            <!-- <p>{{ reg. }}</p> -->
                            <!-- <img src="../assets/img/logo/almeidajj.png" class="badge" />
                            <img src="../assets/img/logo/revirablackteam.png" class="badge" /> -->
                            <!-- <ul id="ultags">
                                <li id="skillbadge_jiu"><img style="width: 34px;" src="../assets/img/categs/jiu.png" /></li>
                                <li id="skillbadge_thay"><img style="width: 30px;" src="../assets/img/categs/thay.png" /></li>
                                <li id="skillbadge_boxe"><img style="width: 27px;" src="../assets/img/categs/boxe.png" /></li>
                            </ul> -->

                            <div style="float: right;top: -30px;position: relative;right: 9px;">
                                <a class="btn_check wpp" target="_blank" *ngIf="reg.phone.length > 8"
                                    style="position: relative; left: -3px;"
                                    href="https://api.whatsapp.com/send?phone=+5511{{ reg.phone.replace('119', '9') }}&text=">
                                    <fa-icon [icon]="FAIconWhats" class="ico"></fa-icon> 
                                </a> 
                                
                            </div>

                        </div>
                    </div>
                </li>
            </ul>

            <app-comp-frm
            id="frmpartner"  
            *ngIf="page_frm" 
            style="position: absolute;top: 52px;box-shadow: 1px 2px 2px #000;background: #fff;z-index: 999;position: fixed;display: block;"
                        [frmoption]="'partner'"></app-comp-frm>
        </div> 
        <a id="btn_frm" class="fixed_action"
            (click)="switchFrm('on')">
            <fa-icon [icon]="FAIconPlus" class="ico"></fa-icon>
        </a>
        
    </div>
</div>