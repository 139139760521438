<div id="page_dash">
    <div id="box-pages" class="block_pages">
        <div class="head">
            <fa-icon [icon]="FAIconDash" class="pageicon"></fa-icon>
            <h1 class="title">DASHBOARD</h1>
        </div>

        <div class="window" style="opacity: 1;">
            <!-- bars . leads --->
            <div class="content box" style="position: relative; opacity: 1;">
                <span class="srt" style="text-align: right;">PRÉ-CADASTROS<br/>2021</span>
                <ul class="rptbars">
                    <li *ngFor="let reg of model_rptleads">
                        <p>{{ reg.total }}</p>
                        <a style='{{ "height:" + (reg.total * 3) + "px" }}'></a>
                        <h4>{{ lmorgana.getmonthname(reg.label, false) }}</h4>
                    </li>
                </ul>
            </div>
            
            <!-- bars . agenda --->
            <div *ngIf="model_rptagenda.length > 0" class="content box" style="position: relative; opacity: 1;">
                <fa-icon [icon]="FAIconDash" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;"></fa-icon>
                <span class="srt" style="top: 43px;text-align: right;">AGENDAMENTOS<br/>2021</span>
                <ul class="rptbars">
                    <li *ngFor="let reg of model_rptagenda">
                        <p>{{ reg.total }}</p>
                        <a style='{{ "height:" + (reg.total * 3) + "px" }}'></a>
                        <h4>{{ lmorgana.getmonthname(reg.label, false) }}</h4>
                    </li>
                </ul>
            </div>

            <ul class="categ">
                <li *ngFor="let reg of model_dash">
                    <div class="progress-circle progress-{{ reg.distinct }}" class="prgcircle">
                        <span>{{ reg.total }}</span>
                    </div>
                    <div>
                        <h4>{{ reg.label }}</h4>
                        <p><i class="fas fa-clock"></i><span>{{ reg.total }}</span> agendamentos</p>
                        <p><i class="fas fa-users"></i><span>{{ reg.distinct }}</span> alunos</p>
                    </div>

                </li>
            </ul>

            <div class="content box" style="position: relative; opacity: 1;">
                <span class="srt">2020</span>
                <ul class="rptbars">
                    <li><a></a></li>
                    <li><a></a></li>
                    <li><a></a></li>
                    <li><a></a></li>
                    <li><a></a></li>
                    <li><a></a></li>

                    <li><a></a></li>
                    <li><a></a></li>
                    <li><a></a></li>
                    <li><a></a></li>
                    <li><a></a></li>
                    <li><a></a></li>
                </ul>
            </div>
            
        </div>
    </div>
</div>