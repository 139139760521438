import { Component, Input, OnInit } from '@angular/core';

import { HaylieService } from '../services/haylie.service'
import { ImageSnippet } from '../services/entities'

import { faCloudUploadAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons'



@Component({
  selector: 'app-comp-imgup',
  templateUrl: './comp-imgup.component.html',
  styleUrls: ['./comp-imgup.component.css']
})
export class CompImgupComponent implements OnInit {
  FAIcon = faUserCircle;
  boxFAIconBtnUp = faCloudUploadAlt;
  @Input() activated: boolean = false;
  @Input() currentImg: string = 'padawan/default.png'
  @Input() org: string = 'register'
  orgImg = '';
  page_loading = false;


  constructor(private haylie: HaylieService) { }

  //@ViewChild('imageInput') frmPadawanImg:any;
  ngOnChanges() { 
    this.orgImg = this.currentImg;

    if (this.haylie.outsider_photo(true,0) <= 0) this.currentImg ='padawan/default.png';

   }
  ngOnInit(): void {
    this.orgImg = this.currentImg;

    if (this.haylie.outsider_photo(true,0) <= 0) this.currentImg ='padawan/default.png';
  }
  runFile(imgInput: any) {

    const file: File = imgInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      this.page_loading = true;

      let selectedFile = new ImageSnippet(event.target.result, file);

          this.haylie.outsider_hi(selectedFile.file).subscribe(
            (res) => {
              //this.onSuccess();
              console.log('success.registermedia> ' + res.substr(0,res.indexOf("###mid")));
              this.currentImg = res.substr(0,res.indexOf("###mid"));

              this.haylie.outsider_photo(false, res.substr(res.indexOf("###mid") + 6)   );
              
              this.page_loading = false;

            },
            (err) => {
              //this.onError();
              console.log('error> ' + err);
              //this.currentImg = 'padawan/default.png'
              this.currentImg = this.orgImg;
            })
      
    });

    reader.readAsDataURL(file);

  }
  /*private onSuccess() {
    this.selectedFile.pending = false;
    this.selectedFile.status = 'ok';
  }
  private onError() {
    this.selectedFile.pending = false;
    this.selectedFile.status = 'fail';
    this.selectedFile.src = '';
  }*/

}
