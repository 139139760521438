<div id="page_alerts">
    <div id="box-pages" class="block_pages">
        <div class="head">
            <fa-icon [icon]="FAIconAlert" class="pageicon" *ngIf="model_type == 'alerts'"></fa-icon>
            <fa-icon [icon]="FAIconSuggestion" class="pageicon" *ngIf="model_type == 'suggestions'"></fa-icon>
            <h1 class="title">{{ model_title }}</h1>
            <br/>
            <p class="total" *ngIf="model_alerts.length > 0">{{ model_alerts.length }} registros</p>
        </div>

        <div class="window" style="opacity: 1;">
            <ul *ngIf="model_alerts.length > 0 && !page_frm" style="padding-bottom: 9px;opacity: 1;" >
                <li id="phome-lialertbox{{ reg.id }}" *ngFor="let reg of model_alerts" class="content box alivebg{{ reg.alive }}"  style="opacity: 1;padding-bottom: 9px;">
                    <div id="alertbox{{ reg.id }}" class="alertbox">
                        <!-- <fa-icon [icon]="FAIconAstronaut" class="boxicon"></fa-icon> -->
                        <h4 style="font-weight: 600; padding: 18px 0 9px 0;">{{ reg.title }}</h4>
                        <!-- <p style="line-height: 25px;">{{ reg.msg  }}<br/></p> -->
                        <span style="line-height: 25px; padding: 3px 21px;width: 90%;display: block;margin: 9px 0;"
                                    [innerHTML]="reg.msg"></span>
                        
                        <a *ngIf="reg.alive == 1" class="btn_check fix" 
                            (click)="goHaylie('alertcheck', reg.id)">
                            <fa-icon [icon]="FAIconDelete" class="ico"></fa-icon> 
                        </a>

                        <span class="srt">
                            {{ reg.dateins.substr(8, 2) + '/' + reg.dateins.substr(5, 2) + '/' + reg.dateins.substr(0, 4) }}<br/>
                            {{ reg.dateins.substr(11, 5) }}
                        </span>
                        <span class="info"  
                            style="float: left;width: auto;position: absolute;bottom: -16px;left: 0;">
                            <fa-icon [icon]="FAIconUser" class="ico" style="color: darkcyan;float: left;display: block;width: 100%;text-align: left;"></fa-icon>
                            <b style="font-family: Arial; color: #333;display:block;
                                font-size: 9px;text-align: left;margin-left: 3px;">{{  reg._name }}</b><br>
                            
                        </span>
                    </div>
                </li>
            </ul>

            <!-- <div id="frmleadsrpt" *ngIf="page_rpt" class="content box"
                style="opacity: 1; position: absolute;top: 99px;box-shadow: 1px 2px 2px #000;">
            
                

                <a class="btnReturn" (click)="switchFrm('init')">
                    VOLTAR</a>
            
            </div> 
        
        (click)="switchFrm('rpt')"-->

        </div> 

        <a id="btn_frm" class="fixed_action" 
            >
            <fa-icon [icon]="FAIconNew" class="ico" style="font-size: 21px;"></fa-icon>
        </a>
</div>