import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

import { faCamera, faCloudUploadAlt, faStar as faStarFull, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-regular-svg-icons'


import { GsapService } from '../services/gsap.service'
import { HaylieService } from '../services/haylie.service'
import { ImageSnippet, eMedia } from '../services/entities'

@Component({
  selector: 'app-page-gallery',
  templateUrl: './page-gallery.component.html',
  styleUrls: ['./page-gallery.component.css']
})
export class PageGalleryComponent implements OnInit {
  boxFAIcon = faCamera;
  boxFAIconBtnUp = faCloudUploadAlt;
  FAIconStar = faStar;
  FAIconStarFull = faStarFull;
  FAIconRemove = faTimes;

  txtcount_imgs = 'X fotos';
  txtcount_stars = 'Y destaques';

  page_status: boolean = false;
  page_loading: boolean = false;
  model_pics: eMedia[] = [];
  currentImg: string = '';
  orgImg: string = '';
  
  lpermission : any;

  constructor(private gsap: GsapService, private haylie: HaylieService, private router: Router) { 
    
  }

  ngOnInit(): void {
    this.page_status = true;
    this.get_gallery();
    this.page_loading = true;
    this.lpermission = this.haylie.player_permission(true, 0);
    this.gsap.anima_trg('cronos', 'showup');
  }
  get_gallery(): void {
    this.model_pics = [];

    this.haylie.get_gallery().then(this.load_gallery);
  }
  private load_gallery = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    //var jobj = JSON.parse(l_response);
    //console.log('response> ' + l_response.pictures[1].name);
    this.model_pics = l_response.pictures;

    this.page_loading = false;
    this.gsap.anima_trg('cronos', '');
    return this.model_pics;
   
  }
  goBack():void {
    //this.page_status = false;
    this.router.navigateByUrl("octahome");
  }

  goHaylie(p_action: string, p_id:string):void {
 
    if (p_action == "delete") {
      console.log('haylie.kill>' + p_id);
      
      this.gsap.anima_trg('cronos', 'showup');
      this.haylie.kill_content("media", p_id).subscribe(
        (res) => { 
          this.model_pics = [];
          this.get_gallery(); }); 
      
    }
    else {
      console.log('haylie.update>' + p_id);

      this.haylie.update_media(p_action, p_id).subscribe(
        (res) => { this.get_gallery(); }); 

    }

    //this.page_status = false;
    //this.router.navigateByUrl("octahome");
  }

  runFile2(imgInput: any): void {

    const file2: File = imgInput.files[0];
    const reader2 = new FileReader();

    reader2.addEventListener('load', (event: any) => {
      this.page_loading = true;
      this.gsap.anima_trg('cronos', 'showup');

      let selectedFile = new ImageSnippet(event.target.result, file2);

      this.haylie.send_gallery(selectedFile.file).subscribe(
        (res) => {
          //this.onSuccess();
          console.log('success.gallerymedia> ' + res.substr(0,res.indexOf("###mid")));
          //this.currentImg = res.substr(0,res.indexOf("###mid"));
          this.get_gallery();
          //this.page_loading = false;

        },
        (err) => {
          //this.onError();
          console.log('error> ' + err);
          this.page_loading = false;
        })

    }); 

    reader2.readAsDataURL(file2);

  }


  
}
