import { Component, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { eMedia, eProfile } from '../services/entities';
import { GsapService } from '../services/gsap.service';
import { HaylieService } from '../services/haylie.service';

@Component({
  selector: 'app-page-reanimation',
  templateUrl: './page-reanimation.component.html',
  styleUrls: ['./page-reanimation.component.css']
})
export class PageReanimationComponent implements OnInit {
  FAIconCheck = faCheck;

  p_url_token : string = '';
  maindomain : string = '';

  model_profile : eProfile = new eProfile();
  model_media : eMedia = new eMedia();
  model_avatar : string = '';
  model_name : string = '';

  response_msg : string = '';

  @ViewChild('key_1') frmkey1:any;
  @ViewChild('key_2') frmkey2:any;

  constructor(private haylie: HaylieService, 
    private gsap: GsapService,          
    private route: ActivatedRoute, 
    private router: Router) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
        console.log('qparams> ' + params); 
        this.p_url_token = params.p_token;
        console.log('p_url_token> ' + this.p_url_token); 

        this.haylie.get_profile(this.p_url_token).then(this.catch_profile);
        //this.haylie.player_token(false, this.p_url_token);
      }
    );

  }

  catch_profile = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.profile> ' + l_response.player._profile.name);

    this.model_profile = l_response.player._profile;
    this.model_media = l_response.player._profile._media;

    this.model_avatar = this.model_media.name;
    this.model_name = this.model_profile.name;
    
    this.gsap.anima_trg('cronos', '');
  }
  catch_keys = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.keys> ' + l_response);

    this.response_msg = l_response.msg;

    this.gsap.anima_trg('cronos', '');

    if (l_response.result) {
      this.gsap.magic_trg('#page_reanimation .result', 'easybuild');

      setTimeout(() => { 
        this.router.navigateByUrl('octalogin');
      }
      , 3000);
    }
    // else {
    //   this.gsap.anima_trg('reanimation', 'denied2');
    
  }

  goHaylie() {
      this.gsap.anima_trg('cronos', 'showup');
      var frmcheck = true;
      var key1 = '', key2 = '';

      if (this.frmkey1.nativeElement.value.length <= 0)
      { frmcheck = false; console.log('frmerror> missing key1'); }
      else  
        key1 = this.frmkey1.nativeElement.value;

      if (this.frmkey2.nativeElement.value.length <= 0)
      { frmcheck = false; console.log('frmerror> missing key2'); }
      else  
        key2 = this.frmkey2.nativeElement.value;

      if (key1 != key2)
      { frmcheck = false; console.log('frmerror> keys dont match'); }

      if (frmcheck) {
        console.log('sending keys');
        this.haylie.player_locker(key1, this.p_url_token).then(this.catch_keys);
        console.log('done.');
        //this.switchFrm("off");
      }
      else {
        this.gsap.magic_trg("#page_reanimation", "blink");
        this.gsap.anima_trg('cronos', '');
      }
   }



}
