import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faCalendarCheck, faTimes, faCheck, faPlus, faMinus, faEdit, faUserAstronaut } from '@fortawesome/free-solid-svg-icons';
import { HaylieService } from '../services/haylie.service';
import { GsapService } from '../services/gsap.service';
import { eAgenda, eClass } from '../services/entities';
import { CookieService } from '../services/cookie.service';
import { MorganaService } from '../services/morgana.service';

@Component({
  selector: 'app-page-agenda',
  templateUrl: './page-agenda.component.html',
  styleUrls: ['./page-agenda.component.css']
})
export class PageAgendaComponent implements OnInit {
  pageFAIcon = faCalendarCheck;
  FAIconRemove = faTimes;
  FAIconCheck = faCheck;
  player_permission = 0;
  
  FAIconNotMember = faUserAstronaut;
  FAIconPlus = faPlus;
  FAIconMinus = faMinus;
  frmFAIcon = faEdit;

  model_categs = ["jiu", "thay", "boxe"];
  model_classes : eClass[] = [];
  model_days = [];
  model_full : eAgenda[] = [];
  lstdays : string[] = [];
  lstdaysjiu : eAgenda[] = [];
  lstdaysthay : eAgenda[] = [];
  lstdaysboxe : eAgenda[] = [];

  @Input() activated: boolean = false;


  /*--- frm ---*/ 
  page_frm = false;
  model_agenda : any[] = [];
  model_timebox : any[] = [];
  model_players : any[] = [];
  frm_timeboxval = "";
  
  timebox_jiu : any[] = ["07:00","12:15","18:00", "19:30"];
  timebox_jiu2 : any[] = ["18:30","20:00"];
  timebox_jiu3 : any[] = ["09:00"];
  
  timebox_thay : any[] = ["07:00","09:00","19:00"];
  timebox_thay2 : any[] = ["08:00","20:00"];
  timebox_thay3 : any[] = ["12:30"];

  timebox_boxe : any[] = ["15:00","20:30","21:00"];
  timebox_boxe2 : any[] = ["07:00","19:00"];
  timebox_boxe3 : any[] = ["12:30"];

  listdays_padawan = "";
  listdays_timeset = "";
  listdays_timesetindex = "";
  listdays_timesetday = "";
  listdays_thay_ready : any[] = [];
  listdays_thay = "";
  listdays_jiu_ready : any[] = [];
  listdays_jiu = "";
  listdays_boxe_ready : any[] = [];
  listdays_boxe = "";
  listdays_week : any[] = [];

  @ViewChild('agenda_skill') frmskill:any;
  @ViewChild('agenda_dateto') frmdateto:any;
  @ViewChild('agenda_time') frmtime:any;
  @ViewChild('agenda_padawan') frmpadawan:any;
  @ViewChild('agenda_dates') frmlistdates:any;

  constructor(private router: Router, private haylie: HaylieService, 
      private morgana: MorganaService,
      private gsap: GsapService, private cookie: CookieService) { }

  switchFrm(p_action:string):void {
    
    if (p_action == 'on') {
      this.page_frm = true;
      this.gsap.magic_trg('#frmagenda', 'easybuild');
      this.gsap.magic_trg('#page_agenda .fixed_action', 'vanish');
    }
    else { 
      this.gsap.magic_trg('#frmagenda', 'easyvanish');
      this.gsap.magic_trg('#page_agenda .fixed_action', 'easybuild');
      this.page_frm = false;
    }

  }

  private catch_players = (p_response: any): void =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.padawans> ' + l_response.padawans);
    this.model_players = [];

    if (l_response.padawans.length > 0) {
      this.gsap.anima_trg('cronos', '');
      this.model_players = l_response.padawans;
    } 
  }
  private catch_classes = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_classes = l_response.classes;

    this.gsap.anima_trg('cronos', '');
    return this.model_classes;
  }
  private catch_agenda = (p_response: any): void =>  {
    var l_response = JSON.parse(p_response);
    //console.log('catch.agenda> ' + l_response.alerts);

    if (l_response.full.length > 0) {
      //this.gsap.anima_trg('cronos', '');
      this.model_full = l_response.full;
      //console.log("agendaloaded.full> " + this.model_full.length);
      this.model_days = l_response.days;
      //console.log("agendaloaded.days> " + this.model_days.length);

      this.lstdays = [...new Set(this.model_full.map(item => item.dateto_short))];


      this.model_agenda = [];
      for (var d = 0; d < l_response.days.length; d++) {
        if (l_response.days[d].skill == "thay" && this.listdays_thay_ready.indexOf(l_response.days[d].dateshort) < 0) {
          this.listdays_thay += '<option label="' + l_response.days[d].dayofweek + '">' + l_response.days[d].date.substr(0, 10) + '</option>'
          this.listdays_thay_ready[d] = l_response.days[d].dateshort;
        }
        if (l_response.days[d].skill == "jiu" && this.listdays_jiu_ready.indexOf(l_response.days[d].dateshort) < 0) {
          this.listdays_jiu += '<option label="' + l_response.days[d].dayofweek + '">' + l_response.days[d].date.substr(0, 10) + '</option>'
          this.listdays_jiu_ready[d] = l_response.days[d].dateshort;
        }
        if (l_response.days[d].skill == "boxe" && this.listdays_boxe_ready.indexOf(l_response.days[d].dateshort) < 0) {
          this.listdays_boxe += '<option label="' + l_response.days[d].dayofweek + '">' + l_response.days[d].date.substr(0, 10) + '</option>'
          this.listdays_boxe_ready[d] = l_response.days[d].dateshort;
        }

        if (l_response.days[d].dateshort.length > 0 && 
          this.listdays_week.indexOf(l_response.days[d].dateshort) < 0) 
            this.listdays_week[d] = l_response.days[d].dayofweek;
        
      }

    } 
    else console.log("agenda.catcherror");
    
    this.gsap.anima_trg('cronos', '');
  }
  catch_frm = (p_response:any) => {
    this.content_refresh();
  }
  content_refresh() {
    this.switchFrm("off");
    this.gsap.anima_trg('cronos', 'showup');
    this.haylie.get_classes("").then(this.catch_classes);
    this.haylie.get_agenda().then(this.catch_agenda); 

    if (this.haylie.player_permission(true, 0) == 0) 
      this.haylie.player_permission(false, parseInt(this.cookie.getCookie("player_permission")));

    this.player_permission = this.haylie.player_permission(true, 0);

    if (this.player_permission == 1)
      this.haylie.get_padawans("1").then(this.catch_players);

  }
  ngOnChanges() { if (this.activated) this.content_refresh(); }
  ngOnInit(): void {
    
  }
  goBack():void {
    this.router.navigateByUrl("octahome");
  }
  goHaylie(p_action: string, p_target: string): void{
    console.log(p_action + ">" + p_target);
  
    switch(p_action) {
      case "deleteagenda": 
        console.log('haylie.kill-agenda>' + p_target);

        this.haylie.kill_content("agenda", p_target).subscribe(
          (res) => { 
            this.content_refresh();
        
          }); 
        break;

      case "frm":
        this.gsap.anima_trg('cronos', 'showup');
        var frmcheck = true;
        var obj = new eAgenda();

        if (this.frmskill.nativeElement.value.length <= 0)
        { frmcheck = false; console.log('frmerror> missing skill'); }
        else  
          obj.skill = this.frmskill.nativeElement.value;
        
        if (this.frmdateto.nativeElement.value.length <= 0)
        { frmcheck = false; console.log('frmerror> missing dateto'); }
        else  
          obj.dateto = this.frmdateto.nativeElement.value;
        
        if (this.frm_timeboxval.length <= 0)
        { frmcheck = false; console.log('frmerror> missing time'); }
        else  
          obj.time = this.frm_timeboxval;
        
        if (this.player_permission == 1) { 
          if (this.frmpadawan.nativeElement.value.length <= 0)
          { frmcheck = false; console.log('frmerror> missing padawan'); }
          else  
          { obj.padawan = this.frmpadawan.nativeElement.value; }
        }
        // else 
        //   obj.padawan = this.haylie.player_id(true, 0);
        /*else { 
          if (this.frmpadawan.nativeElement.value.length <= 0)
          { frmcheck = false; console.log('frmerror> missing padawan'); }
          else  
          { obj.padawan = this.frmpadawan.nativeElement.value; }
        } */
        


        if (frmcheck) {
          this.switchFrm("off");
          console.log('sending agenda> ' + obj);
          this.haylie.send_agenda(obj).then(this.catch_frm);
          console.log('done.');

          /*setTimeout(() => {
            this.content_refresh();
            //this.switchFrm("off");
            //this.gsap.anima_trg('cronos', '');
          }, 3600);*/

        }
        else {
          this.gsap.magic_trg("#frmagenda", "blink");
          this.gsap.anima_trg('cronos', '');
        }
      
        break;
    }

  }

  select_change(p_select:string, p_val:string):void {

    if (p_select == "time") this.frm_timeboxval = p_val.replace(":", "");
    else {
      this.frm_timeboxval = "";

      if (p_select == "skill") {
          console.log('_selected.' + p_select + '> ' + p_val);
          
          let listswitch = p_val == "jiu" ?  this.listdays_jiu_ready : (p_val == "thay" ?  this.listdays_thay_ready : this.listdays_boxe_ready);
          
          this.model_agenda = [];
          for (var d = 0; d < listswitch.length; d++) {
            if (listswitch[d] != null) 
                this.model_agenda.push({ dateshort: listswitch[d], dayofweek : this.listdays_week[d] });
          }
          
          this.listdays_timeset = p_val;
      }
      if (p_select == "dateto") {
          console.log('_selected.' + p_select + '> ' + p_val);
          
          var dateformat = p_val.substr(6,4) + '-' + p_val.substr(3,2) + '-' + p_val.substr(0,2);
          var day = new Date(dateformat).getUTCDay();
          //var day = parseInt(p_val.substr(0,2));  
          var newIndex = [1, 3, 5].includes(day) ? "1" : ([2, 4].includes(day) ? "2" : "3");
          //this.listdays_timesetindex = newIndex;
          this.listdays_timesetindex = day.toString();

          //console.log('_selected.timesetindex> ' + newIndex);
          console.log('_selected.day> ' + day);
      }
      if (p_select == "padawan") {
          this.listdays_padawan = p_val;
      } 

      this.timeboxreview();
    }

  }
  timeboxreview(): void {
  
    if (this.listdays_timeset.length > 0) {

      var selectedDate = this.frmdateto.nativeElement.value;
      console.log('_selected.dateto.filter_value> ' + selectedDate);

      this.model_timebox = [];
      var l_list = this.model_classes.filter(x => x.skill == this.listdays_timeset && x.day == this.morgana.getdayofweek(this.listdays_timesetindex));
      l_list.forEach(x => {
         if (x.alive == 1) this.model_timebox.push(x.time); 
      })
      
    }
    
  }



}
