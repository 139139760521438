<div id="page_products">
    <div id="box-pages" class="block_pages">
        <div class="head">
            <fa-icon [icon]="pageFAIcon" class="pageicon"></fa-icon>
            <h1 class="title">PRODUTOS</h1>
            <br/>
            <p class="total" *ngIf="model_products.length > 0">{{ model_products.length }} registros</p>
        </div>

        <div id="frmsearch" class="frm" style="min-height: 34px;">
            
            <input #search_name class="inputtxt" type="text" 
                placeholder="Pesquisar"  
                (keyup)="searchList($event)"
             />
        </div>

        <div class="window" style="opacity: 1;">
            <ul *ngIf="model_products">
                <li *ngFor="let reg of model_products" class="content box" style="opacity: 1;padding-bottom: 9px;" >
                    <div id="alertbox" class="alertbox" 
                        style=" position: relative; min-height: 120px; max-height: 399px;overflow:hidden;">
                        <fa-icon [icon]="FAIconProduct" class="boxicon"></fa-icon> 
        
                        <div class="info">
                            <h4 class="name">{{ reg.name }}</h4>
                            <p class="status"></p>
                            
                            

                        </div>
                    </div>
                </li>
            </ul>

            <app-comp-frm
                id="frmproduct"  
                *ngIf="page_frm" 
                style="position: absolute;top: 43px;box-shadow: 1px 2px 2px #000;background: #fff;z-index: 999;position: fixed;display: block;"
                        [frmoption]="'product'"></app-comp-frm>
        </div> 
        <a id="btn_frm" class="fixed_action"
            (click)="switchFrm('on')">
            <fa-icon [icon]="FAIconPlus" class="ico"></fa-icon>
        </a>
    </div>
</div>