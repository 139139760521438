<div id="page_msgs">
    <div id="box-pages" class="block_pages">
        <div class="head">
            <fa-icon [icon]="pageFAIcon" class="pageicon"></fa-icon>
     
            <img style="width: 52px; height: 52px; display: inline-block; border-radius: 100%; margin-left: -45px; position: relative; top: -3.4px;"  
            src="{{ 'http://haylie.bosswebapps.net/padawan/' + model_padawan._avatar }}" class="photo" />
     
            <h1 class="title">{{ model_padawan._name }}</h1>
        </div>
        <div class="window" style="opacity: 1;background: #666;" id="msgs_container">

            <ul *ngIf="model_msgs" style="padding-bottom: 90px;opacity: 1;position: relative;float: left;" >
                <li id="phome-limsgbox{{ reg.id }}" 
                    *ngFor="let reg of model_msgs" 
                    class="content box alivebg{{ reg.alive }}"  
                    style="opacity: 1;padding-bottom: 9px;">
                    <div id="msgbox{{ reg.id }}" class="msgbox {{ reg.id_user.toString() == model_target ? 'me' : '' }}">
                        
                        <p style="line-height: 25px;">
                            <span [innerHTML]="reg.msg"></span>
                        </p>
                           

                        <span class="srt">
                            {{ reg.dateins.substr(8, 2) + "/" + reg.dateins.substr(5, 2) + "/" + reg.dateins.substr(0, 4) + " " + reg.dateins.substr(11, 5) }}
                        </span>
                        
                    </div>
                </li>
            </ul>
            <!-- <p id="boxlimit"></p> -->
        </div> 

        <textarea #input_msg class="fixed_action txt" placeholder=">" aria-multiline="true"
            (keyup.enter)="goHaylie()"></textarea>
        <!-- <input type="text" id="" class="fixed_action txt" placeholder=">" /> -->
        <a id="btn_frm" class="fixed_action" (click)="goHaylie()">
            <fa-icon [icon]="FAIconPlane" class="ico"></fa-icon>
        </a> 

</div>