import { ViewChild, Component, OnInit, Input, ElementRef } from '@angular/core';
import { Router } from '@angular/router'

import { faCoins, faCommentDollar, faCheck, faTimes, faPlus, faMinus, faEdit, faUsers } from '@fortawesome/free-solid-svg-icons'

import { ePlan } from '../services/entities'
import { GsapService } from '../services/gsap.service'
import { HaylieService } from '../services/haylie.service'

@Component({
  selector: 'app-page-plans',
  templateUrl: './page-plans.component.html',
  styleUrls: ['./page-plans.component.css']
})
export class PagePlansComponent implements OnInit {
  player_live = false;
  player_permission = 0;
  model_plans: ePlan[] = [];
  model_frm_id = 0;
  model_frm_name = '';
  model_frm_skill = '';
  model_frm_type = '';
  model_frm_price = '';

  FAIconHead = faCommentDollar;
  FAIconCheck = faCheck;
  FAIconDelete = faTimes;
  FAIconPlus = faPlus;
  FAIconMinus = faMinus;
  frmFAIcon = faEdit;
  FAIconMembers = faUsers;

  page_frm = false;
  
  lhaylie: any;
  @Input() activated: boolean = false;

  @ViewChild('plan_skill') frmskill:any;
  @ViewChild('plan_name') frmname:any;
  @ViewChild('plan_type') frmtype:any;
  @ViewChild('plan_price') frmprice:any;

  constructor(private gsap: GsapService, private haylie: HaylieService, private router: Router) {
    this.lhaylie = this.haylie;
   }

  ngOnInit(): void {
    this.player_permission = this.haylie.player_permission(true, 0);
  }
  ngOnChanges() { 
    if (this.activated) this.content_refresh();
  }
  content_refresh() {
    this.gsap.anima_trg('cronos', 'showup');
    
    if (this.haylie.player_token(true, '').length > 0) {
      this.player_permission = this.haylie.player_permission(true, 0);
      this.switchFrm("off");
      this.get_plans();
    }
    else {
      this.player_live = false; 
      this.gsap.magic_trg("#outsider_wrapper", "build");
      this.router.navigateByUrl('octalogin');
    }
  }

  switchFrm(p_action:string):void {
    
    if (p_action == 'on') {
      this.model_frm_id = 0; 
      this.model_frm_name = ''; 
      this.model_frm_skill = '';
      this.model_frm_type = '';
      this.model_frm_price = '';  
    
      this.page_frm = true;
      this.gsap.magic_trg('#frmplan', 'easybuild');
      this.gsap.magic_trg('#page_plans .fixed_action', 'vanish');
    }
    else { 
      this.gsap.magic_trg('#frmplan', 'easyvanish');
      this.gsap.magic_trg('#page_plans .fixed_action', 'easybuild');
      this.page_frm = false;
    }

  }

  get_plans(): void {
    this.model_plans = [];

    this.haylie.get_plans().then(this.catch_plans);
  }
  catch_plans = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_plans = l_response.plans;

    this.gsap.anima_trg('cronos', '');
    return this.model_plans;
  }

  goHaylie(p_action: string, p_target: string): void{
    console.log(p_action + ">" + p_target);
  
    switch(p_action) {
      case "frm":
        this.gsap.anima_trg('cronos', 'showup');
        var frmcheck = true;
        var obj = new ePlan();

        if (this.frmname.nativeElement.value.length <= 0)
        { frmcheck = false; console.log('frmerror> missing skill'); }
        else  
          obj.name = this.frmname.nativeElement.value;

        if (this.frmskill.nativeElement.value.length <= 0)
        { frmcheck = false; console.log('frmerror> missing skill'); }
        else  
          obj.skill = this.frmskill.nativeElement.value;
        
        if (this.frmtype.nativeElement.value.length <= 0)
        { frmcheck = false; console.log('frmerror> missing dateto'); }
        else  
          obj.type = this.frmtype.nativeElement.value;
        
        if (this.frmprice.nativeElement.value <= 0)
        { frmcheck = false; console.log('frmerror> missing dateto'); }
        else  
          obj.price = this.frmprice.nativeElement.value;

        if (frmcheck) {
          obj.id = this.model_frm_id;

          this.switchFrm("off");
          console.log('sending plan> ' + obj);
          this.haylie.send_plan(obj).then(this.catch_frm);
          console.log('done.');

          /*setTimeout(() => {
            this.content_refresh();
            //this.switchFrm("off");
            //this.gsap.anima_trg('cronos', '');
          }, 3600);*/

        }
        else {
          this.gsap.magic_trg("#frmplan", "blink");
          this.gsap.anima_trg('cronos', '');
        }
      break;

      case "delete": 
        console.log('haylie.kill-plan>' + this.model_frm_id);

        this.haylie.kill_content("plan", this.model_frm_id.toString()).subscribe(
          (res) => { 
            this.content_refresh();
        
          }); 
        break;

      case "edit": 
        console.log('haylie.edit-plan>' + p_target);
        this.model_frm_id = parseInt(p_target); 
        var register = this.model_plans.filter((x) => x.id.toString() == p_target); 
        this.switchFrm("on");

        this.model_frm_id = parseInt(p_target); 
        this.model_frm_name = register[0].name; 
        this.model_frm_skill = register[0].skill;
        this.model_frm_type = register[0].type;
        this.model_frm_price = register[0].price; 

        break;
    }
  }
  catch_frm = (p_response:any) => {
    this.content_refresh();
  }

}
