<div id="page_gallery">
    <div id="box-pages" class="block_pages">
        <div class="head" style="display: block; float: left; width: 100%;color: #efefef;">
            <fa-icon [icon]="boxFAIcon" class="pageicon"
            style="font-size: 36px;left: 3%;position: relative; float:left; margin-right: 9px;"></fa-icon>
            
            <h1 class="title" style="display: inline-block; display: inline-block;
            position: relative; float: right; margin: 9px;
            font-size: 12px; letter-spacing: 1.8px; font-family: 'mysans';">
                GALERIA</h1>
                <br/>
            <p class="total" *ngIf="model_pics.length > 0">{{ model_pics.length }} registros</p>
        </div>


        <div class="window">
            <ul *ngIf="model_pics">
                <li *ngFor="let p of model_pics">
                    <div *ngIf="p.name != 'init'" class="col-1-4"> 
                        <img src="{{ 'http://haylie.bosswebapps.net/gallery/' + p.name }}" width="98%" />
                    </div>
                    <a *ngIf="p._file_alive == 1 && lpermission == 1" (click)="goHaylie('mediastar', p.id)" class="contentaction star">
                        <fa-icon [icon]="FAIconStar"></fa-icon>
                    </a>
                    <a *ngIf="p._file_alive == 2 && lpermission == 1" (click)="goHaylie('media', p.id)" class="contentaction star">
                        <fa-icon [icon]="FAIconStarFull"></fa-icon>
                    </a>
                    <!--*ngIf="p._file_alive == 2 && lpermission == 1" -->
                    <a (click)="goHaylie('delete', p.id)" 
                        *ngIf="p._file_alive >= 1 && lpermission == 1"
                        class="contentaction delete">
                        <fa-icon [icon]="FAIconRemove"></fa-icon>
                    </a>
                </li>
            </ul>
            <!-- <div *n gIf="page_loading" style="float: left; position: absolute; top: 0px; left: 0; z-index: 999999999999;
                                text-align: center; width: 100%;">
                <img src="../assets/img/gif/loading3.gif" />
            </div> -->

        </div>
        <div *ngIf="lpermission == 1" 
        style="text-align: center;  border-radius: 900px;
        height: 70px; width: 90px; overflow: hidden;
        position: fixed; bottom: 25px; right: 25px;">

            <fa-icon 
                style="top: 16px;border-radius: 90px;color: #fff;padding: 9px;
                    background: #0095f6;box-shadow: 1px 2px 2px rgb(0, 0, 0, .7);font-size: 36px;position: relative;"
                [icon]="boxFAIconBtnUp" class="pageicon"></fa-icon>

                <input #imageInput2 
                    style="left: 0; padding-bottom: 70px;
                        position: absolute; top: -90px; text-indent: 300px;background: transparent; height: 90px; " 
                    type="file"
                    accept="image/*"
                    (change)="runFile2(imageInput2)" />
        </div>

    </div>
</div>

