import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MasterHeaderComponent } from './master-header/master-header.component';
import { MasterSidebarComponent } from './master-sidebar/master-sidebar.component';
import { PageLoginComponent } from './page-login/page-login.component';
import { PageProfileComponent } from './page-profile/page-profile.component';
import { CompImgupComponent } from './comp-imgup/comp-imgup.component';
import { PageHomeComponent } from './page-home/page-home.component';
import { PageGalleryComponent } from './page-gallery/page-gallery.component';
import { PageTeamComponent } from './page-team/page-team.component';
import { PageAgendaComponent } from './page-agenda/page-agenda.component';
import { PageLeadsComponent } from './page-leads/page-leads.component';
import { PageDashComponent } from './page-dash/page-dash.component';
import { CompFrmComponent } from './comp-frm/comp-frm.component';
import { PageMsgsComponent } from './page-msgs/page-msgs.component';
import { PagePlansComponent } from './page-plans/page-plans.component';
import { PageClassesComponent } from './page-classes/page-classes.component';
import { PageAlertsComponent } from './page-alerts/page-alerts.component';
import { PageSettingsComponent } from './page-settings/page-settings.component';
import { PageReanimationComponent } from './page-reanimation/page-reanimation.component';
import { PageRegisterComponent } from './page-register/page-register.component';
import { MasterNavComponent } from './master-nav/master-nav.component';
import { PageWelcomeComponent } from './page-welcome/page-welcome.component';
import { PagePartnersComponent } from './page-partners/page-partners.component';
import { PageProductsComponent } from './page-products/page-products.component';
import { PageOrdersComponent } from './page-orders/page-orders.component';

@NgModule({
  declarations: [
    AppComponent,
    MasterHeaderComponent,
    MasterSidebarComponent,
    PageLoginComponent,
    PageProfileComponent,
    CompImgupComponent,
    PageHomeComponent,
    PageGalleryComponent,
    PageTeamComponent,
    PageAgendaComponent,
    PageLeadsComponent,
    PageDashComponent,
    CompFrmComponent,
    PageMsgsComponent,
    PagePlansComponent,
    PageClassesComponent,
    PageAlertsComponent,
    PageSettingsComponent,
    PageReanimationComponent,
    PageRegisterComponent,
    MasterNavComponent,
    PageWelcomeComponent,
    PagePartnersComponent,
    PageProductsComponent,
    PageOrdersComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, 
    FontAwesomeModule, 
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
