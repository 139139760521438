import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router'
import { eAgenda, eClass } from '../services/entities';
import { GsapService } from '../services/gsap.service'
import { HaylieService } from '../services/haylie.service'
import { AngelService } from '../services/angel.service'
import { faClipboardCheck, faPlus, faEdit, faTimes, faUserAstronaut, faUserAlt } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

@Component({
  selector: 'app-page-classes',
  templateUrl: './page-classes.component.html',
  styleUrls: ['./page-classes.component.css']
})
export class PageClassesComponent implements OnInit {
  player_live = false;
  player_permission = 0 ;
  model_classes: eClass[] = [];
  model_agenda_today : eAgenda[] = [];
  page_frm = false;
  page_frm_detail = false;
  model_frm_id = 0;
  model_frm_skill = ''; 
  model_frm_day = '';
  model_frm_time = '';
  model_search_skill = '';

  model_base_skills: string[] = ["jiu", "thay", "boxe"];
  model_base_days: string[] = ["segunda", "terça", "quarta", "quinta", "sexta", "sábado", "domingo"];

  model_today_skill = "jiu";

  model_class_detail: eClass = new eClass();
  //model_today_day = this.helper_today.getDay();
  //public maskTime = [/[1-9]/, /\d/,':',/\d/, /\d/];

  @Input() activated: boolean = false;

  FAIconMember = faUserAlt;
  FAIconNotMember = faUserAstronaut;
  FAIconHead = faClipboardCheck;
  FAIconEdit = faEdit;
  FAIconDelete = faTimes;
  FAIconWhats = faWhatsapp;
  FAIconPlus = faPlus;

  lhaylie : any;

  @ViewChild('search_skill') frmsearchskill:any;
  @ViewChild('class_skill') frmskill:any;
  @ViewChild('class_day') frmday:any;
  @ViewChild('class_time') frmtime:any;

  constructor(private gsap: GsapService, private haylie: HaylieService, 
      private angel: AngelService, private router: Router) { }

  ngOnInit(): void {
    this.model_search_skill = 'jiu';
    this.lhaylie = this.haylie;
  }
  ngOnChanges() { 
    if (this.activated) this.content_refresh(); 
  }
  content_refresh() {
    this.gsap.anima_trg('cronos', 'showup');

    if (this.haylie.player_token(true, '').length > 0) {  
      this.player_permission = this.haylie.player_permission(true, 0);
      this.switchFrm("off");
      this.get_classes();

      if (this.angel.player_compass(true, '') == 'classdetail') {
        this.model_class_detail = this.angel.class_target(true, new eClass());
        
        this.haylie.get_agenda_today().then(this.catch_agenda_today); 

        this.page_frm = false; 
        this.page_frm_detail = true; 

        this.gsap.magic_trg('#frmclass', 'easyvanish');
        this.gsap.magic_trg('#page_classes .fixed_action', 'easyvanish');
        this.gsap.magic_trg('#frmclassdetail', 'easybuild');
        this.gsap.magic_trg('#page_classes .fixed_action', 'vanish');
      }
    
    }
    else {
      this.player_live = false; 
      this.gsap.magic_trg("#outsider_wrapper", "build");
      this.router.navigateByUrl('octalogin');
    }
    
  }
  filterClasses(p_skill:string, p_day:string): any[] {
    if (p_day.length > 0)
      return this.model_classes.filter(x => x.skill === p_skill && x.day === p_day);
    else 
      return this.model_classes.filter(x => x.skill === p_skill);
  }
  resetRes(p_what: string) {
    if (p_what == 'search') this.model_search_skill = "";
  }
  select_change(p_select:string, p_val:string):void {
    this.model_search_skill = '';
    if (p_select == "search") this.model_search_skill = p_val;

    this.frmskill.focus();
  }
  switchFrm(p_action:string):void {
    if (p_action == 'on') { 
      this.model_frm_id = 0; 
      this.model_frm_skill = '';
      this.model_frm_day = '';
      this.model_frm_time = '';
    
      this.page_frm = true;
      this.page_frm_detail = false;
      this.gsap.magic_trg('#frmclass', 'easybuild');
      this.gsap.magic_trg('#page_classes .fixed_action', 'vanish');
    }
    else { 
      this.gsap.magic_trg('#frmclass', 'easyvanish');
      this.gsap.magic_trg('#page_classes .fixed_action', 'easybuild');
      this.page_frm = false;
      this.page_frm_detail = false;
    }
  }
  goPage(p_action: string) :void {
    this.haylie.player_compass(false, 'home');
    this.gsap.player_pages(p_action);
  }
  
  get_classes(): void {
    this.model_classes = [];

    this.haylie.get_classes("").then(this.catch_classes);
  }
  catch_classes = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_classes = l_response.classes;

    this.gsap.anima_trg('cronos', '');
    return this.model_classes;
  }
  catch_frm = (p_response:any) => {
    this.content_refresh();
  }
  private catch_agenda_today = (p_response: any): void =>  {
    var l_response = JSON.parse(p_response);
    //console.log('catch.agenda> ' + l_response.alerts);
    this.model_agenda_today = [];

    if (l_response.list.length > 0) {
      this.model_agenda_today = l_response.list; 

      this.model_agenda_today = this.model_agenda_today.filter(x => x.skill == this.model_class_detail.skill && x.datetotime == this.model_class_detail.time.replace(":",""));
    } 
    else console.log("agenda.today.catcherror");
    
    this.gsap.anima_trg('cronos', '');
  }

  goHaylie(p_action: string, p_target: string): void{
    console.log(p_action + ">" + p_target);
  
    switch(p_action) {
      case "frm":
        this.gsap.anima_trg('cronos', 'showup');
        var frmcheck = true;
        var obj = new eClass();

        if (this.frmskill.nativeElement.value.length <= 0)
        { frmcheck = false; console.log('frmerror> missing skill'); }
        else  
          obj.skill = this.frmskill.nativeElement.value;
        
        if (this.frmday.nativeElement.value.length <= 0)
        { frmcheck = false; console.log('frmerror> missing day'); }
        else  
          obj.day = this.frmday.nativeElement.value;

        if (this.frmtime.nativeElement.value.length <= 0)
        { frmcheck = false; console.log('frmerror> missing time'); }
        else  
          obj.time = this.frmtime.nativeElement.value;
        
        if (frmcheck) {
          obj.id = this.model_frm_id.toString();

          this.switchFrm("off");
          console.log('sending plan> ' + obj);
          this.haylie.send_class(obj).then(this.catch_frm);
          console.log('done.');

          /*setTimeout(() => {
            this.content_refresh();
            //this.switchFrm("off");
            //this.gsap.anima_trg('cronos', '');
          }, 3600);*/

        }
        else {
          this.gsap.magic_trg("#frmclass", "blink");
          this.gsap.anima_trg('cronos', '');
        }
        break;

      case "edit": 
        console.log('haylie.edit-class>' + p_target);
        this.switchFrm("on");

        this.model_frm_id = parseInt(p_target); 
        var register = this.model_classes.filter((x) => x.id.toString() == p_target); 
        
        this.model_frm_id = parseInt(p_target); 
        this.model_frm_skill = register[0].skill;
        this.model_frm_day = register[0].day;
        this.model_frm_time = register[0].time;

        break;

      case "kill": 
        console.log('haylie.kill-class>' + this.model_frm_id);

        this.haylie.kill_content("class", this.model_frm_id.toString()).subscribe(
          (res) => { 
            this.content_refresh();
        
          }); 
        break;

      case "deleteagenda": 
        console.log('haylie.kill-agenda>' + p_target);

        this.haylie.kill_content("agenda", p_target).subscribe(
          (res) => { 
            this.content_refresh();
        
          }); 
        break;
    }
  }


}
