import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageHomeComponent } from './page-home/page-home.component'
import { PageLoginComponent } from './page-login/page-login.component'
import { PageGalleryComponent } from './page-gallery/page-gallery.component'
import { PageAgendaComponent } from './page-agenda/page-agenda.component'
import { PageLeadsComponent } from './page-leads/page-leads.component'
import { PagePlansComponent } from './page-plans/page-plans.component'
import { PageClassesComponent } from './page-classes/page-classes.component'
import { PageTeamComponent } from './page-team/page-team.component'
import { CompFrmComponent } from './comp-frm/comp-frm.component'
import { PageAlertsComponent } from './page-alerts/page-alerts.component';
import { PageSettingsComponent } from './page-settings/page-settings.component';
import { PageReanimationComponent } from './page-reanimation/page-reanimation.component';
import { PagePartnersComponent } from './page-partners/page-partners.component'
import { PageProductsComponent } from './page-products/page-products.component';
import { MasterNavComponent } from './master-nav/master-nav.component';

const routes: Routes = [
  { path: 'octalogin', component: PageLoginComponent },
  { path: 'octareanimation', component: PageReanimationComponent },
  { path: 'octahome', component: PageHomeComponent }, 
  { path: 'octaagenda', component: PageAgendaComponent },
  { path: 'octaleads', component: PageLeadsComponent },
  { path: 'octagallery', component: PageGalleryComponent },
  { path: 'octaoneofus', component: CompFrmComponent }, 
  { path: 'octaplans', component: PagePlansComponent },
  { path: 'octaclasses', component: PageClassesComponent }, 
  { path: 'octateam', component: PageTeamComponent }, 
  { path: 'octaalerts', component: PageAlertsComponent },
  { path: 'octasuggestions', component: PageAlertsComponent },  
  { path: 'octassettings', component: PageSettingsComponent },  
  { path: 'octapartners', component: PagePartnersComponent },  
  { path: 'octaproducts', component: PageProductsComponent },  
  { path: 'octanav2', component: MasterNavComponent },  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
