export class Entities {
}
export class ImageSnippet {
    pending: boolean = false;
    status: string = 'init';

    constructor(public src: string, public file: File) {}
}
export class eCompany {
    id: string = '';
    dateins: string = '';
    id_media: string = '';
    cnpj: string = '';
    name: string = '';
    address: string = '';
    phone: string = '';
    site: string = '';
    social_instagram: string = '';
    social_facebook: string = '';
    social_twitter: string = '';
    social_wpp: string = '';
    social_wpptxt: string = '';
    _avatar: string = '';
    alive: string = '';
}
export class ePlan {
    id: number = 0;
    name: string = '';
    txt: string = '';
    skill: string = '';
    type: string = '';
    price: string = '';
    alive: number = 0;
    _count: number = 0;
}
export class eReport {
    label: string = '';
    total: number = 0;
    distinct: number = 0;
    color: string = '';
    
}
export class eMsg {
    id: number = 0;
    dateins: string = '';
    id_user: number = 0;
    id_media: number = 0;
    msg: string = '';
    alive: number = 0;
    log_user: number = 0;

    _name: string = '';
    _avatar: string = '';
    _datelabel: string = '';
}
export class eQuote {
    id: number = 0;
    dateins: Date = new Date();
    quote: string = '';
    author: string = '';
    alive: number = 0;
}
export class eMedia {
    id: string = '';
    dateins: string = '';
    name: string = '';
    _file_alive: number = 0;
}
export class eAlert {
    id: string = '';
    dateins: string = '';
    title: string = '';
    resume: string = '';
    msg: string = '';
    id_type: number = 0;
    id_plan: number = 0;
    alive: number = 0;

    _name: string = '';
}
export class ePadawan {
    id: string = '';
    dateins: string = '';
    mail: string = '';
    _name: string = '';
    _phone: string = '';
    _avatar: string = '';
    token: string = '';
    alive: string = '';
}
export class ePartner {
    id: string = '';
    dateins: string = '';
    partner: string = '';
    cnpj: string = '';
    name: string = '';
    mail: string = '';
    phone: string = '';
    _avatar: string = '';
    alive: string = '';
}
export class eCateg {
    id: string = '';
    id_sector: number = 0;
    name: string = '';
    alive: number = 1;
}
export class eOrder {
    id: string = '';
    id_sector: number = 0;
    name: string = '';
    alive: number = 1;
    _client: eUser = new eUser();
    
}
export class eOrderItem {
    id: string = '';
    id_order: number = 0;
    name: string = '';
    alive: number = 1;
}
export class eLead {
    id: string = '';
    dateins: string = '';
    birth: string = '';
    name: string = '';
    mail: string = '';
    phone: string = '';
    alive: number = 0;
    log_user: number = 0;

    _name: string = '';
}
export class eAgenda {
    id: string = '';
    padawan: string = '';
    skill: string = '';
    dateto: Date = new Date();
    dateto_short = '';
    dateto_label = '';
    datetotime: string = '';
    time: string = '';
    status: number = 1;
    device: number = 2;
    _member: boolean = false;
}
export class eClass {
    id: string = '';
    skill: string = '';
    day: string = '';
    time: string = '';
    alive: number = 1;
    _count: number = 0;
    _countme: number = 0;
}
export class eGameLog {
    id_type: number = 0;
    amount: number = 0;
    title: string = '';
    alive: number = 1;
    log_user: number = 0;
}
export class eProfile {
    id: number = 0;
    datebirth: Date = new Date();
    name: string = '';
    sex: string = '';
    phone: string = '';
    address: string = '';
    doc: string = '';
    
}
export class eUser {
    id: number = 0;
    dateins: Date = new Date();
    mail: string = '';
    template: string = '';
    _profile: eProfile = new eProfile();
    _company: eCompany = new eCompany();
    id_permission: number = 0;
}