<div id="page_team">
    <div id="box-pages" class="block_pages">
        <div class="head">
            <fa-icon [icon]="pageFAIcon" class="pageicon"></fa-icon>
            <h1 class="title">MEMBROS</h1>
            <br/>
            <p class="total" *ngIf="model_padawans.length > 0">{{ model_padawans.length }} registros</p>
        </div>

        <div id="frmsearch" class="frm" style="min-height: 34px;">
            <!---  --->
            <input #search_name class="inputtxt" type="text" 
                placeholder="Pesquisar"  
                (keyup)="searchList($event)"
             />
        </div>

        <div class="window" style="opacity: 1;">
            <ul *ngIf="model_padawans">
                <li *ngFor="let reg of model_padawans" class="content box" style="opacity: 1;padding-bottom: 9px;" >
                    <div id="alertbox" class="alertbox" 
                        style=" position: relative; min-height: 120px; max-height: 399px;overflow:hidden;">
                        <!-- <fa-icon [icon]="FAIconNinja" class="boxicon"></fa-icon> -->
                        <img src="{{ 'http://haylie.bosswebapps.net/padawan/' + reg._avatar }}" 
                            style="margin: 3px 9px;width: 90px;height: 90px;border-radius: 100%;box-shadow: 1px 2px 2px rgb(255, 255, 255, .7);float: left;display: inline-block;border: 1px solid #ccc;" />
    
                        <div class="info">
                            <h4 class="name">{{ reg._name }}</h4>
                            <p class="status"></p>
                            <!-- <p>{{ reg. }}</p> -->
                            <!-- <img src="../assets/img/logo/almeidajj.png" class="badge" />
                            <img src="../assets/img/logo/revirablackteam.png" class="badge" /> -->
                            <!-- <ul id="ultags">
                                <li id="skillbadge_jiu"><img style="width: 34px;" src="../assets/img/categs/jiu.png" /></li>
                                <li id="skillbadge_thay"><img style="width: 30px;" src="../assets/img/categs/thay.png" /></li>
                                <li id="skillbadge_boxe"><img style="width: 27px;" src="../assets/img/categs/boxe.png" /></li>
                            </ul> -->

                            <div style="float: right;top: -43px;position: relative;right: 9px;">
                                <a class="btn_check wpp" target="_blank" *ngIf="reg._phone.length > 8"
                                    style="position: relative; left: -3px;"
                                    href="https://api.whatsapp.com/send?phone=+5511{{ reg._phone.replace('119', '9') }}&text=">
                                    <fa-icon [icon]="FAIconWhats" class="ico"></fa-icon> 
                                </a> 
                                <a (click)="goHaylie('gomsgs', reg.id.toString())"
                                    class="btn_check wpp" 
                                    style="padding: 5px 2px;background: #ef0;border: 3px solid #ef0;">
                                    <fa-icon [icon]="FAIconMsg" class="ico" style="color: #111"></fa-icon> 
                                </a> 
                                <a class="btn_check" (click)="goHaylie('profile', reg.token)" 
                                    style="background: #0095f6;position: relative;
                                    top: -9px;">
                                    <fa-icon [icon]="FAIconPlus" class="ico" style="color:#eee;"></fa-icon> 
                                </a>
                            </div>

                        </div>
                    </div>
                </li>
            </ul>
        </div> 

    </div>
</div>