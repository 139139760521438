import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import { faUsers, faTimes, faPlus, faUserNinja, faEllipsisV, faComments } from '@fortawesome/free-solid-svg-icons'
import { AngelService } from '../services/angel.service';

import { ePadawan } from '../services/entities'
import { GsapService } from '../services/gsap.service'
import { HaylieService } from '../services/haylie.service'

@Component({
  selector: 'app-page-team',
  templateUrl: './page-team.component.html',
  styleUrls: ['./page-team.component.css']
})
export class PageTeamComponent implements OnInit {
pageFAIcon = faUsers;
FAIconPlus = faPlus;
FAIconClose = faTimes; 
FAIconActions = faEllipsisV;
FAIconNinja = faUserNinja; 
FAIconWhats = faWhatsapp;
FAIconMsg = faComments;
player_live = false;
model_padawans: ePadawan[] = [];
model_padawansorg: ePadawan[] = [];

@Input() activated: boolean = false;
  
  constructor(private angel: AngelService, 
    private gsap: GsapService, private haylie: HaylieService, private router: Router) 
  { 
    
  }
  ngOnChanges() {
    if (this.activated) { this.content_refresh(); console.log('---changesrun.team'); }
  }
  content_refresh() {
    this.gsap.anima_trg('cronos', 'showup');
    
    if (this.haylie.player_token(true, '').length > 0) {
      console.log('player> ' + this.haylie.player_token(true, ''));
      this.get_team();

      this.player_live = true;
    }
    else {
      this.player_live = false; 

      this.gsap.magic_trg("#outsider_wrapper", "build");
      this.router.navigateByUrl('octalogin');
    }
  }
  ngOnInit(): void {
    
  }
  get_team(): void {
    this.model_padawans = [];
    this.model_padawansorg = [];

    this.haylie.get_padawans("1").then(this.catch_padawans);
  }
  /*compare( a: ePadawan, b: ePadawan) {
    if ( a._name < b._name ){
      return -1;
    }
    if ( a._name > b._name ){
      return 1;
    }
    return 0;
  }*/
  private catch_padawans = (p_response: any): void =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.padawans> ' + l_response.padawans);

    if (l_response.padawans.length > 0) {
      this.gsap.anima_trg('cronos', '');
      this.model_padawans = l_response.padawans;
      this.model_padawansorg = this.model_padawans;
    } 
    
  }
  goBack():void {
    this.router.navigateByUrl("octahome");
  }
  goHaylie(p_action: string, p_target: string): void{
    console.log(p_action + ">" + p_target);

    switch(p_action) {
      case "profile":
        this.angel.profile_token(false, p_target);
        this.angel.player_compass(false, p_action);
        this.gsap.player_pages(p_action);
      break;

      case "gomsgs":
        console.log('gomsgs-loguser> ' + p_target); 
        this.angel.player_target(false, p_target);
        let buddy = this.model_padawansorg.filter(x => x.id == p_target); //duhflag
        this.angel.player_padawan(false, buddy[0]);   
        this.angel.player_compass(false, "msgs");
        this.gsap.player_pages("msgs");
      break;
    }

  }

  searchList(p_key:any):void {
    console.log('--- search key --- >>> ' + p_key);
    this.model_padawans = [];
    if (p_key.target.value.length > 0)
      this.model_padawans = this.model_padawansorg.filter(x => x._name.toLowerCase().indexOf(p_key.target.value.toLowerCase()) >= 0);
    else 
      this.model_padawans = this.model_padawansorg;

  }
  

}
