import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router'

import { faBan, faCalendarCheck, faEnvelope, faHandMiddleFinger, faKey, faPen, faUserNinja, faComments, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { AngelService } from '../services/angel.service';
import { eUser, ePadawan, eMsg, eProfile } from '../services/entities';
import { GsapService } from '../services/gsap.service';
import { HaylieService } from '../services/haylie.service';

import { io } from 'socket.io-client'

@Component({
  selector: 'app-page-msgs',
  templateUrl: './page-msgs.component.html',
  styleUrls: ['./page-msgs.component.css']
})
export class PageMsgsComponent implements OnInit {
  pageFAIcon = faComments; 
  FAIconPlane = faPaperPlane;
  model_msgs: eMsg[] = [];
  model_target = ""; 
  model_padawan: ePadawan = new ePadawan();

  //socket = io("https://bwa-sandbox.herokuapp.com");
  roomid : number = 0;
  
  constructor(private router: Router, 
    private gsap: GsapService, private haylie: HaylieService, 
    private angel: AngelService) { }

  @Input() activated: boolean = false;

  @ViewChild("input_msg") inputmsg: any;

  ngOnInit(): void {
    this.content_refresh();
  }
  ngOnChanges() { /* x: SimpleChanges */
    if (this.activated) this.content_refresh();
  }
  content_refresh() {
    this.gsap.anima_trg('cronos', 'showup');
    this.model_target = this.angel.player_target(true,"");
    this.model_padawan = this.angel.player_padawan(true, new ePadawan()); 
    
    if (this.haylie.player_token(true, '').length > 0) {
      console.log('player> ' + this.haylie.player_token(true, ''));
      this.get_msgs();
    }
    else {
      this.gsap.magic_trg("#outsider_wrapper", "build");
      this.router.navigateByUrl('octalogin');
    }

    //this.setupSocketConnection();
  }
  get_msgs(): void {
    this.haylie.get_msgs("9", this.angel.player_target(true,"")).then(this.catch_msgs);
  }
  private catch_msgs = (p_response: any): void =>  {
    var l_response = JSON.parse(p_response);
    
    console.log('catch.msgs> ' + l_response.msgs);

    this.gsap.anima_trg('cronos', '');
    this.model_msgs = l_response.msgs.length > 0 ? l_response.msgs : [];
    this.gsap.magic_trg('#page_msgs .window', 'scrollbottom');

  }
  catch = (p_response: any) => {
    this.get_msgs();
  }
  goHaylie() {

    if (this.inputmsg.nativeElement.value.length > 0) {  
      this.haylie.send_msg(this.inputmsg.nativeElement.value).then(this.catch);
      this.inputmsg.nativeElement.value = '';
      console.log('done.');

      console.log('sending msg> ' + this.inputmsg.nativeElement.value);
      //this.socket.emit('message', this.inputmsg.nativeElement.value);
      
    }
  }

  setupSocketConnection() {
    //this.socket.connect();
    
    this.roomid = this.haylie.player_id(true, 0);
    this.roomid += parseInt(this.angel.player_target(true, ''));

    console.log('--server-room-> ' + this.roomid);

    // this.socket.on('connection', (psocket) => {
    //   psocket.join("octa#room" + this.roomid.toString());
    // });
    /*this.socket.on('message-broadcast', (data: string) => {
      if (data) {
        
        let broadmsg : eMsg = new eMsg();
        broadmsg.msg = data;
        broadmsg.dateins = "00/00/0000";

        console.log('--server-broadcast-> ' + data);
        
        //this.model_msgs.push(broadmsg);
        this.get_msgs();
       }
     });
*/
    // this.socket.on("connection", (sockett) => {  
    //   sockett.join("/#room" + this.roomid);
    // });

    // this.socket.on("private-message", (msg) => {  
    //   //sockett.join("room" + this.roomid);
    // });

    

  }

}
