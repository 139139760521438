<div id="page_reanimation">
    <div>
    <img id="reanimationlogo" *ngIf="maindomain != 'bwa'"
        src="../assets/img/logo/newfight.png" class="logo" />
    </div>
    <div>
        <h4 class="title"><b>CRIAR NOVA SENHA</b></h4>
    </div>
    <div>
    <img src="{{ 'http://haylie.bosswebapps.net/padawan/' + model_avatar }}" 
        class="avatar" />
    </div>
    <div>
        <h4 class="name">{{ model_name }}</h4>
    </div>
    <div>
        <div class="eRecover" id="ghost_wrapper" 
            style="opacity: 1;width: 210px;top: 9px; left: 50%;position: relative;
            margin-left: -105px;margin-top: 9px;">
            <label class="has-float-label">
                <input #key_1 type="email" 
                    placeholder="" class="eRecover" aria-multiline="false" autocomplete="off" value="" />
                <span style="font-family: mysanslight;color: #111;font-size: 12px;">NOVA SENHA</span>
            </label>
        </div>
        <div class="eRecover" id="ghost_wrapper" 
            style="opacity: 1;width: 210px;top: 9px; left: 50%;position: relative;
                margin-left: -105px;margin-top: 30px;">
            <label class="has-float-label">
                <input #key_2 type="email" 
                    placeholder="" class="eRecover" aria-multiline="false" autocomplete="off" value="" />
                <span style="font-family: mysanslight;color: #111;font-size: 12px;">CONFIRME SUA SENHA</span>
            </label>
        </div>
    </div>
    <div>
        <a id="btn_send" class="btnrecblue" (click)="goHaylie()"
        style="position: relative;top: 52px;text-align: center; margin: 9px 15%; width: 70%;
            box-shadow: 1px 2px 7px rgba(0,0,0,.7); ">ENVIAR</a>
    </div>

    <p id="eRecoverResult" class="help result">
        <fa-icon [icon]="FAIconCheck" style="font-size: 34px; color: rgba(0,0,0,.7);"></fa-icon>
        <br/><br/><br/>       
        {{ response_msg }}<br/>
<!-- 
        <img style="width: 99px" src="../assets/img/categs/punch.png"   /> -->

    </p>

</div>