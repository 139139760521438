import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import { faUsers, faTimes, faPlus, faUserNinja, faEllipsisV, faComments, faCheck } from '@fortawesome/free-solid-svg-icons'
import { AngelService } from '../services/angel.service';

import { ePadawan } from '../services/entities'
import { GsapService } from '../services/gsap.service'
import { HaylieService } from '../services/haylie.service'

@Component({
  selector: 'app-page-welcome',
  templateUrl: './page-welcome.component.html',
  styleUrls: ['./page-welcome.component.css']
})
export class PageWelcomeComponent implements OnInit {
  pageFAIcon = faUsers;
  FAIconPlus = faPlus;
  FAIconClose = faTimes; 
  FAIconActions = faEllipsisV;
  FAIconNinja = faUserNinja; 
  FAIconWhats = faWhatsapp;
  FAIconMsg = faComments;
  boxFAIconResult = faCheck;
  player_live = false;
  loading_status = false;
  page_status = false;
  model_padawans: ePadawan[] = [];
  model_padawansorg: ePadawan[] = [];
  
  @Input() activated: boolean = false;
    
  constructor(private angel: AngelService, 
    private gsap: GsapService, private haylie: HaylieService, private router: Router) 
  { 
    
  }
  ngOnInit(): void {
  }

}
