import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { faEdit, faExclamationTriangle, faLightbulb, faPallet, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { AngelService } from '../services/angel.service';
import { GsapService } from '../services/gsap.service';
import { HaylieService } from '../services/haylie.service';
import { ePlan, eAlert, eCateg } from '../services/entities'
import { SequenceEqualOperator } from 'rxjs/internal/operators/sequenceEqual';

@Component({
  selector: 'app-comp-frm',
  templateUrl: './comp-frm.component.html',
  styleUrls: ['./comp-frm.component.css']
})
export class CompFrmComponent implements OnInit {
  frmFAIcon = faEdit;
  FAIconProduct = faPallet;
  FAIconPartner = faUserTie;
  FAIconLight = faLightbulb;
  FAIconAlert = faExclamationTriangle;
  frmcheck = false;
  model_categs: eCateg[] = [];
  model_plans: ePlan[] = [];
  model_since : Date = new Date();
  model_limit : Date = new Date();

  lfrmtype='';
  padawan = '';
  padawanmail = '';
  padawanphone = '';
  padawanplan = '';
  padawanphoto = 0;
  padawaninit = '';

  alerttitle = '';
  alerttxt = '';
  alertplan = '';

  partnermail = '';
  partnerphone = '';
  partnername = '';
  partnercompany = '';
  partnercnpj = '';

  response_msg = '';

  @Input() activated: boolean = false;
  @Input() frmoption: string = '';

  @ViewChild('newguy_name') frmName:any;
  @ViewChild('newguy_mail') frmMail:any;
  @ViewChild('newguy_phone') frmPhone:any;
  @ViewChild('newguy_plan') frmPlan:any;
  @ViewChild('newguy_init') frmDtInit:any;
  @ViewChild('newguy_avatar') frmAvatar:any;

  //@ViewChild('newalert_end') frmAlertEnd:any = '';
  @ViewChild('newalert_title') frmAlertTitle:any = '';
  @ViewChild('newalert_plan') frmAlertPlan:any = '';
  @ViewChild('newalert_txt') frmAlertTxt:any = '';
  
  @ViewChild('newsugg_title') frmSuggTitle:any = '';
  @ViewChild('newsugg_txt') frmSuggTxt:any = '';
  
  @ViewChild('newpartner_name') frmPartnerName:any = '';
  @ViewChild('newpartner_mail') frmPartnerMail:any = '';
  @ViewChild('newpartner_phone') frmPartnerPhone:any = '';
  @ViewChild('newpartner_company') frmPartnerCompany:any = '';
  @ViewChild('newpartner_cnpj') frmPartnerCnpj:any = '';
  
  constructor(private angel: AngelService, 
              private haylie: HaylieService, private gsap: GsapService) {
                
               }


  private catch_partner = (p_response:any) => {
    this.goHaylie('partners');
  }
  private catch_plans = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_plans = l_response.plans;

    this.gsap.anima_trg('cronos', '');
    return this.model_plans;
  }
  private catch_categs = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_categs = l_response.categs;

    this.gsap.anima_trg('cronos', '');
    return this.model_categs;
  }
  private catch_register = (p_response: any): void =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.register> ' + l_response);

    this.response_msg = l_response.msg;

    this.gsap.anima_trg('cronos', '');
    
    if (l_response.result) {

      this.frmName.nativeElement.value = '';
      this.frmMail.nativeElement.value = '';
      this.frmPhone.nativeElement.value = '';
      this.frmPlan.nativeElement.value = '';
      this.frmDtInit.nativeElement.value = '';

      this.model_since = new Date();
      this.padawanphoto = 0; 
      this.haylie.outsider_photo(false, 0);

      //this.gsap.anima_trg('outsider', 'sucess');

      this.angel.player_compass(false, 'home');
      //this.haylie.player_compass(false, 'home');

      this.gsap.player_pages('home');
    } 
    else {
      this.gsap.anima_trg('outsider', 'denied2');
    }
  }


    private catch_alert = (p_response: any): void =>  {
      var l_response = JSON.parse(p_response);
      console.log('catch.alert> ' + l_response);
  
      this.response_msg = l_response.msg;
  
      //this.gsap.anima_trg('cronos', '');
      
      if (l_response.result == true) {
  
        this.frmAlertTitle.nativeElement.value = '';
        this.frmAlertTxt.nativeElement.value = '';
        this.frmAlertPlan.nativeElement.value = '';
        //this.frmAlertTitle.nativeElement.value = '';
  
        this.model_limit = new Date();
        
        //this.gsap.anima_trg('outsider', 'sucess');
  
        this.angel.player_compass(false, 'home');
        //this.haylie.player_compass(false, 'home');
  
        this.gsap.player_pages('home');
      } 
      else {
        this.gsap.anima_trg('outsider', 'denied2');
      }
      
    }
    private catch_sugg = (p_response: any): void =>  {
      var l_response = JSON.parse(p_response);
      console.log('catch.alert> ' + l_response);
  
      this.response_msg = l_response.msg;
  
      //this.gsap.anima_trg('cronos', '');
      
      if (l_response.result == true) {
  
        this.frmSuggTitle.nativeElement.value = '';
        this.frmSuggTxt.nativeElement.value = '';
        
        this.angel.player_compass(false, 'home');
        //this.haylie.player_compass(false, 'home');
  
        this.gsap.player_pages('home');
      } 
      else {
        this.gsap.anima_trg('outsider', 'denied2');
      }
      
    }
  content_refresh() {
    this.haylie.get_plans().then(this.catch_plans);

    this.haylie.get_categs().then(this.catch_categs);
  }
  ngOnChanges() { 
    if (this.activated) { 
      this.padawanphoto = 0; 
      this.haylie.outsider_photo(false, 0);
      this.content_refresh();
    } 

    this.haylie.get_categs().then(this.catch_categs);

   }
  
  ngOnInit(): void {
  }
  goHaylie(p_action:string): void {

    switch(p_action) {
      case "partners":
            this.gsap.magic_trg('#frmpartner', 'easyvanish');
            this.gsap.magic_trg('#page_partner .fixed_action', 'easybuild');
            //this.page_frm = false;
            this.angel.player_compass(false, 'partners');
            this.gsap.player_pages('partners');

            break;

      case "products":
            this.gsap.magic_trg('#frmproduct', 'easyvanish');
            this.gsap.magic_trg('#page_products .fixed_action', 'easybuild');
            //this.page_frm = false;
            this.angel.player_compass(false, 'products');
            this.gsap.player_pages('products');

            break;

          case "back":
            this.angel.player_compass(false, 'home');
            this.gsap.player_pages('home');

            //duhflag this.gsap.magic_trg('#frmagenda .fixed_action', 'easybuild');
            break;

          
          case "suggestion":
              this.frmcheck = true;
              
              if (this.frmSuggTitle.nativeElement.value.length <= 0)
              { this.frmcheck = false; console.log('suggerror> missing title'); }
              else  
                this.alerttitle = this.frmSuggTitle.nativeElement.value;
              
              if (this.frmSuggTxt.nativeElement.value.length <= 0)
              { this.frmcheck = false; console.log('suggerror> missing txt'); }
              else  
                this.alerttxt = this.frmSuggTxt.nativeElement.value.replace("\n","<br/>").toString();
  
              while(this.alerttxt.indexOf("\n") > 0) 
                this.alerttxt = this.alerttxt.replace("\n","<br/>").toString();
  
  
              console.log(" sugg_txt-x>>> " + this.alerttxt);
              
              var newalert = new eAlert() 
              newalert.id = "0";
              newalert.title = this.alerttitle;
              //newalert.resume = this.alerttxt;
              newalert.msg = this.alerttxt; 
              newalert.id_plan = 0;
              newalert.id_type = 2;
  
              if (this.frmcheck) {
                this.haylie.send_alert(newalert).then(this.catch_sugg);
              }  
              else 
                this.gsap.anima_trg('frm', 'newsugg');
              
              console.log(p_action + " sugg>>>" + this.frmAlertTitle.nativeElement.value)
              
            break;

          case "alert":
            this.frmcheck = true;

            if (this.frmAlertTitle.nativeElement.value.length <= 0)
            { this.frmcheck = false; console.log('alerterror> missing title'); }
            else  
              this.alerttitle = this.frmAlertTitle.nativeElement.value;
            
            if (this.frmAlertTxt.nativeElement.value.length <= 0)
            { this.frmcheck = false; console.log('alerterror> missing txt'); }
            else  
              this.alerttxt = this.frmAlertTxt.nativeElement.value.replace("\n","<br/>").toString();

            while(this.alerttxt.indexOf("\n") > 0) 
              this.alerttxt = this.alerttxt.replace("\n","<br/>").toString();


            console.log(" alert_txt-x>>> " + this.alerttxt);
            /*
            if (this.frmAlertPlan.nativeElement.value.length <= 0)
            { this.frmcheck = false; console.log('alerterror> missing plan'); }
            else  
              this.alertplan = this.frmAlertPlan.nativeElement.value;

            
            if (this.frmDtInit.nativeElement.value.length <= 0)
            { this.frmcheck = false; console.log('padawanerror> missing init'); }
            else  
              this.padawaninit = this.frmDtInit.nativeElement.value;
            */
            var newalert = new eAlert() 
            newalert.id = "0";
            newalert.title = this.alerttitle;
            //newalert.resume = this.alerttxt;
            newalert.msg = this.alerttxt; 
            newalert.id_plan = 0;
            newalert.id_type = 1;

            if (this.frmcheck) {
              this.haylie.send_alert(newalert).then(this.catch_alert);
            }  
            else 
              this.gsap.anima_trg('frm', 'newalert');
            
              
            console.log(p_action + " alert>>>" + this.frmAlertTitle.nativeElement.value)
            break;

          case "register":
            this.frmcheck = true;

            if (this.frmName.nativeElement.value.length <= 0)
            { this.frmcheck = false; console.log('padawanerror> missing name'); }
            else  
              this.padawan = this.frmName.nativeElement.value;
            
            if (this.frmMail.nativeElement.value.length <= 0)
            { this.frmcheck = false; console.log('padawanerror> missing mail'); }
            else  
              this.padawanmail = this.frmMail.nativeElement.value;
            
            if (this.frmPhone.nativeElement.value.length <= 0)
            { this.frmcheck = false; console.log('padawanerror> missing phone'); }
            else  
              this.padawanphone = this.frmPhone.nativeElement.value;
            
            if (this.frmPlan.nativeElement.value.length <= 0)
            { this.frmcheck = false; console.log('padawanerror> missing plan'); }
            else  
              this.padawanplan = this.frmPlan.nativeElement.value;

            if (this.frmDtInit.nativeElement.value.length <= 0)
            { this.frmcheck = false; console.log('padawanerror> missing init'); }
            else  
              this.padawaninit = this.frmDtInit.nativeElement.value;
      

            if (this.haylie.outsider_photo(true, 0) <= 0)
            { this.frmcheck = false; console.log('padawanerror> missing photo'); }
            else  
              this.padawanphoto = this.haylie.outsider_photo(true, 0);

            if (this.frmcheck) {
              this.haylie.outsider_letmein(this.padawan, this.padawanmail, this.padawanphone, this.padawanplan, this.padawanphoto.toString(), this.padawaninit).then(this.catch_register);
            }  
            else 
              this.gsap.anima_trg('frm', 'newguy');
            
              
            console.log(p_action + " padawan>>>" + this.frmName.nativeElement.value)
            break;

            case "partner":
              this.frmcheck = true;
  
              if (this.frmPartnerCompany.nativeElement.value.length <= 0)
              { this.frmcheck = false; console.log('partnererror> missing company'); }
              else  
                this.partnercompany = this.frmPartnerCompany.nativeElement.value;
  
              if (this.frmPartnerName.nativeElement.value.length <= 0)
              { this.frmcheck = false; console.log('partnererror> missing name'); }
              else  
                this.partnername = this.frmPartnerName.nativeElement.value;
  
              if (this.frmPartnerCnpj.nativeElement.value.length <= 0)
              { this.frmcheck = false; console.log('partnererror> missing cnpj'); }
              else  
                this.partnercnpj = this.frmPartnerCnpj.nativeElement.value;
              
              if (this.frmPartnerMail.nativeElement.value.length <= 0)
              { this.frmcheck = false; console.log('partnererror> missing mail'); }
              else  
                this.partnermail = this.frmPartnerMail.nativeElement.value;
              
              if (this.frmPartnerPhone.nativeElement.value.length <= 0)
              { this.frmcheck = false; console.log('partnererror> missing phone'); }
              else  
                this.partnerphone = this.frmPartnerPhone.nativeElement.value;
              
              
              /*if (this.haylie.outsider_photo(true, 0) <= 0)
              { this.frmcheck = false; console.log('padawanerror> missing photo'); }
              else  
                this.padawanphoto = this.haylie.outsider_photo(true, 0);
              */

              if (this.frmcheck) {
                this.haylie.send_partner(0, this.partnercompany, this.partnercnpj, this.partnername, this.partnermail, this.partnerphone).then(this.catch_partner);
              }  
              else 
                this.gsap.anima_trg('frm', 'newpartner');
              
                
              console.log(p_action + " partner>>>" + this.frmPartnerName.nativeElement.value)
              break;
    }
  }
  

}
