<div id="page_leads">
    <div id="box-pages" class="block_pages">
        <div class="head">
            <fa-icon [icon]="FAIconAstronaut" class="pageicon"></fa-icon>
            <h1 class="title">PRÉ-CADASTROS</h1>
            <br/>
            <p class="total" *ngIf="model_leads.length > 0">{{ model_leads.length }} registros</p>
        </div>

        <div class="window" style="opacity: 1;">
            <ul *ngIf="model_leads.length > 0 && !page_rpt" style="padding-bottom: 9px;opacity: 1;" >
                <li id="phome-lileadbox{{ reg.id }}" *ngFor="let reg of model_leads" class="content box alivebg{{ reg.alive }}"  style="opacity: 1;padding-bottom: 9px;">
                    <div id="leadbox{{ reg.id }}" class="alertbox">
                        <!-- <fa-icon [icon]="FAIconAstronaut" class="boxicon"></fa-icon> -->
                        <h4 style="font-weight: 600; padding: 18px 0 9px 0;">{{ reg.name }}</h4>
                        <p style="line-height: 25px;">
                            <b>{{ reg.phone  }}</b><br/>
                            {{ reg.mail  }}</p>
                        <a class="btn_check wpp" target="_blank" href="https://api.whatsapp.com/send?phone=+5511{{ reg.phone.replace('119', '9') }}&text=">
                            <fa-icon [icon]="FAIconWhats" class="ico"></fa-icon> 
                        </a>
                        <a *ngIf="reg.alive == 1" class="btn_check fix" (click)="goHaylie('leadcheck', reg.id)">
                            <fa-icon [icon]="FAIconDelete" class="ico"></fa-icon> 
                        </a>

                        <span class="srt">
                            {{ reg.dateins.substr(8, 2) + '/' + reg.dateins.substr(5, 2) + '/' + reg.dateins.substr(0, 4) }}<br/>
                            {{ reg.dateins.substr(11, 5) }}
                        </span>
                    </div>
                </li>
            </ul>

            <div id="frmleadsrpt" *ngIf="page_rpt" class="content box"
                style="opacity: 1; position: absolute;top: 99px;box-shadow: 1px 2px 2px #000;">
            
                <!-- bars . leads --->
                <div *ngIf="model_rptleads.length > 0" class="content box" 
                    style="position: relative; opacity: 1; padding-bottom: 7px;">
                    <fa-icon [icon]="FAIconDash" class="boxicon"
                                    style="position: absolute; float: left; margin: 9px;"></fa-icon>
                    <span class="srt" style="top: 43px;text-align: right;">PRÉ-CADASTROS<br/>{{current_y}}</span>
                    <ul class="rptbars">
                        <li *ngFor="let reg of model_rptleads">
                            <p>{{ reg.total }}</p>
                            <a style='{{ "height:" + (reg.total * 3) + "px" }}'></a>
                            <h4>{{ lmorgana.getmonthname(reg.label, false) }}</h4>
                        </li>
                    </ul>
                    <p class="totals">total <span>{{ totaly }}</span></p>
                </div>
                <!-- bars . leads - lastYear --->
                <div *ngIf="lhaylie.player_permission(true, 0) == 1 && model_rptleadsly.length > 0" class="content box" style="position: relative; opacity: 1;">
                    <fa-icon [icon]="FAIconDash" class="boxicon"
                                    style="position: absolute; float: left; margin: 9px;"></fa-icon>
                    <span class="srt" style="top: 43px;text-align: right;">PRÉ-CADASTROS<br/>{{current_ly}}</span>
                    <ul class="rptbars">
                        <li *ngFor="let reg of model_rptleadsly">
                            <p>{{ reg.total }}</p>
                            <a style='{{ "height:" + (reg.total * 3) + "px" }}'></a>
                            <h4>{{ lmorgana.getmonthname(reg.label, false) }}</h4>
                        </li>
                    </ul>
                    <p class="totals">total <span>{{ totally }}</span></p>
                </div> 

                <a class="btnReturn" (click)="switchFrm('init')">
                    VOLTAR</a>
            
            </div>

        </div> 

        <a id="btn_frm" class="fixed_action" 
            (click)="switchFrm('rpt')">
            <fa-icon [icon]="FAIconDash" class="ico" style="font-size: 21px;"></fa-icon>
        </a>
</div>