import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import { faUsers, faTimes, faPlus, faUserNinja, faEllipsisV, faComments, faCookieBite, faBox, faPallet } from '@fortawesome/free-solid-svg-icons'
import { AngelService } from '../services/angel.service';

import { ePartner } from '../services/entities'
import { GsapService } from '../services/gsap.service'
import { HaylieService } from '../services/haylie.service'

@Component({
  selector: 'app-page-products',
  templateUrl: './page-products.component.html',
  styleUrls: ['./page-products.component.css']
})
export class PageProductsComponent implements OnInit {
pageFAIcon = faCookieBite;
FAIconPlus = faPlus;
FAIconClose = faTimes; 
FAIconActions = faEllipsisV;
FAIconProduct = faPallet; 
FAIconWhats = faWhatsapp;
FAIconMsg = faComments;
player_live = false;
model_products: ePartner[] = [];
model_productsorg: ePartner[] = [];
page_frm = false;

@Input() activated: boolean = false;
  
  constructor(private angel: AngelService, 
    private gsap: GsapService, private haylie: HaylieService, private router: Router) 
  { 
    
  }
  ngOnChanges() {
    this.page_frm = false;
    
    if (this.activated) { this.content_refresh(); console.log('---changesrun.team'); }
  }
  content_refresh() {
    this.gsap.anima_trg('cronos', 'showup');
    
    
    if (this.haylie.player_token(true, '').length > 0) {
      console.log('player> ' + this.haylie.player_token(true, ''));
      this.get_products();

      this.player_live = true;
    }
    else {
      this.player_live = false; 

      this.gsap.magic_trg("#outsider_wrapper", "build");
      this.router.navigateByUrl('octalogin');
    }
  }
  ngOnInit(): void {
    
  }
  get_products(): void {
    this.model_products = [];
    this.model_productsorg = [];

    this.haylie.get_products("1").then(this.catch_products);
  }
  /*compare( a: ePadawan, b: ePadawan) {
    if ( a._name < b._name ){
      return -1;
    }
    if ( a._name > b._name ){
      return 1;
    }
    return 0;
  }*/
  private catch_products = (p_response: any): void =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.products> ' + l_response.products);

    if (l_response.products.length > 0) {
      this.gsap.anima_trg('cronos', '');
      this.model_products = l_response.products;
      this.model_productsorg = this.model_products;
    } 
    
  }
  goBack():void {
    this.router.navigateByUrl("octahome");
  }
  goHaylie(p_action: string, p_target: string): void{
    console.log(p_action + ">" + p_target);

    /*switch(p_action) {
      case "profile":
        this.angel.profile_token(false, p_target);
        this.angel.player_compass(false, p_action);
        this.gsap.player_pages(p_action);
      break;

      case "gomsgs":
        console.log('gomsgs-loguser> ' + p_target); 
        this.angel.player_target(false, p_target);
        let buddy = this.model_padawansorg.filter(x => x.id == p_target); //duhflag
        this.angel.player_padawan(false, buddy[0]);   
        this.angel.player_compass(false, "msgs");
        this.gsap.player_pages("msgs");
      break;
    }*/

  }

  searchList(p_key:any):void {
    console.log('--- search key --- >>> ' + p_key);
    this.model_products = [];
    if (p_key.target.value.length > 0)
      this.model_products = this.model_productsorg.filter(x => x.name.toLowerCase().indexOf(p_key.target.value.toLowerCase()) >= 0);
    else 
      this.model_products = this.model_productsorg;

  }
  
  switchFrm(p_action:string):void {
    if (p_action == 'on') { 
      // this.model_frm_id = 0; 
      // this.model_frm_skill = '';
      // this.model_frm_day = '';
      // this.model_frm_time = '';
    
      this.page_frm = true;
      this.gsap.magic_trg('#frmproduct', 'easybuild');
      this.gsap.magic_trg('#page_products .fixed_action', 'vanish');
    }
    else { 
      this.gsap.magic_trg('#frmproduct', 'easyvanish');
      this.gsap.magic_trg('#page_products .fixed_action', 'easybuild');
      this.page_frm = false;
    }



  }

}
