<div *ngIf="player_live">

    <app-master-header></app-master-header>

    <app-master-sidebar 
        style="overflow-x:hidden;position: relative; float: left; width: 100%; height: 100%;"></app-master-sidebar>

    <div id="box-pages" class="block_pages" style="background: #aaa">

        <div id="playerpage_home" class="playerpage" style="float: left;padding-bottom: 90px;">
            <img id="maincross" src="../assets/img/categs/newfight-cross-white.png" 
            style="width: 90px;opacity: 1;left: 50%;position: relative;margin-left: -45px;float:left;" />

            <!-- leads --->
            <div id="block-alerts" style="opacity: 1;" *ngIf="model_leads && lhaylie.player_permission(true, 0) == 1">
                <ul style="padding-bottom: 9px;opacity: 1;" >
                    <li id="phome-lileadbox{{ reg.id }}" *ngFor="let reg of model_leads" class="content box"  style="opacity: 1;padding-bottom: 9px;">
                        <div id="leadbox{{ reg.id }}" class="alertbox" style="position: relative;">
                            <fa-icon [icon]=" reg._name.length <= 0 ? FAIconAstronaut : FAIconFriends" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;"></fa-icon>
                            <h4 style="font-weight: 600; padding: 18px 0 9px 0;">
                                {{ reg._name.length > 0 ? "INDICAÇÃO" : "PRÉ-CADASTRO" }}
                            </h4>
                            <p style="line-height: 25px;font-size: 12px;">
                                <b>{{ reg.name }}</b><br/>
                                {{ reg.phone  }}<br/>
                                {{ reg.mail  }}</p>
                            <a class="btn_check wpp" target="_blank" href="https://api.whatsapp.com/send?phone=+5511{{ reg.phone.replace('119', '9') }}&text=">
                                <fa-icon [icon]="FAIconWhats" class="ico"></fa-icon> 
                            </a>
                            <!-- <p>{{ reg.msg }}</p> -->
                            <a class="btn_check fix" (click)="goHaylie('leadcheck', reg.id, '')">
                                <fa-icon [icon]="FAIconDelete" class="ico"></fa-icon> 
                            </a>

                            <span class="srt" style="top: 43px;text-align: left;">
                                {{ reg.dateins.substr(8, 2) + '/' + reg.dateins.substr(5, 2) + '/' + reg.dateins.substr(0, 4) }}<br/>
                                {{ reg.dateins.substr(11, 5) }}
                            </span>
                            <span class="info" 
                                *ngIf="reg._name.length > 0"
                                style="float: left;width: auto;position: absolute;bottom: -16px;left: 0;">
                                <fa-icon [icon]="FAIconUser" class="ico" style="color: darkcyan;float: left;display: block;width: 100%;text-align: left;"></fa-icon>
                                <b style="font-family: Arial; color: #333;display:block;
                                    font-size: 9px;text-align: left;margin-left: 3px;">{{  reg._name }}</b><br>
                                
                            </span>
                        </div>
                    </li>
                </ul>
            </div>

            <!-- alerts --->
            <div id="block-alerts" style="opacity: 1;" *ngIf="model_alerts.length > 0">
                <ul style="padding-bottom: 9px;opacity: 1;" >
                    <li id="phome-lialertbox{{ reg.id }}" *ngFor="let reg of model_alerts" class="content box"  style="opacity: 1;padding-bottom: 9px;">
                        <div id="alertbox{{ reg.id }}" class="alertbox" style="position: relative;">
                            <fa-icon [icon]="FAIconAlert" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;color:#ff9900;"></fa-icon>
                            <!-- <h4 style="font-weight: 600; padding: 18px 0 9px 0;">AVISO</h4> -->
                            <p style="line-height: 25px;font-size: 12px;padding-bottom: 9px;text-transform: uppercase;">
                                <b style="
                                    display: inline-block;
                                    border: 1px solid #999;
                                    padding: 3px 27px;
                                    margin-bottom: 9px;
                                    text-transform: uppercase;
                                    letter-spacing: 1.2px;
                                ">{{ reg.title }}</b><br/>
                                <span style="padding: 3px 5%;width: 90%;display: block;margin: 16px 0px;"
                                    [innerHTML]="reg.msg">
                                </span>
                            </p>
                            
                            <a class="btn_fixed_rect" style="background: #ff0000;" 
                                *ngIf="model_alerts && lhaylie.player_permission(true, 0) == 1"
                                (click)="goHaylie('alertcheck', reg.id, '')">
                                remover</a>

                            <span class="srt" style="top: 43px;text-align: left;">
                                COMUNICADO<br/>
                                {{ reg.dateins.substr(8, 2) + '/' + reg.dateins.substr(5, 2) + '/' + reg.dateins.substr(0, 4) }}<br/>
                                
                            </span>
                        </div>
                    </li>
                </ul>
            </div>

            <!-- suggestions --->
            <div id="block-suggestions" style="opacity: 1;" 
                *ngIf="model_suggestions.length > 0 || lhaylie.player_permission(true, 0) > 1">
                <ul *ngIf="lhaylie.player_permission(true, 0) == 1"
                    style="padding-bottom: 9px;opacity: 1;" >
                    <li id="phome-lialertbox{{ reg.id }}" 
                        *ngFor="let reg of model_suggestions" class="content box"  style="opacity: 1;padding-bottom: 9px;">
                        <div id="alertbox{{ reg.id }}" class="alertbox" 
                            style="position: relative;min-height: 144px;">
                            <fa-icon [icon]="FAIconLight" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;color:#aa9900;"></fa-icon>
                            <!-- <h4 style="font-weight: 600; padding: 18px 0 9px 0;">{{ reg.title }}</h4> -->
                            <p style="line-height: 25px;font-size: 12px;padding-bottom: 9px;text-transform: uppercase;">
                                <b style="
                                    display: inline-block;
                                    border: 1px solid #999;
                                    padding: 3px 27px;
                                    margin-bottom: 9px;
                                    text-transform: uppercase;
                                    letter-spacing: 1.2px;
                                ">{{ reg.title }}</b><br/>
                                
                                <span style="padding: 3px 5%;width: 90%;display: block;margin: 16px 0;"
                                    [innerHTML]="reg.msg">
                                </span>
                            </p>
                            
                            <a class="btn_check fix" 
                                *ngIf="lhaylie.player_permission(true, 0) == 1"
                                (click)="goHaylie('alertcheck', reg.id, '')"
                                style="float:right; background: #333;">
                                <fa-icon [icon]="FAIconDelete" class="ico" style="color: #ccc"></fa-icon> 
                            </a>

                            <span class="srt" style="top: 43px;text-align: left;">
                                SUGESTÃO<br/>
                                {{ reg.dateins.substr(8, 2) + '/' + reg.dateins.substr(5, 2) + '/' + reg.dateins.substr(0, 4) }}<br/>
                            </span>
                            <span class="info" 
                                style="float: left;width: auto;position: absolute;bottom: -16px;left: 0;">
                                <fa-icon [icon]="FAIconUser" class="ico" style="color: darkcyan;float: left;display: block;width: 100%;text-align: left;"></fa-icon>
                                <b style="font-family: Arial; color: #333;display:block;
                                    font-size: 9px;text-align: left;margin-left: 3px;">{{  reg._name }}</b><br>
                                
                            </span>
                        </div>
                    </li>
                </ul>
                <div class="content box" style="opacity: 1;" 
                    *ngIf="lhaylie.player_permission(true, 0) > 1">
                    <div id="alertboxusersuggestion" class="alertbox" 
                    style="position: relative;min-height: 144px;">
                        <fa-icon [icon]="FAIconLight" class="boxicon"
                            style="position: absolute; float: left; margin: 9px;color:#aa9900;"></fa-icon>
                    
                        <p style="line-height: 25px;font-size: 12px;padding-bottom: 9px;text-transform: uppercase;">
                            <b style="
                                display: inline-block;
                                border: 1px solid #999;
                                padding: 3px 27px;
                                margin-bottom: 9px;
                                text-transform: uppercase;
                                letter-spacing: 1.2px;
                            ">CAIXA DE SUGESTÕES</b><br/>
                            
                            <span style="padding: 3px 21px;width: 90%;display: block;margin: 3px 0;">
                                Envie sua sugestão<br>nos ajude a melhorar sempre
                            </span>
                        </p>
                        
                        <a class="btn_fixed_rect" 
                            (click)="trg_actions('suggestion')">
                            ESCREVER</a>

                        <span class="srt" style="top: 43px;text-align: left;">
                            SUGESTÃO<br/>
                        </span>
                    </div>    
                </div>    

            </div>

            <!-- msgs --->
            <div id="block-msgs" style="opacity: 1;">
                <ul *ngIf="model_msgs">
                    <li id="phome-limsgbox{{ reg.id }}" *ngFor="let reg of model_msgs" class="content box" 
                        style="opacity: 1;padding-bottom: 9px;position: relative;" >
                        <fa-icon [icon]="FAIconMsg" class="boxicon"
                        style="position: absolute; float: left; margin: 9px;color: aqua;"></fa-icon>
                    
                        <p style="line-height: 25px;position: relative;margin: 9px;top: 30px;padding: 25px 9px;z-index: 1;font-size: 12px;">        
                        <span [innerHTML]="reg.msg"></span></p>

                        <a class="btn_check fix" style="float:right;z-index: 10;position: relative;" (click)="goHaylie('msgcheck', reg.id.toString(), '')">
                            <fa-icon [icon]="FAIconDelete" class="ico"></fa-icon> 
                        </a>
                        <a class="btn_check fix" style="background: #ef0;float:right;z-index: 10;position: relative;" 
                            (click)="goHaylie('gomsgs', reg.log_user.toString(), reg.id.toString())">
                            <fa-icon [icon]="FAIconReply" class="ico" style="color: #111;"></fa-icon> 
                        </a>
                    
                        <span class="srt" style="top: 43px;text-align: left;">
                            MENSAGEM<br/>
                            <!-- {{ reg._datelabel + " " + reg.dateins.substr(11, 5) }} -->
                            {{ reg.dateins.substr(8, 2) + "/" + reg.dateins.substr(5, 2) + "/" + reg.dateins.substr(0, 4) }}<br>
                            {{ reg.dateins.substr(11, 5) }} 
                        </span>
                        <span class="info" style="float: left;width: auto;position: absolute;bottom: 0;left: 0;">
                            <fa-icon [icon]="FAIconUser" class="ico" style="color: darkcyan;float: left;display: block;width: 100%;text-align: left;"></fa-icon>
                            <b style="font-family: Arial; color: #333;display:block;
                                font-size: 9px;text-align: left;margin-left: 3px;">{{  reg._name }}</b><br>
                        </span>
                    </li>
                </ul>
            </div>

            <!-- padawans --->
            <div id="block-padawans" style="opacity: 1;" >
                <ul *ngIf="model_padawans && lhaylie.player_permission(true, 0) == 1">
                    <li id="phome-limemberbox{{ reg.token }}" *ngFor="let reg of model_padawans" class="content box" style="opacity: 1;padding-bottom: 9px;" >
                        <div id="memberbox{{ reg.token }}" class="alertbox" 
                            style=" position: relative; min-height: 340px; max-height: 399px;overflow:hidden;">
                            <!-- <fa-icon [icon]="FAIconNinja" class="boxicon"></fa-icon> -->
                            <img src="{{ 'http://haylie.bosswebapps.net/padawan/' + reg._avatar }}" 
                                style="margin: 9px; width: 50%; height: auto; border-radius:100%; box-shadow: 1px 2px 2px rgb(255, 255, 255, .3);  " />
        
                            <div style="float: left; width: 100%; position: absolute; bottom: 0; background: #fff;">
                                <fa-icon [icon]="FAIconPadawan" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;"></fa-icon>

                                <!-- <h4 style="font-weight: 600; padding: 9px 0 9px 0;">NOVO MEMBRO</h4> -->
                                <p style="font-size: 16px;line-height: 21px;">{{ reg._name }}
                                    <br>{{ reg.mail }}
                                    <br>{{ reg._phone }}</p>
                                <!-- <p>{{ reg. }}</p> -->
                                <a class="btn_check wpp" target="_blank" href="https://api.whatsapp.com/send?phone=+5511{{ reg._phone.replace('119', '9') }}&text=">
                                    <fa-icon [icon]="FAIconWhats" class="ico"></fa-icon> 
                                </a>
                                <a class="btn_check" (click)="goHaylie('padawancheck', reg.token, '')" 
                                    style="background: #0061ff;padding: 9px 7px;">
                                    <fa-icon [icon]="FAIconCheck" class="ico"></fa-icon> 
                                </a>
                                <a class="btn_check fix" (click)="goHaylie('padawanrefuse',  reg.token, '')">
                                    <fa-icon [icon]="FAIconDelete" class="ico"></fa-icon> 
                                </a>
                                
                                <span class="srt" style="top: 43px;text-align: left;">
                                    NOVO MEMBRO<br>
                                    {{ reg.dateins.substr(8, 2) + "/" + reg.dateins.substr(5, 2) + "/" + reg.dateins.substr(0, 4) }}<br/>
                                    {{ reg.dateins.substr(11, 5) }}
                                </span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <!-- classes . today --->
            <div id="boxtoday" class="content box">
                <fa-icon [icon]="FAIconToday" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;color:green"></fa-icon>
                <span class="srt">HOJE<br/>{{ current_date_label.substr(0, 10) }}</span>
                
                <a class="btn_check fix" id ="btn_today_more"
                    style="float:right;z-index: 10;position: relative;background: transparent; margin: 9px;" 
                    (click)="trg_actions('todayopen')"
                    *ngIf="model_today.length > 0">
                    <fa-icon [icon]="FAIconPlus" class="ico" style="color: #aaa;"></fa-icon> 
                </a>
                <a class="btn_check fix" id ="btn_today_less"
                    style="float:right;z-index: 10;position: relative;background: transparent; margin: 9px; display: none;" 
                    (click)="trg_actions('todayclose')"
                    *ngIf="model_today.length > 0">
                    <fa-icon [icon]="FAIconMinus" class="ico"  style="color: #aaa;"></fa-icon> 
                </a>
                <br/><br/><br/>
                <fa-icon [icon]="FAIconMembers" 
                    *ngIf="model_today.length > 0 && lhaylie.player_permission(true, 0) == 1"  
                            style="position: absolute;right: 21%;top: 108px;color: #666;"></fa-icon>
                <fa-icon [icon]="FAIconDove" *ngIf="model_today.length <= 0"  
                            style="position: absolute;right: 21%;margin: 9px;color: #666;"></fa-icon>
    
                <div *ngIf="model_today.length <= 0" class="empty">
                    <p style="text-align: center;color: #333;font-family: monospace;
                    margin-top: 30px;font-size: 13px;">Nenhuma aula registrada para hoje</p>
                </div>
                <div *ngIf="model_today.length > 0" class="empty">    
                    <h4 style="position: relative;top: -30px;font-size: 12px;float: left;
                            width: 70%;line-height: 25px; margin: 3px 15%;">
                        <a style="color: #66b8ff;font-weight: 600;font-family: mysansbold;"
                            (click)="trg_actions('todayopen')">
                            {{ model_today.length }} aulas</a> registradas para hoje
                        <br/>
                        <p *ngIf="lhaylie.player_permission(true, 0) == 1">
                            {{ model_today_confirmations }} agendamentos</p>
                    </h4>
                </div>
                <!-- <div id="frmsearch" class="frm" style="min-height: 34px;">
                    <input #search_skill class="inputtxt" type="text" 
                        placeholder="Categoria" list="search_skills" 
                        value="{{ model_search_skill }}" 
                        (change)="select_change('search', search_skill.value)"
                        (focus)="search_skill.value='';"
                        (click)="search_skill.value='';"
                     />
                    <datalist id="search_skills">
                        <option value="jiu">Jiu Jitsu</option>
                        <option value="thay">Muay Thay</option>
                        <option value="boxe">Boxe</option>
                    </datalist>
                </div> -->
                <ul id="boxtodaylist" *ngIf="model_today.length > 0" style="padding-bottom: 9px;opacity: 1;margin: 2.5%;width: 90%;">
                    <li *ngFor="let reg of model_today" 
                        id="phome-liplanbox{{ reg.id }}" 
                        style="opacity: 1;padding: 0;width: 100%;display: block;float: left; font-size: 12px;" >
                        <div style="margin-top: 9px;margin-left: 0%;">
                            <img class="boxicon" 
                                style="margin: 0;
                                width: 34px;
                                top: 18px;
                                left: 0px;
                                position: relative;
                                float: left;margin-right: 16px;"
                                    src="{{ '../assets/img/categs/' + reg.skill + '.png' }}" />
                                 
                                 <h4 style="font-weight: 600;padding: 18px 0 9px 9px;display: inline-block;float: left;width: 63%;border-bottom: 1px solid;">
                                    <!-- <span style="float: left;width: 45px;">{{ reg.skill }}</span>  -->
                                        {{ reg.time }}
                                    <span style="float: right;width: 21px;"
                                        *ngIf="lhaylie.player_permission(true, 0) == 1">
                                        {{ reg._count }}
                                    </span>
                                </h4> 
                        </div>
                        <a class="btn_check fix"
                            *ngIf="reg._count > 0 && lhaylie.player_permission(true, 0) == 1" 
                            (click)="goHaylie('classdetail', reg.id.toString(), '')"
                            style="float: right;background: #0095f6;margin: 7px;">
                                    <fa-icon [icon]="FAIconPlus" class="pageicon"
                                    style="font-size: 16px; display: block; margin: 9px;  
                                            text-align: left; color: #eee;"></fa-icon>
                                </a>
                        <a class="btn_check fix"
                            *ngIf="lhaylie.player_permission(true, 0) == 2 && reg._countme <= 0" 
                            (click)="goHaylie('classcheck', reg.id.toString(), '')"
                            style="float: right;background: #0095f6;margin: 7px;">
                                    <fa-icon [icon]="FAIconCheck" class="pageicon"
                                    style="font-size: 16px; display: block; margin: 9px;  
                                            text-align: left; color: #eee;"></fa-icon>
                                </a>
                        <a class=""
                            *ngIf="lhaylie.player_permission(true, 0) == 2 && reg._countme > 0" 
                            style="float: right;background: transparent;margin: 7px;">
                                    <fa-icon [icon]="FAIconToday" class="pageicon"
                                    style="font-size: 16px; display: block; margin: 9px;  
                                            text-align: left; color: lime;"></fa-icon>
                                </a>
                    </li>
                    <!-- <li style="height: 90px;float: left; width: 90%;"></li> -->
                </ul>
            </div>   
            
            <!-- bars . leads --->
            <div *ngIf="lhaylie.player_permission(true, 0) == 1 && model_rptleads.length > 0" class="content box" style="position: relative; opacity: 1;">
                <fa-icon [icon]="FAIconDash" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;color:lime;"></fa-icon>
                <span class="srt" style="top: 43px;text-align: right;">PRÉ-CADASTROS<br/>{{current_y}}</span>
                <ul class="rptbars">
                    <li *ngFor="let reg of model_rptleads">
                        <p>{{ reg.total }}</p>
                        <a style='{{ "height:" + (reg.total) + "px;background-color:" + reg.color }}'></a>
                        <h4>{{ lmorgana.getmonthname(reg.label, false) }}</h4>
                    </li>
                </ul>
                <p class="totals">total <span>{{ totaly }}</span></p>
            </div>
            <!-- bars . leads - lastYear --->
            <div *ngIf="lhaylie.player_permission(true, 0) == 1 && model_rptleadsly.length > 0" class="content box" style="position: relative; opacity: 1;">
                <fa-icon [icon]="FAIconDash" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;color:lime;"></fa-icon>
                <span class="srt" style="top: 43px;text-align: right;">PRÉ-CADASTROS<br/>{{current_ly}}</span>
                <ul class="rptbars">
                    <li *ngFor="let reg of model_rptleadsly">
                        <p>{{ reg.total }}</p>
                        <a style='{{ "height:" + (reg.total) + "px;background-color:" + reg.color }}'></a>
                        <h4>{{ lmorgana.getmonthname(reg.label, false) }}</h4>
                    </li>
                </ul>
                <p class="totals">total <span>{{ totally }}</span></p>
            </div>
            <!-- bars . leads - lastYear 2 --->
            <div *ngIf="lhaylie.player_permission(true, 0) == 1 && model_rptleadsly2.length > 0" class="content box" style="position: relative; opacity: 1;">
                <fa-icon [icon]="FAIconDash" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;color:lime;"></fa-icon>
                <span class="srt" style="top: 43px;text-align: right;">PRÉ-CADASTROS<br/>{{current_ly2}}</span>
                <ul class="rptbars">
                    <li *ngFor="let reg of model_rptleadsly2">
                        <p>{{ reg.total }}</p>
                        <a style='{{ "height:" + (reg.total) + "px;" }}'></a>
                        <h4>{{ lmorgana.getmonthname(reg.label, false) }}</h4>
                    </li>
                </ul>
                <p class="totals">total <span>{{ totally2 }}</span></p>
            </div>

            <!-- bars . whats - year --->
            <div *ngIf="lhaylie.player_permission(true, 0) == 1 && model_rptwhats.length > 0" class="content box" style="position: relative; opacity: 1;">
                <fa-icon [icon]="FAIconDash" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;color:lime;"></fa-icon>
                <span class="srt" style="top: 43px;text-align: right;">WHATSAPP<br/>{{current_y}}</span>
                <ul class="rptbars">
                    <li *ngFor="let reg of model_rptwhats">
                        <p>{{ reg.total }}</p>
                        <a style='{{ "height:" + (reg.total) + "px;" }}'></a>
                        <h4>{{ lmorgana.getmonthname(reg.label, false) }}</h4>
                    </li>
                </ul>
                <p class="totals">total <span>{{ totallly }}</span></p>
            </div>
            <!-- bars . whats - lastYear --->
            <div *ngIf="lhaylie.player_permission(true, 0) == 1 && model_rptwhats2.length > 0" class="content box" style="position: relative; opacity: 1;">
                <fa-icon [icon]="FAIconDash" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;color:lime;"></fa-icon>
                <span class="srt" style="top: 43px;text-align: right;">WHATSAPP<br/>{{current_ly}}</span>
                <ul class="rptbars">
                    <li *ngFor="let reg of model_rptwhats2">
                        <p>{{ reg.total }}</p>
                        <a style='{{ "height:" + (reg.total) + "px;" }}'></a>
                        <h4>{{ lmorgana.getmonthname(reg.label, false) }}</h4>
                    </li>
                </ul>
                <p class="totals">total <span>{{ totallly2 }}</span></p>
            </div>

            <!-- bars . agenda ->
            <div *ngIf="model_rptagenda.length > 0" class="content box" style="position: relative; opacity: 1;">
                <fa-icon [icon]="FAIconDash" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;color:lime;"></fa-icon>
                <span class="srt" style="top: 43px;text-align: right;">AGENDAMENTOS<br/>{{current_y}}</span>
                <ul class="rptbars">
                    <li *ngFor="let reg of model_rptagenda">
                        <p>{{ reg.total }}</p>
                        <a style='{{ "height:" + (reg.total * 3) + "px" }}'></a>
                        <h4>{{ lmorgana.getmonthname(reg.label, false) }}</h4>
                    </li>
                </ul>
                <p class="totals">total <span>{{ totalyagenda }}</span></p>
            </div>--->

            <!-- game -->
            <div class="content box" style="position: relative; opacity: 1;"
                *ngIf="model_game.length > 0">
                <fa-icon [icon]="FAIconCoins" class="boxicon"
                        style="position: absolute; float: left; margin: 9px;color: blueviolet;"></fa-icon>
                <span class="srt" style="top: 43px;text-align: right;">PONTOS<br/>.</span>
                <div *ngIf="model_game.length <= 0" class="empty">
                    <p style="text-align: center;color: #333;
                    margin-top: 30px;font-size: 13px;">Nenhum registro encontrado</p>
                </div>  
                
                <fa-icon [icon]="FAIconGem" class="boxicon"
                style="position: relative;margin: 9px;color: #666;font-size: 61px;margin-top: 30px;"></fa-icon>

                <h4 *ngIf="model_game.length > 0" style="position: relative;top: 34px;">
                    <a style="color: #66b8ff;font-weight: 600;font-family: mysansbold;"
                        (click)="goPage('octaplans', 'plans')">
                        {{ model_game.length }} pontos</a> disponíveis
                </h4>
                <br/><br/><br/>  
                <a class="btn_fixed_rect" (click)="goPage('octapoints', 'points')">
                    desafios</a>
                <br/><br/>  
                
                <a class="btn_fixed_rect" (click)="goPage('octapoints', 'points')">
                    ver mais</a>
            </div>

            <ul id="masternav">
                
                <li *ngIf="lhaylie.player_permission(true, 0) == 1">
                    <div class="content box" 
                        style="position: relative; opacity: 1;padding-bottom: 9px;
                            width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
                    
                        <fa-icon [icon]="FAIconPartners" 
                            class="boxicon" 
                            style="position: relative;  margin: 9px; color: #333;font-size: 52px;" ></fa-icon>
                    
                        <span class="srt" 
                            style="top: 16px; text-align: center;width: 100%;
                            position: relative;float: left;font-size: 9px;letter-spacing: 3px;">FORNECEDORES<br /></span>
                        <br/>
                        <a class="btn_fixed_rect"
                            (click)="trg_actions('partners')"></a>
                    </div>
                </li>
                <li *ngIf="lhaylie.player_permission(true, 0) == 2">
                    <div class="content box" 
                        style="position: relative; opacity: 1;padding-bottom: 9px;
                            width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
                    
                        <fa-icon [icon]="FAIconTeachers" 
                            class="boxicon" 
                            style="position: relative;  margin: 9px; color: #333;font-size: 52px;" ></fa-icon>
                    
                        <span class="srt" 
                            style="top: 16px; text-align: center;width: 100%;
                            position: relative;float: left;font-size: 9px;letter-spacing: 3px;">PROFESSORES<br /></span>
                        <br/>
                        <a class="btn_fixed_rect"
                            (click)="trg_actions('staff')"></a>
                    </div>
                </li>
                <li>
                    <div class="content box" 
                        style="position: relative; opacity: 1;padding-bottom: 9px;
                            width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
                    
                        <fa-icon [icon]="FAIconProducts" 
                            class="boxicon" 
                            style="position: relative;  margin: 9px; color: #666;font-size: 52px;" ></fa-icon>
                    
                        <span class="srt" 
                            style="top: 16px; text-align: center;width: 100%;
                            position: relative;float: left;font-size: 9px;letter-spacing: 3px;">PRODUTOS<br /></span>
                        <br/>
                        <a class="btn_fixed_rect"
                            (click)="trg_actions('products')"></a>
                    </div>
                </li>
                <li>
                    <div class="content box" 
                        style="position: relative; opacity: 1;padding-bottom: 9px;
                            width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
                    
                        <fa-icon [icon]="FAIconOrders" 
                            class="boxicon" 
                            style="position: relative;  margin: 9px; color: #999;font-size: 52px;" ></fa-icon>
                    
                        <span class="srt" 
                            style="top: 16px; text-align: center;width: 100%;
                            position: relative;float: left;font-size: 9px;letter-spacing: 3px;">PEDIDOS<br /></span>
                        <br/>
                        <a class="btn_fixed_rect"
                            (click)="trg_actions('orders')"></a>
                    </div>
                </li>
                
            </ul>
            
            <!-- plans --->
            <div class="content box" style="position: relative; opacity: 1;"
                *ngIf="model_plans.length > 0">
                <fa-icon [icon]="FAIconPlans" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;color: blueviolet;"></fa-icon>
                <span class="srt" style="top: 43px;text-align: right;">PLANOS<br/>.</span>
                <div *ngIf="model_plans.length <= 0" class="empty">
                    <p style="text-align: center;color: #333;
                    margin-top: 30px;font-size: 13px;">Nenhum registro encontrado</p>
                </div>  
                <h4 *ngIf="model_plans.length > 0" style="position: relative;top: 34px;">
                    <!-- Você possui  -->
                    <a style="color: #66b8ff;font-weight: 600;font-family: mysansbold;"
                        (click)="goPage('octaplans', 'plans')">
                        {{ model_plans.length }} planos</a> ativos
                </h4>
                <br/><br/><br/>  
                <a class="btn_fixed_rect" (click)="goPage('octaplans', 'plans')">
                    ver todos</a>
            </div>

            <!-- classes --->
            <div class="content box" style="position: relative; opacity: 1;">
                <fa-icon [icon]="FAIconClasses" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;color: crimson;"></fa-icon>
                <span class="srt" style="top: 43px;text-align: right;">AULAS<br/>.</span>
                <div *ngIf="model_classes.length <= 0" class="empty">
                    <p style="text-align: center;color: #333;
                    margin-top: 30px;font-size: 13px;">Nenhum registro encontrado</p>
                </div>  
                <h4 *ngIf="model_classes.length > 0" style="position: relative;top: 34px;">
                    <a style="color: #66b8ff;font-weight: 600;font-family: mysansbold;"
                        (click)="goPage('octaclasses', 'classes')">
                        {{ model_classes.length }} aulas</a> por semana
                </h4>
                <p style="text-align: left;color: #333;margin: 61px 0 -45px 19px;font-size: 13px;border-bottom: 1px solid #ccc;">
                    horários cadastrados:</p>
                <ul class="ulcountlabel">
                    <li>
                        <!-- <img src="{{ '../assets/img/categs/jiu.png' }}"  style="padding-bottom: 9px;" /> -->
                        <h4>jiu</h4><p>{{ count_class_jiu }}</p></li>
                    <li>
                        <!-- <img src="{{ '../assets/img/categs/thay.png' }}" style="padding-bottom: 6.3px;" /> -->
                        <h4>thay</h4><p>{{ count_class_thay }}</p></li>
                    <li>
                        <!-- <img src="{{ '../assets/img/categs/boxe.png' }}" /> -->
                        <h4>boxe</h4><p>{{ count_class_boxe }}</p></li>
                </ul>
                <br/><br/><br/>  
                <a class="btn_fixed_rect" (click)="goPage('octaclasses', 'classes')">
                    ver todos</a>
            </div>

            <!-- members --->
            <div class="content box" style="position: relative; opacity: 1;">
                <fa-icon [icon]="FAIconMembers" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;color: #ff9900;"></fa-icon>
                <span class="srt" style="top: 43px;text-align: right;">MEMBROS<br/>.</span>
                <div *ngIf="model_players.length <= 0" class="empty">
                    <p style="text-align: center;color: #333;
                    margin-top: 30px;font-size: 13px;">Nenhum membro encontrado</p>
                </div>  
                <h4 *ngIf="model_players.length > 0" style="position: relative;top: 34px;">
                    <!-- Você possui  -->
                    <a style="color: #66b8ff;font-weight: 600;font-family: mysansbold;"
                        (click)="goPage('octateam', 'team')">
                        {{ model_players.length }} membros</a> ativos
                </h4>
                <br/><br/>
                <p style="text-align: left;color: #333;margin: 30px 0 -7px 19px;font-size: 13px;border-bottom: 1px solid #ccc;">
                    cadastros recentes:</p>
                <ul class="latest" *ngIf="model_padawanslatest.length > 0">
                    <li *ngFor="let newguy of model_padawanslatest">
                        <img src="{{ 'http://haylie.bosswebapps.net/padawan/' + newguy._avatar }}" 
                            style="margin: 3px 9px;width: 70px;height: 70px;border-radius: 100%;box-shadow: 1px 2px 2px rgb(255, 255, 255, .7);float: left;display: inline-block;border: 1px solid #ccc;" />

                        <h4 class="name">{{ newguy._name }}</h4>
                        <span class="dateins">
                            {{ newguy.dateins.substr(8, 2) + '/' + newguy.dateins.substr(5, 2) + '/' + newguy.dateins.substr(0, 4) }}<br/>
                        </span>
                    </li>
                </ul>
                <br/><br/><br/>  
                <a class="btn_fixed_rect" (click)="goPage('octateam', 'team')">
                    ver todos</a>
            </div> 

            <!-- bars . promos --->
            <div class="content box" style="position: relative; opacity: 1;" 
                *ngIf="lhaylie.player_permission(true, 0) == 1 || model_promo.length > 0">

                <fa-icon [icon]="FAIconStarFull" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;"></fa-icon>
                <span class="srt" style="top: 43px;text-align: right;">DESTAQUE<br/>.</span>
                <div *ngIf="model_promo.length <= 0" class="empty">
                    <p style="text-align: center;color: #333;
                    margin-top: 30px;font-size: 13px;">Nenhum registro encontrado</p>
                </div>  
                <div *ngIf="model_promo.length > 0">
                    <img style="margin-top: 61px;" 
                        src="{{ 'http://haylie.bosswebapps.net/promo/' + model_promo[0].name }}" 
                        width="98%" />

                        <a class="btn_fixed_rect" style="background: #ff0000;" 
                            *ngIf="lhaylie.player_permission(true, 0) == 1"
                            (click)="goHaylie('killpromo', model_promo[0].id, '')">
                            remover</a>
                </div>  
                        
            </div>

            
            <!----insta->
            <div id="instafeed"></div> -->

            <!-- gallery --->
            <div class="content box" style="position: relative; opacity: 1;">
                <fa-icon [icon]="FAIconGallery" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;color:darkmagenta"></fa-icon>
                <span class="srt" style="top: 43px;text-align: right;">GALERIA<br/>
                    {{ model_pics.length  }} fotos</span>
                
                <ul *ngIf="model_pics" class="ulgallery">
                    <li *ngFor="let p of model_pics">
                        <div *ngIf="p.name != 'init'" class="col-1-4"> 
                            <img src="{{ 'http://haylie.bosswebapps.net/gallery/' + p.name }}" width="98%" />
                        </div>
                        <a *ngIf="p._file_alive == 1" (click)="goHaylie('mediastar', p.id, '')" class="contentaction star">
                            <fa-icon [icon]="FAIconStar"></fa-icon>
                        </a>
                        <a *ngIf="p._file_alive == 2" (click)="goHaylie('media', p.id, '')" class="contentaction star">
                            <fa-icon [icon]="FAIconStarFull"></fa-icon>
                        </a>
                        <a (click)="goHaylie('delete', p.id, '')" class="contentaction delete">
                            <fa-icon [icon]="FAIconRemove"></fa-icon>
                        </a> 
                    </li>
                </ul>
                <a class="btn_fixed_rect" (click)="goPage('octagallery', 'gallery')">
                    acessar galeria</a>
            </div>

            <!-- quote --->
            <div id="block-quotes" style="opacity: 1;">
                
                <ul *ngIf="model_quotes" style="padding-bottom: 9px;opacity: 1;" >
                    <li id="phome-liquotebox{{ reg.id }}" *ngFor="let reg of model_quotes" class="content box"  style="opacity: 1;padding-bottom: 9px;">
                        <div id="quotebox{{ reg.id }}" class="alertbox" style="position: relative;">
                            <fa-icon [icon]="FAIconQuote" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;"></fa-icon>
                            <p style="line-height: 27px;
                            position: relative;
                            padding-top: 9p;
                            padding-top: 52px;
                            font-family: monospace;
                            padding: 61px;">
                                {{ reg.quote  }}<br/>
                            </p>
                            <!-- <a class="btn_check fix" 
                                (click)="goHaylie('quotecheck', reg.id.toString())">
                                <fa-icon [icon]="FAIconDelete" class="ico"></fa-icon> 
                            </a> -->
                            <span class="srt" style="top: 43px;font-family: Impact;color: #333;">{{ reg.author }}</span>
                        </div>
                    </li>
                </ul> 
            </div>
                    
            <!-- docker ->
            <div id="block-docker" style="opacity: 1;">
                            
                <ul *ngIf="model_docker" style="padding-bottom: 9px;opacity: 1;" >
                    <li id="phome-liquotebox" *ngFor="let reg of model_docker" class="content box"  style="opacity: 1;padding-bottom: 9px;">
                        <div id="dockerbox" class="alertbox" style="position: relative;">
                            <fa-icon [icon]="FAIconDocker" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;"></fa-icon>
                            <p style="line-height: 27px;
                            position: relative;
                            padding-top: 9p;
                            padding-top: 52px;
                            font-family: monospace;
                            padding: 61px;">
                                {{ reg  }}<br/>
                            </p>
                            
                        </div>
                    </li>
                </ul> 
            </div>-->

            <!--- instafeed --->
            <!-- <div id="instafeed"></div> -->

            <!-- adsense 
            <div id="block-adsense" class="content box" style="position: relative; opacity: 1;">
                <fa-icon [icon]="FAIconGoogle" class="boxicon"
                                style="position: absolute; float: left; margin: 9px;"></fa-icon>
                <span class="srt" style="top: 43px;text-align: right;">PUBLICIDADE</span>
                
                 <br>    
            
            </div>--->

            <!---.--->
            <a id="btn_frm_task" class="fixed_action task"
                *ngIf="lhaylie.player_permission(true, 0) == 1">
                <fa-icon [icon]="FAIconTask" class="ico"></fa-icon>
            </a>
            <a id="btn_frm_suggestion" class="fixed_action suggestion"
                *ngIf="lhaylie.player_permission(true, 0) != 1" 
                (click)="trg_actions('suggestion')">
                <fa-icon [icon]="FAIconLight" class="ico"></fa-icon>
            </a>
            <a id="btn_frm_chat" class="fixed_action chat"
                *ngIf="lhaylie.player_permission(true, 0) != 1" 
                (click)="trg_actions('chat')">
                <fa-icon [icon]="FAIconMsg" class="ico"></fa-icon>
            </a>
            <a id="btn_frm_settings" class="fixed_action settings"
                *ngIf="lhaylie.player_permission(true, 0) != 1" 
                (click)="trg_actions('settings')">
                <fa-icon [icon]="FAIconSettings" class="ico"></fa-icon>
            </a>

            <a id="btn_frm_alert" class="fixed_action alert"
                *ngIf="lhaylie.player_permission(true, 0) == 1" 
                (click)="trg_actions('alert')">
                <fa-icon [icon]="FAIconAlert" class="ico"></fa-icon>
            </a>
            <a id="btn_frm_star" class="fixed_action star"
                *ngIf="lhaylie.player_permission(true, 0) == 1" >
                <fa-icon [icon]="FAIconStarFull" class="ico"></fa-icon>

                <input #imageInputHigh 
                    style="left: 0; padding-bottom: 70px;
                        position: absolute; top: -90px; text-indent: 300px;background: transparent; height: 90px; " 
                    type="file"
                    accept="image/*"
                    (change)="runFileHigh(imageInputHigh)" />
            </a>
            <a id="btn_frm_gallery" class="fixed_action gallery"
                *ngIf="lhaylie.player_permission(true, 0) == 1" 
                (click)="trg_actions('gallery')">
                <fa-icon [icon]="FAIconGallery" class="ico"></fa-icon>

                <!-- <input #imageInputGallery 
                    style="left: 0; padding-bottom: 70px;
                        position: absolute; top: -90px; text-indent: 300px;background: transparent; height: 90px; " 
                    type="file"
                    accept="image/*"
                    (change)="runFileGallery(imageInputGallery)" /> -->
            </a>
            <a id="btn_frm_member" class="fixed_action member"
                *ngIf="lhaylie.player_permission(true, 0) == 1" 
                (click)="trg_actions('member')">
                <fa-icon [icon]="FAIconPadawan" class="ico"></fa-icon>
            </a>
            <a id="btn_frm_nav2" class="fixed_action nav2"
                *ngIf="lhaylie.player_permission(true, 0) == 1" 
                (click)="trg_actions('nav2')">
                <fa-icon [icon]="FAIconNav2" class="ico"></fa-icon>
            </a>
            <a id="btn_frm_less" class="fixed_action less" 
                style="font-size: 18px;"
                (click)="trg_actions('off')">
                <fa-icon [icon]="FAIconMinus" class="ico"></fa-icon>
            </a>
            <!-- <a id="btn_frm" class="fixed_action" 
                style="font-size: 18px;z-index: 61;"
                (click)="trg_actions('on')">
                <fa-icon [icon]="FAIconPlus" class="ico"></fa-icon>
            </a> -->
            <a id="btn_frm" class="fixed_action" 
                style="font-size: 18px;z-index: 61;"
                (click)="trg_actions('on')">
                <fa-icon [icon]="FAIconNav" class="ico"></fa-icon>
            </a>

        </div>

        <div id="playerpage_alert" class="playerpage" style="display: none; opacity: 0;">
            <app-comp-frm [activated]="angel.player_compass(true, '') == 'alert'"
                        [frmoption]="'alert'"></app-comp-frm>
        </div>
        <div id="playerpage_suggestion" class="playerpage" style="display: none; opacity: 0;">
            <div id="block-suggestion" class="content box" 
                *ngIf="lhaylie.player_permission(true, 0) == 2" 
                style="position: relative; opacity: 1;">
                <fa-icon [icon]="FAIconLight" class="boxicon"
                                style="display: block; font-size: 52px; margin: 18px 0; color: #ef0;  
                                text-align: center;"></fa-icon>
             
                <app-comp-frm [activated]="angel.player_compass(true, '') == 'suggestion'"
                          [frmoption]="'suggestion'"></app-comp-frm>
            </div>
        </div>
        <div id="playerpage_register" class="playerpage" style="display: none; opacity: 0;">
            <app-comp-frm [activated]="angel.player_compass(true, '') == 'register'"
                          [frmoption]="'register'"></app-comp-frm>
        </div>
        <div id="playerpage_welcome" class="playerpage" style="display: none; opacity: 0;">
            <app-page-welcome [activated]="angel.player_compass(true, '') == 'welcome'"></app-page-welcome>
        </div>
        <div id="playerpage_dash" class="playerpage" style="display: none; opacity: 0;">
            <app-page-dash [activated]="angel.player_compass(true, '') == 'dash'"></app-page-dash>
        </div>
        <div id="playerpage_leads" class="playerpage" style="display: none; opacity: 0;">
            <app-page-leads [activated]="angel.player_compass(true, '') == 'leads'"></app-page-leads>
        </div>
        <div id="playerpage_agenda" class="playerpage" style="display: none; opacity: 0;">
            <app-page-agenda [activated]="angel.player_compass(true, '') == 'agenda'"></app-page-agenda>
        </div>
        <div id="playerpage_gallery" class="playerpage" style="display: none; opacity: 0;">
            <app-page-gallery></app-page-gallery>
        </div>
        <div id="playerpage_team" class="playerpage" style="display: none; opacity: 0;">
            <app-page-team [activated]="angel.player_compass(true, '') == 'team'" ></app-page-team>
        </div>
        <div id="playerpage_partners" class="playerpage" style="display: none; opacity: 0;">
            <app-page-partners [activated]="angel.player_compass(true, '') == 'partners'" ></app-page-partners>
        </div>
        <div id="playerpage_products" class="playerpage" style="display: none; opacity: 0;">
            <app-page-products [activated]="angel.player_compass(true, '') == 'products'" ></app-page-products>
        </div>
        <div id="playerpage_orders" class="playerpage" style="display: none; opacity: 0;">
            <app-page-orders [activated]="angel.player_compass(true, '') == 'orders'" ></app-page-orders>
        </div>
        <div id="playerpage_classes" class="playerpage" style="display: none; opacity: 0;">
            <app-page-classes [activated]="angel.player_compass(true, '') == 'classes' || angel.player_compass(true, '') == 'classdetail'"></app-page-classes>
        </div>
        <div id="playerpage_profile" class="playerpage" style="display: none; opacity: 0;">
            <app-page-profile [activated]="angel.player_compass(true, '') == 'profile' || angel.player_compass(true, '') == 'profile2'"></app-page-profile>
        </div>
        <div id="playerpage_msgs" class="playerpage" style="display: none; opacity: 0;">
            <app-page-msgs [activated]="angel.player_compass(true, '') == 'msgs'"></app-page-msgs>
        </div>
        <div id="playerpage_plans" class="playerpage" style="display: none; opacity: 0;">
            <app-page-plans [activated]="angel.player_compass(true, '') == 'plans'"></app-page-plans>
        </div>
        <div id="playerpage_nav2" class="playerpage" style="display: none; opacity: 0;">
            <app-master-nav [activated]="angel.player_compass(true, '') == 'nav2'"></app-master-nav>
        </div>
        <div id="playerpage_alerts" class="playerpage" style="display: none; opacity: 0;">
            <app-page-alerts [activated]="angel.player_compass(true, '') == 'alerts'"></app-page-alerts>
        </div>
        <div id="playerpage_suggestions" class="playerpage" style="display: none; opacity: 0;">
            <app-page-alerts [activated]="angel.player_compass(true, '') == 'suggestions'"></app-page-alerts>
        </div>
        <div id="playerpage_settings" class="playerpage" style="display: none; opacity: 0;">
            <app-page-settings [activated]="angel.player_compass(true, '') == 'settings'"></app-page-settings>
        </div>
    </div>



</div>
<!-- <div *ngIf="player_live == false" >
    <app-page-login></app-page-login>
</div> -->