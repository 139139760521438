import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router'

import { faCheck, faTimes, faBell, faPlus, faLightbulb, faUser } from '@fortawesome/free-solid-svg-icons'
import { AngelService } from '../services/angel.service';

import { eAlert } from '../services/entities'
import { GsapService } from '../services/gsap.service'
import { HaylieService } from '../services/haylie.service'
import { MorganaService } from '../services/morgana.service';

@Component({
  selector: 'app-page-alerts',
  templateUrl: './page-alerts.component.html',
  styleUrls: ['./page-alerts.component.css']
})
export class PageAlertsComponent implements OnInit {
  player_live = false;
  player_permission = 0 ;
  model_title: string = 'ALERTAS';
  model_alerts: eAlert[] = [];
  model_type : string = 'alerts'
  page_frm: boolean = false;
  lhaylie: any;
  lmorgana: any;

  FAIconSuggestion = faLightbulb;
  FAIconAlert = faBell;
  FAIconCheck = faCheck;
  FAIconDelete = faTimes;
  FAIconNew = faPlus;
  FAIconUser = faUser;
  
  @Input() activated: boolean = false;

  constructor(private gsap: GsapService, 
    private haylie: HaylieService,
    private morgana: MorganaService,
    public angel: AngelService,  
    private router: Router) 
    { 
      this.lhaylie = this.haylie;
      this.lmorgana = this.morgana;
    }

  ngOnInit(): void {
    
  }
  ngOnChanges() { 
    if (this.activated) this.content_refresh();
  }

  content_refresh() {
    this.gsap.anima_trg('cronos', 'showup');
    //this.switchFrm("init");

    this.model_type = this.angel.player_compass(true, '');
    this.model_title = this.model_type == 'alerts' ? "ALERTAS" : "SUGESTÕES";
    
    if (this.haylie.player_token(true, '').length > 0) {
      console.log('getalerts> ' + this.haylie.player_token(true, ''));
      this.get_alerts();
      
      this.player_live = this.haylie.player_token(true, '').length > 0;
    }
    else {
      this.player_live = false; 

      this.gsap.magic_trg("#outsider_wrapper", "build");
      this.router.navigateByUrl('octalogin');
    }
  }
  get_alerts(): void {
    this.model_alerts = [];

    this.haylie.get_alerts("9").then(this.catch_alerts);
  }
  catch_alerts = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_alerts = l_response.alerts;

    if (this.angel.player_compass(true, '') != 'alerts')
      this.model_alerts = this.model_alerts.filter(x => x.id_type == 2);
    else
      this.model_alerts = this.model_alerts.filter(x => x.id_type < 2);

    this.gsap.anima_trg('cronos', '');
    return this.model_alerts;
  }

  
  goHaylie(p_action: string, p_target: string): void{
    console.log(p_action + ">" + p_target);
  
    switch(p_action) {
      case "frm":
        break;
    }
  }

}
