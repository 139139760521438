import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCheck, faCogs, faEdit, faMinus, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { GsapService } from '../services/gsap.service';
import { HaylieService } from '../services/haylie.service';

@Component({
  selector: 'app-page-settings',
  templateUrl: './page-settings.component.html',
  styleUrls: ['./page-settings.component.css']
})
export class PageSettingsComponent implements OnInit {
  player_live = false;
  player_permission = 0;
  //model_plans: ePlan[] = [];
  model_frm_id = 0;
  model_frm_name = '';
  model_frm_skill = '';
  model_frm_type = '';
  model_frm_price = '';

  FAIconCheck = faCheck;
  FAIconDelete = faTimes;
  FAIconPlus = faPlus;
  FAIconMinus = faMinus;
  frmFAIcon = faEdit;
  pageFAIcon = faCogs;

  page_frm = false;
  
  lhaylie: any;
  @Input() activated: boolean = false;

  constructor(private gsap: GsapService, 
              private haylie: HaylieService, 
              private router: Router) {
    this.lhaylie = this.haylie;
   }

  ngOnInit(): void {
   
  }

}
