import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  public getCookie(p_name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = p_name;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length + 1, c.length);
      }
    }
    return '';
  }

  public setCookie(p_name: string, p_value: string, p_expireDays: number, p_path: string = "") {
    let d: Date = new Date();
    d.setTime(d.getTime() + p_expireDays * 24 * 60 * 60 * 1000);
    let expires: string = "expires=" + d.toUTCString();
    document.cookie = p_name + "=" + p_value + "; " + expires + (p_path.length > 0 ? "; path=" + p_path : "");
  }

  public deleteCookie(p_name:string) {
    this.setCookie(p_name, "", -1);
  }



}