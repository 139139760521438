<!-- <app-page-login></app-page-login> -->

<app-page-login *ngIf="gotoreanimation == 'login'"></app-page-login> 

<!-- <app-page-reanimation *ngIf="gotoreanimation == 'reanimation'"></app-page-reanimation> -->

<div id="cronosLoading" class="orbe" style="display: none;">
    <div class="blue-orbit leo"></div>
    <div class="green-orbit leo"></div>
    <div class="red-orbit leo"></div>

    <div class="white-orbit w1 leo"></div>
    <div class="white-orbit w2 leo"></div>
    <div class="white-orbit w3 leo"></div>
</div>

<router-outlet></router-outlet>