import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCrown, faGem, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { AngelService } from '../services/angel.service';

import { CookieService } from '../services/cookie.service'
import { GsapService } from '../services/gsap.service'
import { HaylieService } from '../services/haylie.service'
 


@Component({
  selector: 'app-master-sidebar',
  templateUrl: './master-sidebar.component.html',
  styleUrls: ['./master-sidebar.component.css']
})
export class MasterSidebarComponent implements OnInit {
  FAIcon = faPowerOff;
  FAIconMaster = faCrown;
  FAIconMember = faGem;
  
  player_avatar = '';
  player_permission = 0;

  constructor(private router: Router, private angel: AngelService, 
    private cookie: CookieService, private gsap: GsapService, private haylie: HaylieService) { }

  ngOnChanges() { 
    this.player_avatar = this.haylie.player_avatar(true, '');
   }
  ngOnInit(): void {

    if (this.haylie.player_avatar(true, '').length <= 0) { 
      this.haylie.player_avatar(false, this.cookie.getCookie("player_avatar"));
      
    }
    this.player_avatar = this.haylie.player_avatar(true, '');

    if (this.haylie.player_permission(true, 0) == 0) 
      this.haylie.player_permission(false, parseInt(this.cookie.getCookie("player_permission")));

    this.player_permission = this.haylie.player_permission(true, 0);
  }
  sidebar_trg(p_action:string):void {
    this.player_avatar = this.haylie.player_avatar(true, '');
    this.gsap.anima_trg("sidebar", p_action);
  }
  goPage(p_route:string, p_page: string):void {
    this.gsap.anima_trg("sidebar", "close");
    this.angel.player_compass(false, "");

    if (p_page.length > 0) {
      if (p_page == 'profile') 
      {
        this.haylie.player_compass(false, 'refresh');
        
        this.angel.profile_token(false, this.cookie.getCookie("player_token"));
        //this.haylie.get_profile(this.angel.profile_token(true, '')).then(this.catch_profile);

        setTimeout(() => this.angel.player_compass(false, 'profile'), 300);
        
        //this.angel.player_compass(false, 'team');
           
//        this.haylie.player_compass(false, 'profile');
        //this.gsap.player_pages('profile');
      }
      else 
        this.angel.player_compass(false, p_page);
      
      //let l_page = p_page == "suggestions" ? "alerts" : p_page; 

      this.gsap.player_pages(p_page);
    }
    else 
      setTimeout(() => { this.router.navigateByUrl(p_route) }, 300);
    
  }
  goOut():void {
    this.haylie.logout();

    this.goPage('octalogin', '');

  }
  // player_reload() {
  //   if (this.player_token != null)
  //     this.haylie.outsider_logmein(this.player_token, "xxxxxplayertokenxxxxx").then(this.catch_login);

  // }

}
