<div id="masterheader" class="header" style="text-align: center">
    <a (click)="header_trg('home')">
    	<img class="iconL" src="../assets/img/svg/octa-silver.svg" style="opacity: 1" />
    </a>

	<img id="logo_inline" class="logo" src="../assets/img/logo/nf_on.png" style="opacity: 1" />
	
    <a (click)="header_trg('sidebar')" id="trg_nav" class="iconR">
        <fa-icon [icon]="FAIcon"></fa-icon>
    </a>
</div>	