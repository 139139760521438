<div id="outsider_wrapper" class="outsider">
    <img class="iconL" src="../assets/img/svg/octa-silver.svg" />
    
    <!-- <a href="#"></a> -->
    <img class="eRegister" src="../assets/img/logo/nf_on.png" 
        style="opacity: 0; width: 81px; display: block;left: 50%;
        position: absolute;margin-left: -40.5px; margin-top: 3.4px;" />

    <fa-icon [icon]="FAIconLab" class="iconR"></fa-icon>

    <img id="intrologo1" *ngIf="maindomain == 'newfight'" 
        src="../assets/img/logo/almeidajj.png" class="teaser" />
    <img id="intrologo2" *ngIf="maindomain == 'newfight'" 
        src="../assets/img/logo/revirablackteam.png" class="teaser" />
    <img id="intrologo3" *ngIf="maindomain == 'newfight'" 
        src="../assets/img/logo/newfight.png" class="teaser" />
    <img id="intrologo4" *ngIf="maindomain == 'newfight'" 
        src="../assets/img/logo/newfight_white.png" class="teaser" />

    <div id="intrologo5" *ngIf="maindomain == 'bwa'" class="teaser">
        <a href="http://bosswebapps.net" id="bwaicon" target="_blank">
            <img src="../assets/img/logo/shortcut-boss.png" style="width: 70px; position: relative; top: 3px; border-radius: 9px;"></a>
        <br>
        <a href="http://bosswebapps.net" id="bwalogo" target="_blank">
            <img src="../assets/img/logo/logo-boss.png" style="width: 160px; position: relative; top: 9px; border-radius: 0px;"></a>
        <br> 
        
        <p class="slogan">BUILD THE FUTURE</p>
    </div>
    <img id="intrologo6" *ngIf="maindomain == 'bwa'"
        src="../assets/img/logo/newfight.png" class="teaser" />
    <img id="intrologo7" *ngIf="maindomain == 'bwa'"
        src="../assets/img/octa/octa-line.png" class="teaser" />
    <img id="intrologo8" *ngIf="maindomain == 'bwa'"
        src="../assets/img/octa/octa-white.png" class="teaser" />

      

    <div class="frm">
        
        <!---.recover  -->
        <div class="eRecover" id="ghost_wrapper" style="opacity: 0;width: 210px;top: 9px; left: 50%;position: relative;margin-left: -105px;">
            <label class="has-float-label">
                <input #ghost type="email" (keyup.enter)="goHaylie('bringmeback')"
                    placeholder="email@exemplo.com" class="eRecover" aria-multiline="false" autocomplete="off" value="" />
                <span style="font-family: mysanslight;">EMAIL</span>
            </label>
        </div>
        <p class="help eRecover" style="display: none;">
            Não se preocupe, iremos lhe enviar um email com instruções para <br/>
            recuperar sua senha
            <br/><br/><br/>
            <fa-icon [icon]="FAIconRecover" 
            style="font-size: 52px; color: rgba(255,255,255,.7);"></fa-icon>
            <br/><br/><br/>
        </p>
        <p id="eRecoverResult" class="help eRecoverResult" style="display: none;
            color: rgb(204, 204, 204);width: 70%;text-align: center;margin: 9px 15%;float: left;top: -30px;position: relative;margin-bottom: 36px;font-size: 16px;">
            
            <fa-icon [icon]="FAIconCheck" style="font-size: 34px; color: rgba(255,255,255,.7);"></fa-icon>
            <br/><br/><br/>       
            {{ response_msg }}<br/>
        </p>
        <p id="eDenied" class="help eDenied" style="display: none;
            color: rgb(204, 204, 204);width: 70%;text-align: center;margin: 9px 15%;float: left;top: -30px;position: relative;margin-bottom: 36px;font-size: 16px;">
            
            <fa-icon [icon]="FAIconDenied" style="font-size: 34px; color: rgba(255,255,255,.7);"></fa-icon>
            <br/><br/><br/>       
            {{ response_msg }}<br/>

        </p>

        <!---.register  -->
        <div class="frmtest eRegister" style="display:none; min-height: 340px;">
            <h4 style="">cadastro</h4>
            
            <ul class="steps" style="float: left; width: 70%; margin: 3px 15%;">
                <li><span id="sc1" class="stepcount {{ padawanstep >= 1 ? 'on' : '' }}"></span></li>
                <li><span id="sc2" class="stepcount {{ padawanstep >= 2 ? 'on' : '' }}"></span></li>
                <li><span id="sc3" class="stepcount {{ padawanstep >= 3 ? 'on' : '' }}"></span></li>
            </ul>
            <div class="regsteps" id="step1">
                <fa-icon [icon]="FAIconPremium" class="boxicon"
                    style="display: block; font-size: 61px; 
                    margin: 34px 0; color:#ff9900; text-align: center;"></fa-icon>

                <div style="margin-top: 3px;float: left; width: 100%;position: relative;">
                    <div class="eRegister" style="display: none;width: 210px;top: 9px; left: 50%;position: relative;margin-left: -105px;">
                        <label class="has-float-label">
                            <input #padawan type="nome" (keyup.enter)="goHaylie('register')" 
                                placeholder="seu nome" class="eRegister" aria-multiline="false" autocomplete="off" value="" />
                            <span style="font-family: mysanslight;">NOME</span>
                        </label>
                    </div>
                    <div class="eRegister" style="display: none;width: 210px;top: 90px; left: 50%;position: relative;margin-left: -105px;margin-bottom: 90px;">
                        <label class="has-float-label">
                            <input #padawanphone type="number" (keyup.enter)="goHaylie('register')" 
                                placeholder="11912345678" class="eRegister" aria-multiline="false" autocomplete="off" value="" />
                            <span style="font-family: mysanslight;">TELEFONE</span>
                        </label>
                    </div>
                </div>

                <p class="help eRegister" style="display: none;">
                    Primeiro precisamos do seu  <br/>
                    <b>nome & telefone</b><br/>
                    
                </p>
            </div>  
            <div class="regsteps" id="step2" style="display:none">
                <fa-icon [icon]="FAIconRegStep2" class="boxicon"
                    style="display: block; font-size: 61px; 
                    margin: 34px 0; color:#ff9900; text-align: center;"></fa-icon>

                <div style="margin-top: 3px;float: left; width: 100%;position: relative;">
                    
                    <p class="help eRegister" style="display: none;">
                        Agora precisamos de uma foto sua<br/>
                        para ser usada na rede <br/>
                        
                    </p>
                    
                    <app-comp-imgup class="eRegister" 
                        style="display: none;float: left;width: 100%;">
                    </app-comp-imgup>

                </div>
            </div>  
            <div class="regsteps" id="step3" style="display:none">
                <fa-icon [icon]="FAIconRegStep3" class="boxicon"
                    style="display: block; font-size: 61px; 
                    margin: 34px 0; color:#ff9900; text-align: center;"></fa-icon>

                <div class="eRegister" style="display: none;width: 210px;top: 9px; left: 50%;position: relative;margin-left: -105px;margin-bottom: 90px;">
                    <label class="has-float-label">
                        <input #padawanmail type="email" (keyup.enter)="goHaylie('register')" 
                            placeholder="seuemail@exemplo.com" class="eRegister" aria-multiline="false" autocomplete="off" value="" />
                        <span style="font-family: mysanslight;">EMAIL</span>
                    </label>
                </div>
                <p class="help eRegister" style="display: none;">
                    Agora é só confirmar seu e-mail<br/>
                    Enviaremos um link para liberação do seu acesso 
                </p>

            </div>  
        </div>  
        <p id="eRegisterResult" class="help eRegisterResult" style="display: none;
            color: rgb(204, 204, 204);width: 70%;text-align: center;margin: 9px 15%;float: left;top: -30px;position: relative;margin-bottom: 36px;font-size: 16px;">
            
            <fa-icon [icon]="FAIconCheck" style="font-size: 34px; color: rgba(255,255,255,.7);"></fa-icon>
            <br/><br/><br/>       
            Solicitação enviada com sucesso<br/><br/>
            Notificaremos a aprovação e liberação do seu acesso por email<br/><br/>

            <img style="width: 99px" src="../assets/img/categs/punch.png"   />

        </p>
        
        <!---.login  -->
        <div class="eLogin" id="mail_wrapper" style="opacity: 0;width: 210px;top: 9px; left: 50%;position: relative;margin-left: -105px;">
            <label class="has-float-label">
                <input #lmail type="email" (keyup.enter)="goHaylie('logmein')" 
                    placeholder="" class="eLogin" 
                    aria-multiline="false" 
                    autocomplete="off"  />
                <span style="font-family: mysanslight;">EMAIL</span>
            </label>
        </div>
        <div class="eLogin" id="key_wrapper" style="opacity: 0; width: 210px;left: 50%;position: relative;margin-left: -105px;top: 90px;">
            <label class="has-float-label">
                <input #lkey name="lkey" class="eLogin usrinputkey" type="password" 
                    (keyup)="mirrorKey($event)"
                    (keyup.enter)="goHaylie('logmein')" 
                    placeholder="" title="" style="opacity: 1" aria-multiline="false" autocomplete="off" />
                <input #lkey2 name="lkey2" class="eLogin usrinputkey" type="text"  
                    placeholder="" title=""
                    (keyup)="mirrorKey($event)"
                    (keyup.enter)="goHaylie('logmein')" 
                    style="display: none;" aria-multiline="false" autocomplete="off" />
     
                <span style="font-family: mysanslight;">SENHA</span>
            </label>
            <p class="help eLogin" style="color: #ccc;opacity: 0;font-size: 10px;margin: 0;position: absolute;top: 52px;float: right;text-align: right;width: 100%;">
                Esqueceu a senha ? 
                <a (click)="switchScreens('Recover')" style="color: #2d4f8e; font-weight: 600;"> Clique aqui</a></p>

            <a id="trg_locker" (click)="switchKeys()">
                <fa-icon class="lockico" id="locker" [icon]="FAIconLocker" style="font-size: 25px; opacity: 1;"></fa-icon>
                <fa-icon class="lockico" id="locker2" [icon]="FAIconLocker2" style="font-size: 25px; display: none;"></fa-icon>
            </a>
        </div>
<!--         
        <input #outsider_reanimation class="inputtxt eReanimation" type="text"  name="nick" value="" placeholder="Nome" />
        <input #outsider_reanimation2 class="inputtxt eReanimation" type="text"  name="nick" value="" placeholder="Nome" /> -->

        <a id="btn_go" class="btnrecblue eLogin" (click)="goHaylie('logmein')"
            style="width: 70%; margin: 9px 15%; border-radius: 4.3px;">ENTRAR</a>
        <a id="btn_recover" class="btnrecblue eRecover" (click)="goHaylie('bringmeback')" 
            style="display: none;position: relative;    top: 61px;
            width: 70%;margin: 9px 15%; border-radius: 4.3px;">ENVIAR</a>
        <a id="btn_register" class="btnrecblue eRegister" 
            (click)="switchSteps(true)" 
            style="display: none;position: relative;top: 61px;
            width: 70%; margin: 9px 15%;border-radius: 4.3px;">{{ btnregisterlabel }}</a>
        <a class="btnrectransp eRecover" (click)="switchScreens('Login')" 
            style="display: none;position: relative; top: 72px; font-size: 12px;">VOLTAR</a>
        <a class="btnrectransp eRegister" (click)="switchSteps(false)" 
            style="display: none;position: relative; top: 72px; font-size: 12px;">VOLTAR</a>


        <p class="help eLogin" 
            style="color: rgb(204, 204, 204);opacity: 0;font-size: 10px;margin: 0px;position: relative;top: 225px;float: left;text-align: center;width: 100%;"> 
            Ainda não tem conta ? <a (click)="switchScreens('Register')" style="color: #2d4f8e; font-weight: 600;"> Cadastre-se</a></p>
    </div>

    <div class="eRecover eLogin" 
        style="position: relative; float: left; width: 100%; top: 54px;z-index: -1">
        <h1 class="title">OCTA</h1>
        <p class="sub">WARRIORS<br/>ONLY</p>
    </div>

    <div class="terms eLogin">
        <a>
            <p>
                <a>Termos de uso e política de privacidade</a>
                <br>
                Ao se {{ termsverb }} na plataforma você concorda com os <br/>
                Termos de Uso e Política de Privacidade aqui referidos
            </p>
        </a>
    </div>
    <!-- <div class="terms eRecover eRegister" style="display:none">
        <a>
            <p>
                <a>Termos de uso e política de privacidade</a>
                <br>
                Ao se {{ termsverb }} na plataforma você concorda com os <br/>
                Termos de Uso e Política de Privacidade aqui referidos
            </p>
        </a>
    </div> -->



</div>