import { Injectable } from '@angular/core';
import { gsap, TimelineMax, TweenLite, Power4, Elastic, Linear } from 'gsap';

import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

@Injectable({
  providedIn: 'root'
})
export class GsapService {

  constructor() { }

   eOutsiderBox = '#outsider_wrapper';
   eOutsiderIntroLogo1 = '#outsider_wrapper #intrologo1';
   eOutsiderIntroLogo2 = '#outsider_wrapper #intrologo2';
   eOutsiderIntroLogo3 = '#outsider_wrapper #intrologo3';
   eOutsiderIntroLogo4 = '#outsider_wrapper #intrologo4';

   eReanimationBox = '#page_reanimation';

  public init(p_domain: string){
    if (p_domain == 'bwa') {
      this.eOutsiderIntroLogo1 = '#outsider_wrapper #intrologo5';
      this.eOutsiderIntroLogo2 = '#outsider_wrapper #intrologo6';
      this.eOutsiderIntroLogo3 = '#outsider_wrapper #intrologo7';
      this.eOutsiderIntroLogo4 = '#outsider_wrapper #intrologo8';
    }

    TweenLite.to(this.eOutsiderIntroLogo4, .1, { y: 9, ease: Linear.easeNone });

    var tlInit = new TimelineMax();

      tlInit.add(TweenLite.to(this.eOutsiderIntroLogo1, .3, { opacity: 1, ease: Linear.easeIn }), 0.3);
      tlInit.add(TweenLite.to(this.eOutsiderIntroLogo1, .9, { opacity: 0, ease: Linear.easeIn }), 1.6);
      
      tlInit.add(TweenLite.to(this.eOutsiderIntroLogo2, .3, { opacity: 1, ease: Linear.easeIn }), 2.5);
      tlInit.add(TweenLite.to(this.eOutsiderIntroLogo2, .9, { opacity: 0, ease: Linear.easeIn }), 3.6);
      
      tlInit.add(TweenLite.to(this.eOutsiderIntroLogo3, .3, { opacity: 1, ease: Linear.easeIn }), 4.5);
      //tlInit.add(TweenLite.to(this.eOutsiderIntroLogo3, .9, { opacity: 0, ease: Linear.easeIn }), 3.4);
      
      tlInit.add(TweenLite.to(this.eOutsiderBox, .7, { background: "#111", ease: Linear.easeIn }), 4.8);
      tlInit.add(TweenLite.to(this.eOutsiderIntroLogo4, .4, { opacity: 1, y: 0, ease: Linear.easeIn }), 5);
      
      //tlInit.add(TweenLite.to(this.eOutsiderIntroLogo4, 1.6, { opacity: 1, y: 0, width: "160px", marginLeft: "-80px", ease: Linear.easeIn }), 3.6);
      if (p_domain == 'bwa')
        tlInit.add(TweenLite.to(this.eOutsiderIntroLogo1, .2, { display: "none", ease: Linear.easeNone }), 5.4);
    
      tlInit.add(TweenLite.to(this.eOutsiderBox, .9, { background: "#000", ease: Linear.easeNone }), 5.4);
      tlInit.add(TweenLite.to(this.eOutsiderIntroLogo3, .9, { opacity: 0, ease: Linear.easeNone }), 5.4);
    
      tlInit.add(TweenLite.to("#outsider_wrapper .eLogin", .3, { opacity: 1, y: 0, ease: Linear.easeNone }), 5.7);
  
  }
  public outsider_pages(p_page:string) {
    var tlPages = new TimelineMax();

    tlPages.add(TweenLite.to("#outsider_wrapper .eRecoverResult", .2, { opacity: 0, display: "none", ease: Linear.easeNone }), 0);
    tlPages.add(TweenLite.to("#outsider_wrapper .eRegisterResult", .2, { opacity: 0, display: "none", ease: Linear.easeNone }), 0);
    tlPages.add(TweenLite.to("#outsider_wrapper .eDenied", .2, { opacity: 0, display: "none", ease: Linear.easeNone }), 0);

    tlPages.add(TweenLite.to("#outsider_wrapper .eReanimation", .2, { opacity: 0, display: "none", ease: Linear.easeNone }), 0);
    tlPages.add(TweenLite.to("#outsider_wrapper .eRegister", .2, { opacity: 0, display: "none", ease: Linear.easeNone }), 0);
    tlPages.add(TweenLite.to("#outsider_wrapper .eLogin", .2, { opacity: 0, display: "none", ease: Linear.easeNone }), 0);
    tlPages.add(TweenLite.to("#outsider_wrapper .eRecover", .2, { opacity: 0, display: "none", ease: Linear.easeNone }), 0);

    tlPages.add(TweenLite.to("#outsider_wrapper .e" + p_page + ":not(.usrinputkey)", .7, { opacity: 1, display: "block", ease: Linear.easeNone }), .3);

    switch(p_page) {
      case "Login": 
        tlPages.add(TweenLite.to("#outsider_wrapper input[name='lkey']", .34, { opacity: 1, display: "block", ease: Linear.easeNone }), .9);
        break;

      case "Register": 
        //tlPages.add(TweenLite.to("#outsider_wrapper input[name='lkey']", .34, { opacity: 1, display: "block", ease: Linear.easeNone }), .9);
        //duhflag---
        break;
    }

  }
  public player_pages(p_page:string) {
    var tlPages = new TimelineMax();

    tlPages.add(TweenLite.to(".playerpage", .2, { opacity: 0, display: "none", ease: Linear.easeNone }), 0);
    tlPages.add(TweenLite.to("#playerpage_" + p_page, .7, { opacity: 1, display: "block", ease: Linear.easeNone }), .3);
  
  }
  public anima_trg(p_page:string, p_action:string) {
    var tlBase = new TimelineMax();

    switch(p_page) {
      case "outsider":
        if (p_action.indexOf("regstep") > 0) {
          tlBase.add(TweenLite.to(".regsteps", .2, { opacity: 0, display: "none", ease: Linear.easeNone }), 0);
          tlBase.add(TweenLite.to(".regsteps#step" + p_action.replace("outregstep", ""), .7, { opacity: 1, display: "block", ease: Linear.easeNone }), .5);
        
        }

        if (p_action == "denied" || p_action == "denied2") {
          console.log(p_page + '> access denied');
          tlBase.add(TweenLite.to(this.eOutsiderBox, .34, { background: "#ff0000", ease: Linear.easeNone }), 0);
          tlBase.add(TweenLite.to("#outsider_wrapper .frm", .34, { background: "#ff0000", ease: Linear.easeNone }), 0);
          tlBase.add(TweenLite.to(this.eOutsiderBox, .34, { background: '#000', ease: Linear.easeNone }), 1.2);
          tlBase.add(TweenLite.to("#outsider_wrapper .frm", .34, { background: '#000', ease: Linear.easeNone }), 1.2);
          if (p_action == "denied2") this.outsider_pages('Denied');
        }
        if (p_action == "granted" || p_action == "granted2") { 
          console.log(p_page + '> access granted');
          tlBase.add(TweenLite.to(this.eOutsiderBox, .34, { background: "rgba(51,170,0, .61)", ease: Linear.easeNone }), 0);
          tlBase.add(TweenLite.to("#outsider_wrapper .frm", .34, { background: "rgba(51,170,0, .61)", ease: Linear.easeNone }), 0);
          tlBase.add(TweenLite.to(this.eOutsiderBox, .34, { background: '#000', ease: Linear.easeNone }), 1.2);
          tlBase.add(TweenLite.to("#outsider_wrapper .frm", .34, { background: '#000', ease: Linear.easeNone }), 1.2);
          this.outsider_pages(p_action == "granted" ? 'RegisterResult' : 'RecoverResult');
        }
        if (p_action == "frmup") 
          TweenLite.to("#outsider_wrapper .frm", .34, { top: '52px', background: '#000', ease: Linear.easeNone });
        
        if (p_action == "frmdown") 
          TweenLite.to("#outsider_wrapper .frm", .34, { top: '210px', background: '#000', ease: Linear.easeNone });

        if (p_action == "frmunlock") {
          tlBase.add(TweenLite.to("#outsider_wrapper .usrinputkey", .2, { opacity: 0, display: "none", ease: Linear.easeNone }), 0);
          tlBase.add(TweenLite.to("#outsider_wrapper .lockico", .2, { opacity: 0, display: "none", ease: Linear.easeNone }), 0);
          tlBase.add(TweenLite.to("#outsider_wrapper #locker2", .34, { opacity: 1, display: "inline-block", ease: Linear.easeNone }), .3);  
          tlBase.add(TweenLite.to("#outsider_wrapper input[name='lkey2']", .34, { opacity: 1, display: "block", ease: Linear.easeNone }), .3);
        }
        if (p_action == "frmlock") {
          tlBase.add(TweenLite.to("#outsider_wrapper .usrinputkey", .2, { opacity: 0, display: "none", ease: Linear.easeNone }), 0);
          tlBase.add(TweenLite.to("#outsider_wrapper .lockico", .2, { opacity: 0, display: "none", ease: Linear.easeNone }), 0);
          tlBase.add(TweenLite.to("#outsider_wrapper #locker", .34, { opacity: 1, display: "inline-block", ease: Linear.easeNone }), .3);  
          tlBase.add(TweenLite.to("#outsider_wrapper input[name='lkey']", .34, { opacity: 1, display: "block", ease: Linear.easeNone }), .3);
        }
        
        break;

      case "cronos":
        if (p_action.length > 0)
          TweenLite.to("#cronosLoading", .3, { opacity: 1, display: "flex", ease: Linear.easeNone });
        else 
          TweenLite.to("#cronosLoading", .3, { opacity: 0, display: "none", ease: Linear.easeNone });
    
        break;

      case "sidebar":
        if (p_action == "open") {
          TweenLite.to("#mastersidebar", .2, { right: "0", ease: Linear.easeNone });
          //TweenLite.to("#mastersidebar_b", .2, { right: "9%", ease: Linear.easeNone });
          TweenLite.to("#fakelayer_sidebar", .2, { display: "block", ease: Linear.easeNone });  
        }
        else {
          //TweenLite.to("#mastersidebar_b", .2, { right: "-101%", ease: Linear.easeNone });
          TweenLite.to("#mastersidebar", .2, { right: "-101%", ease: Linear.easeNone });
          TweenLite.to("#fakelayer_sidebar", .2, { display: "none", ease: Linear.easeNone }); 
        }
        break;

      case "home":
        if (p_action == "more" || p_action == "more-nav") {
          //if (p_action == "more") tlBase.add(TweenLite.to('#playerpage_home #btn_frm', .7, { rotate: '-90deg', opacity: 0, ease: Linear.easeOut }), 0);
          tlBase.add(TweenLite.to('#playerpage_home #btn_frm', .7, { rotate: '-90deg', opacity: .3, ease: Linear.easeOut }), 0);
          
          //tlBase.add(TweenLite.to('#playerpage_home #btn_frm_less', .2, { right: '25px', opacity: 1, ease: Linear.easeOut }), 0);
          tlBase.add(TweenLite.to('#playerpage_home #btn_frm_nav2', .2, { right: '25px', opacity: 1, ease: Linear.easeOut }), .1);
          tlBase.add(TweenLite.to('#playerpage_home #btn_frm_member', .2, { right: '25px', opacity: 1, ease: Linear.easeOut }), .1);
          tlBase.add(TweenLite.to('#playerpage_home #btn_frm_gallery', .2, { right: '25px', opacity: 1, ease: Linear.easeOut }), .2);
          tlBase.add(TweenLite.to('#playerpage_home #btn_frm_star', .2, { right: '25px', opacity: 1, ease: Linear.easeOut }), .3);
          tlBase.add(TweenLite.to('#playerpage_home #btn_frm_alert', .2, { right: '25px', opacity: 1, ease: Linear.easeOut }), .4);
          //tlBase.add(TweenLite.to('#playerpage_home #btn_frm_suggestion', .2, { right: '25px', opacity: 1, ease: Linear.easeOut }), .5);
        
          tlBase.add(TweenLite.to('#playerpage_home #btn_frm_settings', .2, { right: '25px', opacity: 1, ease: Linear.easeOut }), .1);
          tlBase.add(TweenLite.to('#playerpage_home #btn_frm_chat', .2, { right: '25px', opacity: 1, ease: Linear.easeOut }), .2);
          tlBase.add(TweenLite.to('#playerpage_home #btn_frm_suggestion', .2, { right: '25px', opacity: 1, ease: Linear.easeOut }), .3);
          
        }
        else {
          tlBase.add(TweenLite.to('#playerpage_home #btn_frm', .7, { rotate: '90deg', opacity: 1, ease: Linear.easeOut }), 0);
          
          //tlBase.add(TweenLite.to("#playerpage_home #btn_frm_less", .2, { right: '-90px', opacity: 0, ease: Linear.easeOut }), 0);
          tlBase.add(TweenLite.to('#playerpage_home #btn_frm_nav2', .2, { right: '-90px', opacity: 0, ease: Linear.easeOut }), 0);
          tlBase.add(TweenLite.to("#playerpage_home #btn_frm_member", .2, { right: '-90px', opacity: 0, ease: Linear.easeOut }), .1);
          tlBase.add(TweenLite.to("#playerpage_home #btn_frm_gallery", .2, { right: '-90px', opacity: 0, ease: Linear.easeOut }), .2);
          tlBase.add(TweenLite.to("#playerpage_home #btn_frm_star", .2, { right: '-90px', opacity: 0, ease: Linear.easeOut }), .3);
          tlBase.add(TweenLite.to("#playerpage_home #btn_frm_alert", .2, { right: '-90px', opacity: 0, ease: Linear.easeOut }), .4);
          //tlBase.add(TweenLite.to("#playerpage_home #btn_frm_suggestion", .2, { right: '-90px', opacity: 0, ease: Linear.easeOut }), .5);
        
          tlBase.add(TweenLite.to("#playerpage_home #btn_frm_settings", .2, { right: '-90px', opacity: 0, ease: Linear.easeOut }), .1);
          tlBase.add(TweenLite.to("#playerpage_home #btn_frm_chat", .2, { right: '-90px', opacity: 0, ease: Linear.easeOut }), .2);
          tlBase.add(TweenLite.to("#playerpage_home #btn_frm_suggestion", .2, { right: '-90px', opacity: 0, ease: Linear.easeOut }), .3);
          
        }
        /*
        this.gsap.magic_trg('#playerpage_home #btn_frm', 'vanish');
        this.gsap.magic_trg('#playerpage_home #btn_frm_less', 'easybuild');
        this.gsap.magic_trg('#playerpage_home #btn_frm_member', 'easybuild');
        this.gsap.magic_trg('#playerpage_home #btn_frm_agenda', 'easybuild');
        this.gsap.magic_trg('#playerpage_home #btn_frm_angel', 'easybuild');
        */
        break;

      case "hometoday":
        if (p_action == "open") {
          tlBase.add(TweenLite.to("#playerpage_home #boxtoday", .2, { height: 'auto', ease: Linear.easeOut }), 0);
          this.magic_trg('#playerpage_home #boxtoday #btn_today_more', 'vanish');
          this.magic_trg('#playerpage_home #boxtoday #btn_today_less', 'easybuild');
        }
        else {
          tlBase.add(TweenLite.to("#playerpage_home #boxtoday", .2, { height: '61px', ease: Linear.easeOut }), 0);
          this.magic_trg('#playerpage_home #boxtoday #btn_today_less', 'vanish');
          this.magic_trg('#playerpage_home #boxtoday #btn_today_more', 'easybuild');
        }
        break;

      case "frm":
        if (p_action == "newguy") {
          console.log(p_page + '> input error');
          tlBase.add(TweenLite.to("#playerpage_register #frmmember", .34, { background: "#ff0000", ease: Linear.easeNone }), 0);
          tlBase.add(TweenLite.to("#playerpage_register #frmmember", .34, { background: '#fff', ease: Linear.easeNone }), 1.2);
          //if (p_action == "denied2") this.outsider_pages('Denied');
        }
        if (p_action == "newalert") {
          console.log(p_page + '> input error');
          tlBase.add(TweenLite.to("#playerpage_alert #frmnewalert.content.box", .34, { background: "#ff0000", ease: Linear.easeNone }), 0);
          tlBase.add(TweenLite.to("#playerpage_alert #frmnewalert.content.box", .34, { background: '#fff', ease: Linear.easeNone }), 1.2);
          //if (p_action == "denied2") this.outsider_pages('Denied');
        }
        if (p_action == "newsuggestion") {
          console.log(p_page + '> input error');
          tlBase.add(TweenLite.to("#frmnewsuggestion", .34, { background: "#ff0000", ease: Linear.easeNone }), 0);
          tlBase.add(TweenLite.to("#frmnewsuggestion", .34, { background: '#fff', ease: Linear.easeNone }), 1.2);
          //if (p_action == "denied2") this.outsider_pages('Denied');
        }
        break;
        

      case "reanimation":
        if (p_action == "sucess") { 
          console.log(p_page + '> access granted');
          tlBase.add(TweenLite.to(this.eReanimationBox, .34, { background: "rgba(51,170,0, .61)", ease: Linear.easeNone }), 0);
          tlBase.add(TweenLite.to(this.eReanimationBox, .34, { background: '#000', ease: Linear.easeNone }), 1.2);
        }
        break;

    }


  
  }
  public magic_trg(p_target:string, p_magic:string) {
    var tlMagic = new TimelineMax();
    //tlMagic.add(TweenLite.to(document, .7, { scrollTo: { y: 0 } }), 0);
    switch(p_magic) {
      case "scrolltop": 
        //TweenLite.to(p_target, .7, { scrollTo: { y: 0 }, ease: Linear.easeNone });
        break;

      case "scrollbottom": 
        gsap.to(p_target, { duration: 0.75, scrollTo: { y: 999999999 } });
        break;

      case "fadein": 
        TweenLite.to(p_target, .9, { zIndex: 9, opacity: 1, ease: Linear.easeNone });
        break;

      case "fadeout": 
        TweenLite.to(p_target, .9, { zIndex: 9, opacity: 0, ease: Linear.easeNone });
        break;

      case "easyvanish": 
        tlMagic.add(TweenLite.to(p_target, .7, { opacity: 0, ease: Linear.easeNone }), 0);
        tlMagic.add(TweenLite.to(p_target, .1, { display: 'none', opacity: 0, ease: Linear.easeNone }), 0.9);
        break;

      case "vanish": 
        TweenLite.to(p_target, .1, { opacity: 0, ease: Linear.easeNone });
        TweenLite.to(p_target, .1, { display: 'none', opacity: 0, ease: Linear.easeNone });
        break;

      case "easybuild": 
        tlMagic.add(TweenLite.to(p_target, .1, { display: 'block', opacity: 0, ease: Linear.easeNone }), 0);
        tlMagic.add(TweenLite.to(p_target, .7, { opacity: 1, display: "block", ease: Linear.easeNone }), .2);
        break;

      case "build": 
        TweenLite.to(p_target, .1, { opacity: 1, ease: Linear.easeNone });
        TweenLite.to(p_target, .1, { display: 'block', opacity: 1, ease: Linear.easeNone });
        break;

      case "blink":
        tlMagic.add(TweenLite.to(p_target, .34, { background: "#ff0000", ease: Linear.easeNone }), 0);
        tlMagic.add(TweenLite.to(p_target, .34, { background: '#fff', ease: Linear.easeNone }), 1.2);
        break;

    }

  }

}
