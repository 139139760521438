<ul id="masternav" style="display: block">
    <li>
        <div class="content box" 
            style="position: relative; opacity: 1;padding: 30px 0;
            width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
        
            <fa-icon [icon]="FAIconToday" 
                class="boxicon" 
                style="position: relative;  margin: 9px; color: #333;font-size: 52px;" ></fa-icon>
        
            <span class="srt" 
                style="top: 16px; text-align: center;width: 100%;
                position: relative;float: left;font-size: 9px;letter-spacing: 3px;">HOJE<br /></span>
            <br/>
            <a class="btn_fixed_rect"></a>
        </div>
    </li>
    <li>
        <div class="content box" 
            style="position: relative; opacity: 1;padding: 30px 0;
            width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
        
            <fa-icon [icon]="FAIconAlert" 
                class="boxicon" 
                style="position: relative;  margin: 9px; color: #333;font-size: 52px;" ></fa-icon>
        
            <span class="srt" 
                style="top: 16px; text-align: center;width: 100%;
                position: relative;float: left;font-size: 9px;letter-spacing: 3px;">COMUNICADO<br /></span>
            <br/>
            <a class="btn_fixed_rect"></a>
        </div>
    </li>
    <li>
        <div class="content box" 
            style="position: relative; opacity: 1;padding: 30px 0;
                width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
        
            <fa-icon [icon]="FAIconLight" 
                class="boxicon" 
                style="position: relative;  margin: 9px; color: #333;font-size: 52px;" ></fa-icon>
        
            <span class="srt" 
                style="top: 16px; text-align: center;width: 100%;
                position: relative;float: left;font-size: 9px;letter-spacing: 3px;">SUGESTÃO<br /></span>
            <br/>
            <a class="btn_fixed_rect"></a>
        </div>
    </li>
    <li>
        <div class="content box" 
            style="position: relative; opacity: 1;padding: 30px 0;
                width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
        
            <fa-icon [icon]="FAIconAstronaut" 
                class="boxicon" 
                style="position: relative;  margin: 9px; color: #333;font-size: 52px;" ></fa-icon>
        
            <span class="srt" 
                style="top: 16px; text-align: center;width: 100%;
                position: relative;float: left;font-size: 9px;letter-spacing: 3px;">LEADS<br /></span>
            <br/>
            <a class="btn_fixed_rect"></a>
        </div>
    </li>
    <li>
        <div class="content box" 
            style="position: relative; opacity: 1;padding: 30px 0;
                width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
        
            <fa-icon [icon]="FAIconMembers" 
                class="boxicon" 
                style="position: relative;  margin: 9px; color: #333;font-size: 52px;" ></fa-icon>
        
            <span class="srt" 
                style="top: 16px; text-align: center;width: 100%;
                position: relative;float: left;font-size: 9px;letter-spacing: 3px;">MEMBROS<br /></span>
            <br/>
            <a class="btn_fixed_rect"></a>
        </div>
    </li>
    <li>
        <div class="content box" 
            style="position: relative; opacity: 1;padding: 30px 0;
                width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
        
            <fa-icon [icon]="FAIconPadawan" 
                class="boxicon" 
                style="position: relative;  margin: 9px; color: #333;font-size: 52px;" ></fa-icon>
        
            <span class="srt" 
                style="top: 16px; text-align: center;width: 100%;
                position: relative;float: left;font-size: 9px;letter-spacing: 3px;">NOVO MEMBRO<br /></span>
            <br/>
            <a class="btn_fixed_rect"></a>
        </div>
    </li>
    <li>
        <div class="content box" 
            style="position: relative; opacity: 1;padding: 30px 0;
                width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
        
            <fa-icon [icon]="FAIconStar" 
                class="boxicon" 
                style="position: relative;  margin: 9px; color:#333;font-size: 52px;" ></fa-icon>
        
            <span class="srt" 
                style="top: 16px; text-align: center;width: 100%;
                position: relative;float: left;font-size: 9px;letter-spacing: 3px;">DESTAQUE<br /></span>
            <br/>
            <a class="btn_fixed_rect"></a>
        </div>
    </li>
    <li>
        <div class="content box" 
            style="position: relative; opacity: 1;padding: 30px 0;
                width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
        
            <fa-icon [icon]="FAIconGallery" 
                class="boxicon" 
                style="position: relative;  margin: 9px; color: #333;font-size: 52px;" ></fa-icon>
        
            <span class="srt" 
                style="top: 16px; text-align: center;width: 100%;
                position: relative;float: left;font-size: 9px;letter-spacing: 3px;">GALERIA<br /></span>
            <br/>
            <a class="btn_fixed_rect"></a>
        </div>
    </li>
    <li>
        <div class="content box" 
            style="position: relative; opacity: 1;padding: 30px 0;
                width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
        
            <fa-icon [icon]="FAIconPlans" 
                class="boxicon" 
                style="position: relative;  margin: 9px; color: #333;font-size: 52px;" ></fa-icon>
        
            <span class="srt" 
                style="top: 16px; text-align: center;width: 100%;
                position: relative;float: left;font-size: 9px;letter-spacing: 3px;">PLANOS<br /></span>
            <br/>
            <a class="btn_fixed_rect"></a>
        </div>
    </li>
    <li>
        <div class="content box" 
            style="position: relative; opacity: 1;padding: 30px 0;
                width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
        
            <fa-icon [icon]="FAIconTask" 
                class="boxicon" 
                style="position: relative;  margin: 9px; color: #333;font-size: 52px;" ></fa-icon>
        
            <span class="srt" 
                style="top: 16px; text-align: center;width: 100%;
                position: relative;float: left;font-size: 9px;letter-spacing: 3px;">TAREFAS<br /></span>
            <br/>
            <a class="btn_fixed_rect"></a>
        </div>
    </li>
    <li>
        <div class="content box" 
            style="position: relative; opacity: 1;padding: 30px 0;
                width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
        
            <fa-icon [icon]="FAIconClasses" 
                class="boxicon" 
                style="position: relative;  margin: 9px; color: #333;font-size: 52px;" ></fa-icon>
        
            <span class="srt" 
                style="top: 16px; text-align: center;width: 100%;
                position: relative;float: left;font-size: 9px;letter-spacing: 3px;">HORÁRIOS<br /></span>
            <br/>
            <a class="btn_fixed_rect"></a>
        </div>
    </li>
    <!-- <li>
        <div class="content box" 
            style="position: relative; opacity: 1;padding: 30px 0;
                width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
        
            <fa-icon [icon]="FAIconAgenda" 
                class="boxicon" 
                style="position: relative;  margin: 9px; color: #999;font-size: 52px;" ></fa-icon>
        
            <span class="srt" 
                style="top: 16px; text-align: center;width: 100%;
                position: relative;float: left;font-size: 9px;letter-spacing: 3px;">AGENDA<br /></span>
            <br/>
            <a class="btn_fixed_rect"></a>
        </div>
    </li> -->
    <li>
        <div class="content box" 
            style="position: relative; opacity: 1;padding: 30px 0;
                width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
        
            <fa-icon [icon]="FAIconSettings" 
                class="boxicon" 
                style="position: relative;  margin: 9px; color: #333;font-size: 52px;" ></fa-icon>
        
            <span class="srt" 
                style="top: 16px; text-align: center;width: 100%;
                position: relative;float: left;font-size: 9px;letter-spacing: 3px;">CONFIGURAÇÕES<br /></span>
            <br/>
            <a class="btn_fixed_rect"></a>
        </div>
    </li>
</ul>

<ul id="masternav">
                
    <li>
        <div class="content box" 
            style="position: relative; opacity: 1;padding: 30px 0;
                width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
        
            <fa-icon [icon]="FAIconPartners" 
                class="boxicon" 
                style="position: relative;  margin: 9px; color: #333;font-size: 52px;" ></fa-icon>
        
            <span class="srt" 
                style="top: 16px; text-align: center;width: 100%;
                position: relative;float: left;font-size: 9px;letter-spacing: 3px;">FORNECEDORES<br /></span>
            <br/>
            <a class="btn_fixed_rect"
                (click)="trg_actions('partners')"></a>
        </div>
    </li>
    <li>
        <div class="content box" 
            style="position: relative; opacity: 1;padding: 30px 0;
                width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
        
            <fa-icon [icon]="FAIconProducts" 
                class="boxicon" 
                style="position: relative;  margin: 9px; color: #666;font-size: 52px;" ></fa-icon>
        
            <span class="srt" 
                style="top: 16px; text-align: center;width: 100%;
                position: relative;float: left;font-size: 9px;letter-spacing: 3px;">PRODUTOS<br /></span>
            <br/>
            <a class="btn_fixed_rect"
                (click)="trg_actions('products')"></a>
        </div>
    </li>
    <li>
        <div class="content box" 
            style="position: relative; opacity: 1;padding: 30px 0;
                width: 30%;overflow: hidden;float: left;display: inline-block;min-width: 30%;text-align: center;">
        
            <fa-icon [icon]="FAIconOrders" 
                class="boxicon" 
                style="position: relative;  margin: 9px; color: #999;font-size: 52px;" ></fa-icon>
        
            <span class="srt" 
                style="top: 16px; text-align: center;width: 100%;
                position: relative;float: left;font-size: 9px;letter-spacing: 3px;">PEDIDOS<br /></span>
            <br/>
            <a class="btn_fixed_rect"
                (click)="trg_actions('orders')"></a>
        </div>
    </li>
    
</ul>