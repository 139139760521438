<div id="page_classes">
    <div id="box-pages" class="block_pages">
        <div class="head">
            <fa-icon [icon]="FAIconHead" class="pageicon"></fa-icon>
            <h1 class="title">AULAS</h1>
            <br/>
            <p class="total" *ngIf="model_classes.length > 0">{{ model_classes.length }} registros</p>
        </div>

        <div *ngIf="model_classes.length > 0" class="" style="opacity: 1;">

            <div id="frmsearch" class="frm" style="min-height: 34px;">
                <!--- skill --->
                <input #search_skill class="inputtxt" type="text" 
                    placeholder="Categoria" list="search_skills" 
                    value="{{ model_search_skill }}" 
                    (change)="select_change('search', search_skill.value)"
                    (focus)="search_skill.value='';"
                    (click)="search_skill.value='';"
                 />
                <datalist id="search_skills">
                    <option value="jiu">Jiu Jitsu</option>
                    <option value="thay">Muay Thay</option>
                    <option value="boxe">Boxe</option>
                </datalist>
            </div>

            <div class="window">
                <div *ngIf="model_classes.length <= 0 && page_frm == false && page_frm_detail == false" class="empty"><br/><br/><br/>
                    <p style="text-align: center;color: #ccc;">Nenhum registro encontrado</p>
                </div>
                <ul style="padding-bottom: 90px;opacity: 1;" *ngIf="page_frm == false && page_frm_detail == false">
                    <li *ngFor="let regskill of model_base_skills" id="phome-liplanbox{{ regskill }}" class="content box alivebg"  
                        style="opacity: 1;padding: 0;" >
                        <div *ngIf="regskill == model_search_skill">
                            <img class="boxicon" 
                                style="margin-bottom: 30px;width: 34px;top: 18px; left:0px;position: relative;"
                                    src="{{ '../assets/img/categs/' + regskill + '.png' }}" />
                                
                            <div *ngFor="let regday of model_base_days">
                                <div *ngFor="let reg of filterClasses(regskill, regday)" 
                                    id="planbox{{ reg.id }}" class="alertbox" 
                                    style="position: relative;padding: 9px">
                                <!-- <h4 style="font-weight: 600; padding: 18px 0 9px 0;display: inline-block; float: left;">
                                    {{ reg.skill }}</h4> -->
                                <p style="line-height: 25px;">
                                    {{ reg.day  }} <b>{{ reg.time  }}</b></p>
                                
                                <a *ngIf="reg.alive == 1 && lhaylie.player_permission(true, 0) == 1" class="btn_check fix" 
                                    style="float: right;background: #0095f6;margin: 3px;"
                                    (click)="goHaylie('edit', reg.id.toString())">
                                    <fa-icon [icon]="FAIconEdit" class="pageicon"
                                    style="font-size: 18px; display: block; margin: 9px;  
                                            text-align: left; color: #fff;"></fa-icon>
                                </a>

                                </div>
                            </div>
                        </div>
                    </li>
                    <li style="height: 90px;float: left; width: 90%;"></li>
                </ul>
            </div>
            <div id="frmclass" *ngIf="page_frm" class="content box"
                style="opacity: 1; position: absolute;top: 93px;box-shadow: 1px 2px 2px #000;">
                
                <fa-icon [icon]="FAIconEdit" class="pageicon"
                        style="font-size: 18px; display: block; margin: 9px;  
                                text-align: left; color: #333;"></fa-icon>
                <h1 class="srt" style="top: 16px; left: 34px;">AULA | DETALHES</h1>
                <p class="sub" id="msgbox"></p>
                <br/>
                <div class="frm">
                    <!--- skill --->
                    <input #class_skill class="inputtxt" type="text" 
                        placeholder="Categoria" list="plan_skills"
                        value="{{ model_frm_skill }}"
                        (focus)="class_skill.value='';"
                        (click)="class_skill.value='';"
                     />
                    <datalist id="plan_skills">
                        <option value="jiu">Jiu Jitsu</option>
                        <option value="thay">Muay Thay</option>
                        <option value="boxe">Boxe</option>
                    </datalist>
                    <!--- day --->
                    <input #class_day class="inputtxt" type="text" 
                        placeholder="Dia" list="plan_days"
                        value="{{ model_frm_day }}"
                        (focus)="class_day.value='';"
                        (click)="class_day.value='';"
                     />
                    <datalist id="plan_days">
                        <option value="segunda">Segunda-feira</option>
                        <option value="terça">Terça-feira</option>
                        <option value="quarta">Quarta-feira</option>
                        <option value="quinta">Quinta-feira</option>
                        <option value="sexta">Sexta-feira</option>
                        <option value="sábado">Sábado</option>
                        <option value="domingo">Domingo</option>
                    </datalist>
                    <!--- time --->
                    <input #class_time class="inputtxt" type="time" 
                        placeholder="Hora" 
                        maxlength="5"
                        value="{{ model_frm_time }}"
                        />
                    <!-- <datalist id="agenda_timebox">
                        <option *ngFor="let t of model_timebox" [value]="t">{{ t }}</option>
                    </datalist> -->
                    
                    <button class="btn-splatter" (click)="goHaylie('frm', '0')"
                        style="top: 63px; font-size: 9px; letter-spacing: 3px;">
                        SALVAR</button>
                    <a class="btnReturn" (click)="switchFrm('off')">
                        VOLTAR</a>

                    <a class="btnrectransp" *ngIf="player_permission == 1 && model_frm_id > 0" 
                        (click)="goHaylie('kill', '')" 
                        style="color: #333333; border: 1px solid; margin-top: 90px;
                            font-size: 12px;letter-spacing: 1px;">
                        REMOVER AULA</a>

                </div>
            </div>
            <div id="frmclassdetail" *ngIf="page_frm_detail" class="content box"
                style="opacity: 1; position: absolute;top: 52px;box-shadow: 1px 2px 2px #000;">
                
                <fa-icon [icon]="FAIconEdit" class="pageicon"
                        style="font-size: 18px; display: block; margin: 9px;  
                                text-align: left; color: #666;"></fa-icon>
                <!-- <h1 class="srt" style="top: 16px; left: 34px;">AULA | {{ model_class_detail.day.toUpperCase() }}</h1> -->
                <h1 class="srt" style="top: 16px; left: 34px;">AULA | HOJE</h1>
                <p class="sub" id="msgbox"></p>
                <br/>
                <div class="frm" style="text-align: center;">
                    <img class="boxicon" 
                                style="margin: 0;
                                width: 34px;
                                top: -45px;
                                right: 9px;
                                position: relative;
                                float: right;"
                                    src="{{ '../assets/img/categs/' + model_class_detail.skill + '.png' }}" />
                                
                    <h4>{{ model_class_detail.skill }}</h4>
                    <p>{{ model_class_detail.time }}</p>
                    <div style="width: 95%; margin: 9px 2.5%; border: 1px solid #eee; height: 300px;overflow: hidden; overflow-y: scroll;">
                        <ul *ngIf="model_agenda_today.length > 0">
                            <li *ngFor="let reg of model_agenda_today"
                                style="width: 100%;margin: 0;font-size: 12px;border-bottom: 1px solid #eee;padding: 18px 0;position: relative;float: left;{{ reg._member == false ? 'background: #ef0;' : '' }}">
                                <span class="padawan">{{ reg.padawan.substr(0, 25) }}</span>
                                
                                <fa-icon *ngIf="reg._member == false" [icon]="FAIconNotMember" 
                                            style="font-size: 18px; position: absolute; left: 9px;  
                                                    top: 12px; color: #333;"></fa-icon>
                                <fa-icon *ngIf="reg._member" [icon]="FAIconMember" 
                                            style="font-size: 18px; position: absolute; left: 9px;  
                                                    top: 12px; color: #666;"></fa-icon>

                                <a class="btn_check fix" 
                                   (click)="goHaylie('deleteagenda', reg.id)"
                                    style="position: absolute;right: 9px;color: #ff0000;margin: 0px;background: transparent;box-shadow: none;top: 9px;">
                                            <fa-icon [icon]="FAIconDelete" 
                                            style="font-size: 18px; display: block; margin: 0;  
                                                    text-align: left; color: #ff0000;"></fa-icon>
                                        </a>
                            </li>
                        </ul>
                        <div *ngIf="model_agenda_today.length <= 0" class="empty">
                            <p style="text-align: center;color: #333;
                                margin-top: 30px;font-size: 13px;">Nenhum registro encontrado</p>
                        </div>  
                    </div>
                    <!-- <a class="btnReturn" (click)="switchFrm('off')">
                        VOLTAR</a> -->
                    <a class="btnrectransp" 
                        (click)="goPage('home')" style="color: #0095f6;">
                        VOLTAR</a>

                </div>
            </div>
            
        </div> 

        <a id="btn_frm" class="fixed_action" 
            *ngIf="player_permission == 1 && page_frm_detail == false"
            (click)="switchFrm('on')">
            <fa-icon [icon]="FAIconPlus" class="ico"></fa-icon>
        </a>
    </div>

</div>