import { Injectable } from '@angular/core';
import { eProfile, ePadawan, eClass } from './entities';

@Injectable({
  providedIn: 'root'
})
export class AngelService {

  constructor() { }

  private l_outsider_key = ""; 
  outsider_key(p_get: boolean, p_key: string) {
    if (!p_get)
      this.l_outsider_key = p_key;
      
    return this.l_outsider_key;
  }

  private l_player_compass = ""; 
  player_compass(p_get: boolean, p_compass: string) {
    if (!p_get)
      this.l_player_compass = p_compass;

    //console.log('angel_compass.' + (p_get ? 'get' : 'set') + '> ' + this.l_player_compass);

    return this.l_player_compass;
  }

  private l_profile_token = ''; 
  profile_token(p_get: boolean, p_token: string) {
    if (!p_get)
      this.l_profile_token = p_token;

    console.log('angel_profile.' + (p_get ? 'get' : 'set') + '> ' + this.l_profile_token);

    return this.l_profile_token;
  }

  private l_player_target = ""; 
  player_target(p_get: boolean, p_target: string) {
    if (!p_get)
      this.l_player_target = p_target;

    return this.l_player_target;
  }
  private l_player_padawan : ePadawan = new ePadawan(); 
  player_padawan(p_get: boolean, p_padawan: ePadawan) {
    if (!p_get)
      this.l_player_padawan = p_padawan;

    return this.l_player_padawan;
  }
  private l_class_target : eClass = new eClass(); 
  class_target(p_get: boolean, p_class: eClass) {
    if (!p_get)
      this.l_class_target = p_class;

    return this.l_class_target;
  }



}
