<div class="image-upload-container btn btn-bwm" 
    style="text-align: center; display: block; opacity: 1; top: -12px; position: relative; " 
    id="imgupbox">
    <img *ngIf="!page_loading && org == 'register'" src="{{ 'http://haylie.bosswebapps.net/' + currentImg }}" 
        style="width: 90px; height: 90px; border-radius: 900px; box-shadow: 1px 2px 2px rgb(255, 255, 255, .3);" />
    
    <fa-icon *ngIf="!page_loading && org == 'gallery'" 
        style="border-radius: 90px; color: #fff; padding: 9px; background: #0095f6;box-shadow: 1px 2px 2px rgb(255, 255, 255, .7);"
        [icon]="boxFAIconBtnUp" class="pageicon"></fa-icon>


    <img *ngIf="page_loading" src="../assets/img/gif/loading.gif" 
        style="width: 90px; height: 90px; border-radius: 900px; box-shadow: 1px 2px 2px rgb(255, 255, 255, .3);" />


    <input #imageInput 
        style="text-indent: 300px;background: transparent; height: 90px; " 
           type="file"
           accept="image/*"
           (change)="runFile(imageInput)">
</div>