<div id="page_agenda">
    <div id="box-pages" class="block_pages">
        <div class="head">
            <fa-icon [icon]="pageFAIcon" class="pageicon"></fa-icon>
            <h1 class="title">AGENDA</h1>
            <br/>
            <p class="total" *ngIf="model_full.length > 0">{{ model_full.length }} registros</p>
        </div>

        <div class="window" style="opacity: 1;">
      
            <ul id="lst" style="position: relative; float: left; height: auto; padding-bottom: 36px;">
                <li *ngFor="let reg of model_full">
                    <span class="date">{{ reg.dateto_short.substr(0,5) }}</span>
                    <span class="time">{{ reg.datetotime.substr(0, 2) + ':' + reg.datetotime.substr(2, 2) }}</span>
                    <span class="skill {{ reg.skill }}">{{ reg.skill }}</span>
                    <span class="padawan">{{ reg.padawan.substr(0, 16) }}</span>
                    
                    <a class="kill" (click)="goHaylie('deleteagenda', reg.id)">
                        <fa-icon [icon]="FAIconRemove" class="reglineaction" style="font-size: 16px; float: right;"></fa-icon>
                    </a>

                    
                </li> 
            </ul>
            <div *ngIf="model_full.length <= 0" class="empty">
                <p style="text-align: center;color: #ccc;">Nenhum registro encontrado</p>
            </div>    
            
            <div id="frmagenda" *ngIf="page_frm" class="content box"
                style="opacity: 1; position: absolute;top: 99px;box-shadow: 1px 2px 2px #000;">
                
                <fa-icon [icon]="frmFAIcon" class="pageicon"
                        style="font-size: 18px; display: block; margin: 9px;  
                                text-align: left; color: #333;"></fa-icon>
                <h1 class="srt" style="top: 16px; left: 34px;">NOVO AGENDAMENTO</h1>
                <p class="sub" id="msgbox"></p>
                <br/>
                <div class="frm">
                    <!--- skill --->
                    <input #agenda_skill class="inputtxt" type="text" 
                        placeholder="Aula" list="agenda_skills"
                        (change)="select_change('skill', agenda_skill.value)"
                        (keydown)="$event.preventDefault()"
                        (focus)="agenda_skill.value=''" />
                    <datalist id="agenda_skills">
                        <option value="jiu">Jiu Jitsu</option>
                        <option value="thay">Muay Thay</option>
                        <option value="boxe">Boxe</option>
                    </datalist>
                    <!--- dateto --->
                    <input #agenda_dateto class="inputtxt" type="text"  
                        data-placeholder="Dia" required aria-required="true"
                        name="dateto" placeholder="Dia" list="agenda_dates" 
                        (change)="select_change('dateto', agenda_dateto.value)"
                        (keydown)="$event.preventDefault()"
                        (focus)="agenda_dateto.value=''" />
                    <datalist id="agenda_dates">
                        <option *ngFor="let d of model_agenda" [value]="d.dateshort">{{d.dayofweek}}</option>
                    </datalist>
                    <!--- timebox --->
                    <input #agenda_time class="inputtxt" type="text" 
                        placeholder="Hora" list="agenda_timebox"
                        (change)="select_change('time', agenda_time.value)"
                        (keydown)="$event.preventDefault()"
                        />
                    <datalist id="agenda_timebox">
                        <option *ngFor="let t of model_timebox" [value]="t">{{ t }}</option>
                    </datalist>
                
                    <!-- <input *ngIf="player_permission == 1"  
                        class="inputtxt" type="text" #agenda_padawan name="padawan" value="" placeholder="Nome" /> -->
                    <!--- padawan --->
                    <input #agenda_padawan 
                        *ngIf="player_permission == 1"
                        class="inputtxt" type="text"  
                        data-placeholder="Aluno" required aria-required="true"
                        name="dateto" placeholder="Aluno" list="agenda_members" 
                        (keydown)="$event.preventDefault()"
                        (focus)="agenda_padawan.value=''"
                        (click)="agenda_padawan.value=''"
                         />
                    <datalist id="agenda_members">
                        <option *ngFor="let p of model_players" [value]="p.id">{{p._name}}</option>
                    </datalist>

                    <button class="btn-splatter" (click)="goHaylie('frm', '0')"
                        style="top: 63px; font-size: 9px; letter-spacing: 3px;">
                        AGENDAR</button>
                    <a class="btnReturn" (click)="switchFrm('off')">
                        VOLTAR</a>

                </div>
            </div>
        </div>

        <a id="btn_frm" class="fixed_action" (click)="switchFrm('on')">
            <fa-icon [icon]="FAIconPlus" class="ico"></fa-icon>
        </a>

    </div>
</div>