import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router'

import { faUserAstronaut, faCheck, faTimes, faChartLine } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { eLead, eReport } from '../services/entities'
import { GsapService } from '../services/gsap.service'
import { HaylieService } from '../services/haylie.service'
import { MorganaService } from '../services/morgana.service';

@Component({
  selector: 'app-page-leads',
  templateUrl: './page-leads.component.html',
  styleUrls: ['./page-leads.component.css']
})
export class PageLeadsComponent implements OnInit {
  current_y = (new Date()).getFullYear().toString();
  current_ly = ((new Date()).getFullYear() - 1).toString();
  current_date_label = (new Date()).toLocaleDateString('pt-br') + ' ' + (new Date()).toLocaleTimeString('pt-br');

  player_live = false;
  model_leads: eLead[] = [];
  model_rptleads : eReport[] = [];
  model_rptleadsly : eReport[] = [];
  page_rpt: boolean = false;
  lhaylie: any;
  lmorgana: any;
  totaly: any;
  totally: any;

  FAIconAstronaut = faUserAstronaut;
  FAIconCheck = faCheck;
  FAIconDelete = faTimes;
  FAIconWhats = faWhatsapp;
  FAIconDash = faChartLine;

  @Input() activated: boolean = false;

  constructor(private gsap: GsapService, 
    private haylie: HaylieService,
    private morgana: MorganaService,  
    private router: Router) 
    { 
      this.lhaylie = this.haylie;
      this.lmorgana = this.morgana;
    }

  ngOnInit(): void {
    
  }
  ngOnChanges() { 
    if (this.activated) this.content_refresh();
  }
  content_refresh() {
    this.gsap.anima_trg('cronos', 'showup');
    this.switchFrm("init");
    
    if (this.haylie.player_token(true, '').length > 0) {
      console.log('player> ' + this.haylie.player_token(true, ''));
      this.get_leads();
      this.haylie.get_dash().then(this.catch_dash); 

      this.player_live = this.haylie.player_token(true, '').length > 0;
    }
    else {
      this.player_live = false; 

      this.gsap.magic_trg("#outsider_wrapper", "build");
      this.router.navigateByUrl('octalogin');
    }
  }
  get_leads(): void {
    this.model_leads = [];

    this.haylie.get_leads("9").then(this.catch_leads);
  }
  catch_leads = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_leads = l_response.leads;

    this.gsap.anima_trg('cronos', '');
    return this.model_leads;
  }
  private catch_dash = (p_response: any) => {
    var l_response = JSON.parse(p_response);

    if (l_response.report!= null) { 
      //this.model_dash = l_response.report;
      this.model_rptleads = l_response.rptleads;
      this.model_rptleadsly = l_response.rptleadslastyear;

      this.totaly = this.model_rptleads.map(x => x.total).reduce((a, b) => a + b);
      this.totally = this.model_rptleadsly.map(x => x.total).reduce((a, b) => a + b);
    }
    else console.log("rptleads.catch-error");
  }
  goBack():void {
    this.router.navigateByUrl("octahome");
  }
  goHaylie(p_action: string, p_target: string): void{
    console.log(p_action + ">" + p_target);

    switch(p_action) {
      case "leadcheck": 
        console.log('haylie.kill-lead>' + p_target);
        this.gsap.anima_trg('cronos', 'showup');
        
        this.haylie.kill_content("lead", p_target).subscribe(
          (res) => { 
            this.haylie.get_leads("9").then(this.catch_leads);
          }); 

        break;

      case "": 
        break;
      
    }
  }
  switchFrm(p_action:string):void {
    
    if (p_action != 'init') {
      this.page_rpt = true;
      this.gsap.magic_trg('#frmleadsrpt', 'easybuild');
      this.gsap.magic_trg('#page_leads .fixed_action', 'vanish');
    }
    else { 
      this.gsap.magic_trg('#frmleadsrpt', 'easyvanish');
      this.gsap.magic_trg('#page_leads .fixed_action', 'easybuild');
      this.page_rpt = false;
    }
    
  }

}
