import { ViewChild, Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { faEdit, faCloudUploadAlt, faBan, faCalendarCheck, faEnvelope, faHandMiddleFinger, faKey, faPen, faUserNinja, faComments } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { AngelService } from '../services/angel.service';
import { ePadawan, eMedia, eProfile, ePlan, ImageSnippet } from '../services/entities';
import { GsapService } from '../services/gsap.service';
import { HaylieService } from '../services/haylie.service';
import { CookieService } from '../services/cookie.service';

@Component({
  selector: 'app-page-profile',
  templateUrl: './page-profile.component.html',
  styleUrls: ['./page-profile.component.css']
})
export class PageProfileComponent implements OnInit {
  pageFAIcon = faUserNinja;
  FAIconWhats = faWhatsapp;
  FAIconBan = faBan;
  FAIconMail = faEnvelope;
  FAIconKey = faKey;
  FAIconEdit = faEdit;
  FAIconMsg = faComments;
  FAIconAgenda = faCalendarCheck;
  FAIconFuckU = faHandMiddleFinger;
  FAIconPen = faPen;
  FAIconBtnUp = faCloudUploadAlt;
  player_live = false;
  player_permission = 0;
  page_frm_key = false;
  page_frm_profile = false;
  frmcheck = false;

  model_avatar = 'avatar.jpg';
  model_name = '';
  model_phone = '';
  model_mail = '';
  model_plan = '';
  model_planid = 0;
  model_target = 0;
  model_since : Date = new Date();
  model_frm_pwd = '';
  model_frm_pwd2 = '';

  model_cplayer = false; 

  model_media: eMedia = new eMedia();
  model_profile: eProfile = new eProfile();
  model_padawan: ePadawan = new ePadawan();
  model_plans: ePlan[] = [];

  frm_pro_name = '';
  frm_pro_plan = '';
  frm_pro_mail = '';
  frm_pro_phone = '';
  frm_pro_since = '';

  currenttarget = '';

  constructor(private router: Router, private cookie: CookieService, 
    private gsap: GsapService, private haylie: HaylieService, private angel: AngelService) { 

    }

  @ViewChild('key_1') frmkey1:any;
  @ViewChild('key_2') frmkey2:any;

  @ViewChild('frmprofile_name') frmProfileName:any;
  @ViewChild('frmprofile_plan') frmProfilePlan:any;
  @ViewChild('frmprofile_phone') frmProfilePhone:any;
  @ViewChild('frmprofile_mail') frmProfileMail:any;
  @ViewChild('frmprofile_since') frmProfileSince:any;
  

  //@Input() model_player = this.angel.profile_id(true, 0);
  @Input() activated: boolean = false;

  private catch_plans = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_plans = l_response.plans;

    this.gsap.anima_trg('cronos', '');
    return this.model_plans;
  }
  catch_keys = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.keys> ' + l_response.Message);
  }
  catch_profile = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.profile> ' + l_response.player._profile.name);

    this.model_profile = l_response.player._profile;
    this.model_media = l_response.player._profile._media;

    this.model_avatar = this.model_media.name;
    this.model_name = this.model_profile.name;
    this.model_mail = l_response.player.mail;

    this.model_target = l_response.player.id; 
    this.model_plan = l_response.player._plan.name;
    this.model_planid = l_response.player.id_plan; 
    this.frm_pro_plan = this.model_planid.toString();
    this.model_phone = l_response.player._profile.phone;
    this.model_padawan.id = this.model_target.toString();
    this.model_padawan._name = this.model_name;
    this.model_padawan._avatar = this.model_avatar;
    this.angel.player_padawan(false, this.model_padawan);

    //this.frmProfilePlan.nativeElement.value = this.model_planid;
    //this.angel.player_targetprofile(false, this.model_profile);

    this.model_since = l_response.player._profile.datebirth;
    //this.frmProfileSince.nativeElement.vaue = this.model_since;

    this.model_cplayer = this.angel.profile_token(true, '') == this.cookie.getCookie("player_token");

    this.gsap.anima_trg('cronos', '');
  
  }
  content_refresh() {
    this.switchFrm('init');
    this.page_frm_key = false;
    this.page_frm_profile = false;
    
    if (this.angel.profile_token(true, '').length > 0) {

      this.model_cplayer = this.angel.profile_token(true, '') == this.cookie.getCookie("player_token");

      this.gsap.anima_trg('cronos', 'showup');
  
      //console.log('player> ' + this.haylie.player_token(true, ''));
      
      this.haylie.get_plans().then(this.catch_plans);
      
      this.haylie.get_profile(this.angel.profile_token(true, '')).then(this.catch_profile);

      this.currenttarget = this.angel.profile_token(true, '');
    }
    else {
      this.player_live = false; 

      this.gsap.magic_trg("#outsider_wrapper", "build");
      this.router.navigateByUrl('octalogin');
    }
  
  }
  ngOnChanges() {
    if (this.activated || this.currenttarget != this.angel.profile_token(true, '') || this.angel.profile_token(true, '') == this.cookie.getCookie("player_token")) this.content_refresh();
  }
  ngOnInit(): void {
    if (this.haylie.player_permission(true, 0) == 0) 
       this.haylie.player_permission(false, parseInt(this.cookie.getCookie("player_permission")));

    this.player_permission = this.haylie.player_permission(true, 0);
  
    if (this.haylie.player_token(true, '') == '') 
       this.haylie.player_token(false, this.cookie.getCookie("player_token"));

    this.model_cplayer = this.angel.profile_token(true, '') == this.cookie.getCookie("player_token");

    
  }
  ngAfterViewInit() {
    this.model_cplayer = this.angel.profile_token(true, '') == this.cookie.getCookie("player_token");

  }
  switchFrm(p_action:string):void {
    if (p_action == 'key') {
      this.page_frm_profile = false;
      this.page_frm_key = true;
    }
    else {
      if (p_action == 'profile') {
        this.page_frm_key = false;
        this.page_frm_profile = true;
      } 
      else {
        this.gsap.anima_trg('cronos', '');
        this.page_frm_key = false;
        this.page_frm_profile = false;
      }
    }

  }
  goHaylie(p_action:string, p_target: string) {
    switch(p_action) {
      case "goteam":
            console.log('goteam-loguser> ' + p_target); 
            
            this.angel.player_compass(false, "team");
            this.gsap.player_pages("team");
          break;

        case "gomsgs":
            console.log('gomsgs-loguser> ' + p_target); 
            this.angel.player_target(false, p_target);
              
            this.angel.player_compass(false, "msgs");
            this.gsap.player_pages("msgs");
          break;

        case "newkeys":
            this.gsap.anima_trg('cronos', 'showup');
            var frmcheck = true;
            var key1 = '', key2 = '';

            if (this.frmkey1.nativeElement.value.length <= 0)
            { frmcheck = false; console.log('frmerror> missing key1'); }
            else  
              key1 = this.frmkey1.nativeElement.value;

            if (this.frmkey2.nativeElement.value.length <= 0)
            { frmcheck = false; console.log('frmerror> missing key2'); }
            else  
              key2 = this.frmkey2.nativeElement.value;

            if (key1 != key2)
            { frmcheck = false; console.log('frmerror> keys dont match'); }

            if (frmcheck) {
              console.log('sending keys');
              this.haylie.player_locker(key1).then(this.catch_keys);
              console.log('done.');
              this.switchFrm("off");
            }
            else {
              this.gsap.magic_trg("#frmkey", "blink");
              this.gsap.anima_trg('cronos', '');
            }
        break;

        case "kill": 
          console.log('haylie.kill-player>' + p_target);
          this.gsap.anima_trg('cronos', 'showup');
          
          this.haylie.kill_content("player", p_target).subscribe(
            (res) => { 
              this.angel.player_compass(false, 'team');
              this.gsap.player_pages('team');
            }); 

        break;

        case "review":
          this.frmcheck = true;

          if (this.frmProfileName.nativeElement.value.length <= 0)
          { this.frmcheck = false; console.log('padawanerror> missing name'); }
          else  
            this.frm_pro_name = this.frmProfileName.nativeElement.value;
          
          if (this.frmProfileMail.nativeElement.value.length <= 0)
          { this.frmcheck = false; console.log('padawanerror> missing mail'); }
          else  
            this.frm_pro_mail = this.frmProfileMail.nativeElement.value;
          
          if (this.frmProfilePhone.nativeElement.value.length <= 0)
          { this.frmcheck = false; console.log('padawanerror> missing phone'); }
          else  
            this.frm_pro_phone = this.frmProfilePhone.nativeElement.value;
          
          if (this.frmProfilePlan.nativeElement.value.length <= 0)
          { this.frmcheck = false; console.log('padawanerror> missing plan'); }
          else  
            this.frm_pro_plan = this.frmProfilePlan.nativeElement.value;

            this.model_planid = parseInt(this.frm_pro_plan);

          if (this.frmProfileSince.nativeElement.value.length <= 0)
          { this.frmcheck = false; console.log('padawanerror> missing dt init'); }
          else  
            this.frm_pro_since = this.frmProfileSince.nativeElement.value;

          /*if (this.haylie.outsider_photo(true, 0) <= 0)
          { this.frmcheck = false; console.log('padawanerror> missing photo'); }
          else  
            this.padawanphoto = this.haylie.outsider_photo(true, 0);*/

          if (this.frmcheck) {
            this.haylie.player_review(this.frm_pro_name, this.frm_pro_mail, this.frm_pro_phone, this.frm_pro_plan, this.frm_pro_since);
            //this.haylie.get_profile(this.angel.profile_token(true, '')).then(this.catch_profile);
            this.angel.player_compass(false, 'team');
            this.gsap.player_pages('team');
            //this.router.navigateByUrl('octateam');
          }  
          else 
            this.gsap.anima_trg('outsider', 'denied'); 

        break;


    }
  }

  runFile3(imgInput: any): void {

    const file2: File = imgInput.files[0];
    const reader2 = new FileReader();

    reader2.addEventListener('load', (event: any) => {
      this.gsap.anima_trg('cronos', 'showup');

      let selectedFile = new ImageSnippet(event.target.result, file2);

      this.haylie.send_avatar(selectedFile.file).subscribe(
        (res) => {
          console.log('success.profilemedia> ' + res.substr(0,res.indexOf("###mid")));
          this.model_avatar = res.substr(0,res.indexOf("###mid")).replace("padawan/", "");
          let avatarid = res.substr(res.indexOf("###mid") + 6);
          this.haylie.player_setavatar(avatarid);
          this.haylie.player_avatar(false, this.model_avatar);  

          /*this.haylie.player_compass(false, 'home');
          this.angel.player_compass(false, 'profileimg');
          this.angel.player_compass(false, 'team');*/
          this.gsap.anima_trg('cronos', '');
        },
        (err) => {
          //this.onError();
          console.log('error> ' + err);
          this.gsap.anima_trg('cronos', '');
        })

    }); 

    reader2.readAsDataURL(file2);

  }

}
