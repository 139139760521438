import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faPython } from '@fortawesome/free-brands-svg-icons';
import { faGem, faCheck, faLock, faLockOpen, faMailBulk, faPaperPlane, faTimes, faUserCheck, faCameraRetro } from '@fortawesome/free-solid-svg-icons'
import { AngelService } from '../services/angel.service';

import { CookieService } from '../services/cookie.service'
import { GsapService } from '../services/gsap.service'
import { HaylieService } from '../services/haylie.service'



@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.css']
})
export class PageLoginComponent implements OnInit {
  page_loading = false;
  response_msg = '';
  langel :any;

  constructor(private router: Router,
    private gsap: GsapService, private haylie: HaylieService, 
    private angel: AngelService, private cookie: CookieService) {
      this.langel = this.angel;
  }
  
  @ViewChild('padawan') frmPadawanName:any;
  @ViewChild('padawanmail') frmPadawanMail:any;
  @ViewChild('padawanphone') frmPadawanPhone:any;

  @ViewChild('lmail') frmLoginMail:any;
  @ViewChild('lkey') frmLoginKey:any;
  @ViewChild('lkey2') frmLoginKey2:any;

  @ViewChild('ghost') frmRecoverMail:any;

  FAIconLab = faGem;

  FAIconMaster = faGem;//faCrown;
  FAIconLocker = faLock;
  FAIconLocker2 = faLockOpen;
  FAIconRecover = faMailBulk;
  FAIconPremium = faGem;
  FAIconCheck = faCheck;
  FAIconPlane = faPaperPlane;
  FAIconDenied = faTimes;

  FAIconRegStep1 = faUserCheck;
  FAIconRegStep2 = faCameraRetro;
  FAIconRegStep3 = faMailBulk;

  maindomain = 'newfight';

  frmcheck = true;
  frmlocked = true;
  lmail = '';
  lkey = '';
  lghost = '';
  padawan = '';
  padawanmail = '';
  padawanphone = '';
  padawanphoto = 0;
  padawanstep = 0;
  btnregisterlabel = 'CONTINUAR';

  termsverb = 'logar';

  ngOnInit(): void {
    this.maindomain = window.location.toString().indexOf("academianewfight") > 0 ? 'newfight' : 'bwa';

    console.log('p.login-init.cookietoken>  ' + this.cookie.getCookie("player_token"));
    this.haylie.player_token(false, this.cookie.getCookie("player_token"));
    console.log('p.login-init.cookieavatar>  ' + this.cookie.getCookie("player_avatar"));
    this.haylie.player_avatar(false, this.cookie.getCookie("player_avatar"));
    console.log('p.login-init.cookiepermission>  ' + this.cookie.getCookie("player_permission"));
    this.haylie.player_permission(false, parseInt(this.cookie.getCookie("player_permission")));

    if (this.haylie.player_token(true, '').length > 0) {
      this.router.navigateByUrl('octahome');
    }
    else {
      
      this.gsap.magic_trg("#outsider_wrapper", "build");

      //this.gsap.init();
      this.frmlocked = true;
    }
  }
  ngAfterViewInit() {
    this.frmlocked = true; 
    //this.switchKeys();

    if (this.haylie.player_token(true, '').length <= 0) this.gsap.init(this.maindomain);

    this.frmLoginKey.nativeElement.value = '';
    this.frmLoginKey2.nativeElement.value = ''; 
    this.frmLoginMail.nativeElement.focus();
  }
  private catch_login = (p_response: any): void =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.login> ' + l_response);

    this.gsap.anima_trg('cronos', '');

    if (l_response.id > 0) {
      
      this.gsap.magic_trg("#outsider_wrapper", "vanish");
      this.haylie.player_id(false, l_response.id);
      this.haylie.player_token(false, l_response.token);
      this.haylie.player_avatar(false, l_response._profile._media.name);
      this.haylie.player_permission(false, l_response.id_permission);
      this.angel.player_target(false, l_response.id);

      this.haylie.send_game("login", "3").then(this.catch_game);
      
      this.router.navigateByUrl('octahome');
    }
    else 
      this.gsap.anima_trg('outsider', 'denied');
    
    //setTimeout(() => this.gsap.outsider_pages("Login"), 7000);
    
  }
  private catch_game = (p_response: any): void =>  {
  
  }
  private catch_bringmeback = (p_response: any): void =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.bringmeback> ' + l_response);

    this.response_msg = l_response.msg;

    this.gsap.anima_trg('cronos', '');

    if (l_response.result) {
      this.gsap.anima_trg('outsider', 'granted2');

      setTimeout(() => { 
        this.gsap.outsider_pages("Login"); 
        this.frmlocked = false; 
        this.switchKeys();
      }
      , 2500);
    }
    else {
      this.gsap.anima_trg('outsider', 'denied2');
    
      setTimeout(() => this.gsap.outsider_pages("Recover"), 1800);
    }
    
    //setTimeout(() => this.gsap.outsider_pages("Recover"), 1800);
    
  }
  private catch_register = (p_response: any): void =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.register> ' + l_response);

    this.response_msg = l_response.msg;

    this.gsap.anima_trg('outsider', 'frmdown');
    this.padawanstep = 0; 

    this.gsap.anima_trg('cronos', '');
    
    if (l_response.result) {
      this.gsap.anima_trg('outsider', 'granted');
      this.haylie.send_game("register", "3").then(this.catch_game);

      setTimeout(() => {
        this.padawanstep = 0;
        this.frmPadawanName.nativeElement.value = '';
        this.frmPadawanMail.nativeElement.value = '';
        this.frmPadawanPhone.nativeElement.value = '';
        this.padawanphoto = 0

        //this.switchScreens('Login');
        this.gsap.outsider_pages("Login")
      }, 6300);
    } 
    else {
      this.gsap.anima_trg('outsider', 'denied2');
      
      setTimeout(() => this.gsap.outsider_pages("Register"), 1800);
    }
    
  }
  goHaylie(p_action:string): void{

    switch (p_action) { 
      case "logmein":
        this.frmcheck = true;

        if (this.frmLoginMail.nativeElement.value.length <= 0)
        { this.frmcheck = false; console.log('loginerror> missing mail'); }
        else  
          this.lmail = this.frmLoginMail.nativeElement.value;
        
        if (this.frmLoginKey.nativeElement.value.length <= 0 || this.angel.outsider_key(true, '').length <= 0)
        { this.frmcheck = false; console.log('loginerror> missing key'); }
        else  
          this.lkey = this.frmLoginKey.nativeElement.value;

        if (this.frmcheck) {
          this.gsap.anima_trg('cronos', 'showup');

          console.log('logmein> ' + this.lmail + " . " + this.angel.outsider_key(true, ''));
          this.haylie.outsider_logmein(this.lmail, this.angel.outsider_key(true, '')).then(this.catch_login);
        }  
        else 
          this.gsap.anima_trg('outsider', 'denied');

        break;

      case "register":
        this.frmcheck = true;

        if (this.frmPadawanName.nativeElement.value.length <= 0)
        { this.frmcheck = false; console.log('padawanerror> missing name'); }
        else  
          this.padawan = this.frmPadawanName.nativeElement.value;
        
        if (this.frmPadawanMail.nativeElement.value.length <= 0)
        { this.frmcheck = false; console.log('padawanerror> missing mail'); }
        else  
          this.padawanmail = this.frmPadawanMail.nativeElement.value;
        
        if (this.frmPadawanPhone.nativeElement.value.length <= 0)
        { this.frmcheck = false; console.log('padawanerror> missing phone'); }
        else  
          this.padawanphone = this.frmPadawanPhone.nativeElement.value;
        
        if (this.haylie.outsider_photo(true, 0) <= 0)
        { this.frmcheck = false; console.log('padawanerror> missing photo'); }
        else  
          this.padawanphoto = this.haylie.outsider_photo(true, 0);

        if (this.frmcheck) {
          this.haylie.outsider_letmein(this.padawan, this.padawanmail, this.padawanphone, "0", this.padawanphoto.toString(), "").then(this.catch_register);
          
        }  
        else 
          this.gsap.anima_trg('outsider', 'denied');
        
        
          
        console.log(p_action + " padawan>>>" + this.frmPadawanName.nativeElement.value)

        break;

      case "bringmeback":
        this.frmcheck = true;

        if (this.frmRecoverMail.nativeElement.value.length <= 0)
        { this.frmcheck = false; console.log('bringmeback> missing mail'); }
        else  
          this.lghost = this.frmRecoverMail.nativeElement.value;

        if (this.frmcheck) {
          this.gsap.anima_trg('cronos', 'showup');
          console.log('bringmeback> ' + this.lghost);
          this.haylie.outsider_bringmeback(this.lghost).then(this.catch_bringmeback);
        }  
        else 
          this.gsap.anima_trg('outsider', 'denied');

        break;
    }
    
    //this.gsap.anima_trg('outsider', p_action);

  }
  switchSteps(p_next:boolean):void {
    this.padawanstep += p_next ? (+1) : (-1);
    
    if (this.padawanstep > 0) {
      if (this.padawanstep > 3)
        this.goHaylie('register');
      else {
        this.gsap.anima_trg('outsider', 'outregstep' + this.padawanstep.toString());
        this.btnregisterlabel = this.padawanstep == 3 ? "ENVIAR" : "CONTINUAR";
      }
    }
    else 
    {
      this.gsap.anima_trg('outsider', 'frmdown');
      this.padawanstep = 0; 
      this.switchScreens('Login');
      this.frmLoginMail.nativeElement.focus();
    }
    //this.gsap.outsider_pages(p_page);
  }
  switchScreens(p_page:string):void {
    if (p_page.toLowerCase() == "register") 
    { 
        this.padawanstep = 1;
        this.termsverb = "cadastrar";
        this.gsap.anima_trg('outsider', 'frmup');

    }
    else 
    {
      this.padawanstep = 0;
      this.termsverb = "logar";
      this.gsap.anima_trg('outsider', 'frmdown');
      this.frmlocked = false; 
      this.switchKeys();
      this.frmLoginMail.nativeElement.focus();
    }
    this.gsap.outsider_pages(p_page);
  }
  switchKeys():void {
    this.frmlocked = !this.frmlocked; 
    var destiny = this.frmlocked ? "frmlock" : "frmunlock";
    console.log("switchKeys-destiny> " + destiny);
    this.gsap.anima_trg('outsider', destiny);

    //if (this.frmlocked) this.frmLoginKey.nativeElement.focus();
    //else this.frmLoginKey2.nativeElement.focus();
  }
  mirrorKey(p_key:any):void {
    if (p_key.length <= 0) return;
    
    this.angel.outsider_key(false, p_key.target.value);

    this.frmLoginKey.nativeElement.value = p_key.target.value;
    this.frmLoginKey2.nativeElement.value = p_key.target.value; 
    //this.frmLoginMail.nativeElement.value = this.frmLoginMail.nativeElement.value;

  }

}
