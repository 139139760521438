<div id="page_plans">
    <div id="box-pages" class="block_pages">
        <div class="head">
            <fa-icon [icon]="FAIconHead" class="pageicon"></fa-icon>
            <h1 class="title">PLANOS</h1>
            <br/>
            <p class="total" *ngIf="model_plans.length > 0">{{ model_plans.length }} registros</p>
        </div>

        <div class="window" style="opacity: 1;">
            <div *ngIf="model_plans.length <= 0" class="empty"><br/><br/><br/>
                <p style="text-align: center;color: #ccc;">Nenhum registro encontrado</p>
            </div>
            <ul *ngIf="model_plans" style="padding-bottom: 9px;opacity: 1;" >
                <li id="phome-liplanbox{{ reg.id }}" *ngFor="let reg of model_plans" class="content box alivebg{{ reg.alive }}"  style="opacity: 1;padding-bottom: 9px;">
                    <div id="planbox{{ reg.id }}" class="alertbox" style="position: relative;">
                        <img class="boxicon" style="width: 34px;top: 18px; left:9px;position: absolute;"
                            src="{{ '../assets/img/categs/' + reg.skill + '.png' }}" />
                        <h4 style="font-weight: 600; padding: 18px 0 9px 0;">{{ reg.name }}</h4>
                        <p style="line-height: 25px;">
                            <!-- <b>{{ reg.txt  }}</b><br/> -->
                            {{ reg.type  }}<br/>
                            R$ {{ reg.price  }}</p>
                        
                        <a *ngIf="reg.alive == 1 && lhaylie.player_permission(true, 0) == 1" class="btn_check fix" 
                            style="float: right;background: #0095f6;padding: 3px;margin-right: 9px;"
                            (click)="goHaylie('edit', reg.id.toString())">
                            <fa-icon [icon]="frmFAIcon" class="pageicon"
                            style="font-size: 18px; display: block; margin: 9px;  
                                    text-align: left; color: #fff;"></fa-icon>
                        </a>
                        
                        <a *ngIf="reg.alive == 1" class="btn_check fix" 
                            style="float: right;background: #333; color: #999999;padding: 3.4px 0;" 
                            (click)="goHaylie('list', reg.id.toString())">
                            <fa-icon [icon]="FAIconMembers" class="ico"></fa-icon> 
                        </a>
                        <!-- <a *ngIf="reg.alive == 1" class="btn_check fix" style="float: right;background: #999999;" 
                            (click)="goHaylie('delete', reg.id.toString())">
                            <fa-icon [icon]="FAIconDelete" class="ico"></fa-icon> 
                        </a> -->

                        <span class="srt">modalidade: {{ reg.skill  }}</span>
                        <span class="count" style="
                                            position: absolute;
                                            left: 9px;
                                            bottom: -45px;
                                            font-size: 12px;
                                            font-family: arial;
                                            background: #fe0;
                                            padding: 9px;
                                        ">{{ reg._count }} membros</span>
                    </div>
                </li>
            </ul>

            <div id="frmplan" *ngIf="page_frm" class="content box"
                style="opacity: 1; position: absolute;top: 90px;box-shadow: 1px 2px 2px #000;">
                
                <fa-icon [icon]="frmFAIcon" class="pageicon"
                        style="font-size: 18px; display: block; margin: 9px;  
                                text-align: left; color: #666;"></fa-icon>
                <h1 class="srt" style="top: 16px; left: 34px;">PLANO | DETALHES</h1>
                <p class="sub" id="msgbox"></p>
                <br/>
                <div class="frm">
                    <input class="inputtxt" type="text" #plan_name name="name" 
                        value="{{ model_frm_name }}" placeholder="Nome" />
                    
                    <!--- skill --->
                    <input #plan_skill class="inputtxt" type="text" 
                        placeholder="Categoria" list="plan_skills"
                        value="{{ model_frm_skill }}"
                     />
                    <datalist id="plan_skills">
                        <option value="jiu">Jiu Jitsu</option>
                        <option value="thay">Muay Thay</option>
                        <option value="boxe">Boxe</option>
                        <option value="todas">Ninja</option>
                    </datalist>
                    <!--- type --->
                    <input #plan_type class="inputtxt" type="text" 
                        placeholder="Tipo" list="plan_types"
                        value="{{ model_frm_type }}"
                     />
                    <datalist id="plan_types">
                        <option value="semanal">Semanal</option>
                        <option value="mensal">Mensal</option>
                        <option value="trimestral">Trimestral</option>
                        <option value="semestral">Semestral</option>
                        <option value="anual">Anual</option>
                    </datalist>
                    <!--- price --->
                    <input class="inputtxt" type="number" #plan_price name="price" 
                        value="{{ model_frm_price }}" placeholder="Valor" />
                    
                    <button class="btn-splatter" (click)="goHaylie('frm', '0')"
                        style="top: 63px; font-size: 9px; letter-spacing: 3px;">
                        SALVAR</button>
                    <a class="btnReturn" (click)="switchFrm('off')">
                        VOLTAR</a>

                    <a class="btnrectransp" *ngIf="player_permission == 1 && model_frm_id > 0" 
                        (click)="goHaylie('delete', '')" 
                        style="color: #0095f6; border: 1px solid;top: 90px;position: relative;">
                        REMOVER PLANO</a>
                </div>
            </div>
            
        </div> 

        <a id="btn_frm" class="fixed_action" 
            *ngIf="player_permission == 1"
            (click)="switchFrm('on')">
            <fa-icon [icon]="FAIconPlus" class="ico"></fa-icon>
        </a>
    </div>

</div>