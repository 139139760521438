import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faFileContract, faPlus, faUsers } from '@fortawesome/free-solid-svg-icons';

import { eOrder } from '../services/entities'
import { GsapService } from '../services/gsap.service'
import { HaylieService } from '../services/haylie.service'
import { AngelService } from '../services/angel.service'

@Component({
  selector: 'app-page-orders',
  templateUrl: './page-orders.component.html',
  styleUrls: ['./page-orders.component.css']
})
export class PageOrdersComponent implements OnInit {
pageFAIcon = faUsers;
FAIconPlus = faPlus;
FAIconOrder = faFileContract;
player_live = false;
model_orders: eOrder[] = [];
model_ordersorg: eOrder[] = [];
page_frm = false;

@Input() activated: boolean = false;
  
  constructor(private angel: AngelService, 
    private gsap: GsapService, private haylie: HaylieService, private router: Router) 
  { 
    
  }
  ngOnChanges() {
    if (this.activated) { this.content_refresh(); console.log('---changesrun.team'); }
  }
  content_refresh() {
    this.gsap.anima_trg('cronos', 'showup');
    this.page_frm = false;

    if (this.haylie.player_token(true, '').length > 0) {
      console.log('player> ' + this.haylie.player_token(true, ''));
      this.get_orders();

      this.player_live = true;
    }
    else {
      this.player_live = false; 

      this.gsap.magic_trg("#outsider_wrapper", "build");
      this.router.navigateByUrl('octalogin');
    }
  }
  ngOnInit(): void {
    
  }
  get_orders(): void {
    this.model_orders = [];
    this.model_ordersorg = [];

    this.haylie.get_orders("1").then(this.catch_orders);
  }
  /*compare( a: ePadawan, b: ePadawan) {
    if ( a._name < b._name ){
      return -1;
    }
    if ( a._name > b._name ){
      return 1;
    }
    return 0;
  }*/
  private catch_orders = (p_response: any): void =>  {
    var l_response = JSON.parse(p_response);
    console.log('catch.orders> ' + l_response.orders);

    if (l_response.orders.length > 0) {
      this.gsap.anima_trg('cronos', '');
      this.model_orders = l_response.orders;
      this.model_ordersorg = this.model_orders;
    } 
    
  }
  goBack():void {
    this.router.navigateByUrl("octahome");
  }
  goHaylie(p_action: string, p_target: string): void{
    console.log(p_action + ">" + p_target);

    /*switch(p_action) {
      case "profile":
        this.angel.profile_token(false, p_target);
        this.angel.player_compass(false, p_action);
        this.gsap.player_pages(p_action);
      break;

      case "gomsgs":
        console.log('gomsgs-loguser> ' + p_target); 
        this.angel.player_target(false, p_target);
        let buddy = this.model_padawansorg.filter(x => x.id == p_target); //duhflag
        this.angel.player_padawan(false, buddy[0]);   
        this.angel.player_compass(false, "msgs");
        this.gsap.player_pages("msgs");
      break;
    }*/

  }

  searchList(p_key:any):void {
    console.log('--- search key --- >>> ' + p_key);
    this.model_orders = [];
    if (p_key.target.value.length > 0)
      this.model_orders = this.model_ordersorg.filter(x => x._client._profile.name.toLowerCase().indexOf(p_key.target.value.toLowerCase()) >= 0);
    else 
      this.model_orders = this.model_ordersorg;

  }
  

}
