import { Component, OnInit, Input } from '@angular/core';
import { faGem, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { eReport } from '../services/entities' 
import { GsapService } from '../services/gsap.service';
import { HaylieService } from '../services/haylie.service';
import { MorganaService } from '../services/morgana.service';


@Component({
  selector: 'app-page-dash',
  templateUrl: './page-dash.component.html',
  styleUrls: ['./page-dash.component.css']
})
export class PageDashComponent implements OnInit {
  model_dash : eReport[] = [];
  model_rptleads : eReport[] = [];
  model_rptagenda : eReport[] = [];
  FAIconDash = faChartLine;
  lmorgana : any; 

  @Input() activated: boolean = false;

  constructor(private gsap: GsapService, private haylie: HaylieService, 
    private morgana: MorganaService) { this.lmorgana = morgana; }

  

  private catch_response = (p_response: any) => {
    var l_response = JSON.parse(p_response);

    if (l_response.report.length > 0) { 
      this.model_dash = l_response.report;
      this.model_rptleads = l_response.rptleads;
      this.model_rptagenda = l_response.rptagenda;
    }
    else console.log("dash.catch-error");
  }
  content_refresh() {
    this.gsap.anima_trg('cronos', 'showup');
    this.haylie.get_dash().then(this.catch_response)
  }
  ngOnChanges() { if (this.activated) this.content_refresh(); }
  ngOnInit(): void {



  }
  /*
  _vm.count_jiu = listfull.Where(reg => reg.skill == "jiu").ToList().Count();
  _vm.count_jiu_distinct = listfull.Where(reg => reg.skill == "jiu").ToList().Select(reg => reg.padawan).Distinct().ToList().Count;
  _vm.count_thay = listfull.Where(reg => reg.skill == "thay").ToList().Count();
  _vm.count_thay_distinct = listfull.Where(reg => reg.skill == "thay").ToList().Select(reg => reg.padawan).Distinct().ToList().Count;
  _vm.count_boxe = listfull.Where(reg => reg.skill == "boxe").ToList().Count();
  _vm.count_boxe_distinct = listfull.Where(reg => reg.skill == "boxe").ToList().Select(reg => reg.padawan).Distinct().ToList().Count;

  */

}
