import { Injectable } from '@angular/core';

import { HttpClient, HttpResponse, HttpHeaders, ɵangular_packages_common_http_http_e } from '@angular/common/http'
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { eClass, ePlan, eAlert, eMedia, eLead, eAgenda, eQuote, eMsg, eCateg } from './entities'
import { CookieService } from './cookie.service'
import { AngelService } from './angel.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }), 
  mode: 'no-cors'
};

@Injectable({
  providedIn: 'root'
})
export class HaylieService {
  shootUrl = '';

  server = 'http://haylie.bosswebapps.net/';
  endpoint_media_gallery = 'api/content/getgallery?p_bot=wanda&p_filter=gallery&p_param=clientrequest_nf';
  endpoint_media_promo = 'api/content/getgallery?p_bot=wanda&p_filter=promo&p_param=clientrequest_nf';
  
  endpoint_player_logmein = 'api/player/logmein?p_bot=wanda';
  endpoint_player_bringmeback = 'api/player/bringmeback?p_bot=wanda';
  endpoint_player_reanimation = 'api/player/reanimation?p_bot=wanda';
  endpoint_player_letmein = 'api/player/letmein?p_bot=wanda&p_param=clientrequest_nf';
  endpoint_player_oneofus = 'api/player/oneofus?p_bot=wanda'
  endpoint_player_key = 'api/player/super?p_bot=wanda';
  endpoint_player_avatar = 'api/player/setavatar?p_bot=wanda';
  endpoint_player_review = 'api/player/review?p_bot=wanda';

  endpoint_content_imgup = 'api/content/up?p_bot=wanda';
  endpoint_content_kill = 'api/content/kill?p_bot=wanda';
  endpoint_content_update = 'api/content/update?p_bot=wanda';
  endpoint_content_profile = 'api/content/getprofile?p_bot=wanda';
  
  endpoint_leads_set = 'api/player/lead?p_bot=wanda';
  endpoint_leads_get = 'api/content/getleads?p_bot=wanda&p_param=';

  endpoint_agenda_gettoday = 'api/agenda/gettoday?p_bot=wanda';
  endpoint_agenda_get = 'api/agenda/getall?p_bot=wanda';
  endpoint_agenda_set = 'api/agenda/save?p_bot=wanda&';

  endpoint_class_gettoday = 'api/class/gettoday?p_bot=wanda';
  endpoint_class_get = 'api/class/getall?p_bot=wanda';
  endpoint_class_set = 'api/class/save?p_bot=wanda';

  //endpoint_quotes_set = 'api/content/lead?p_bot=wanda';
  endpoint_quotes_get = 'api/content/getquotes?p_bot=wanda';
  
  endpoint_msgs_get = 'api/content/getmsgs?p_bot=wanda';
  endpoint_msgs_set = 'api/player/courier?p_bot=wanda';

  endpoint_alerts_get = 'api/alert/getall?p_bot=wanda&p_param=';
  endpoint_alerts_set = 'api/alert/save?p_bot=wanda';
  
  endpoint_plans_set = 'api/plan/save?p_bot=wanda';
  endpoint_plans_get = 'api/plan/getall?p_bot=wanda&p_param=';

  endpoint_padawans_get = 'api/content/getpadawans?p_bot=wanda&p_param='; //+playertoken
  
  endpoint_partners_get = 'api/partner/getall?p_bot=wanda&p_param='; //+playertoken
  endpoint_partners_set = 'api/partner/save?p_bot=wanda';

  endpoint_game_get = 'api/game/getall?p_bot=wanda&p_param='; //+playertoken
  endpoint_game_set = 'api/game/save?p_bot=wanda';

  endpoint_categs_get = 'api/categ/getall?p_bot=wanda&p_param=';

  endpoint_orders_get = 'api/categ/getall?p_bot=wanda&p_param=';

  endpoint_products_get = 'api/product/getall?p_bot=wanda&p_param='; //+playertoken
  
  endpoint_reports_dash = 'api/content/getdash?p_bot=wanda';

  endpoint_docker_node = 'http://localhost:5000/';
  endpoint_docker_node2 = 'http://localhost:5002/';

  constructor(private http: HttpClient, 
            private cookie: CookieService, private angel: AngelService) { }

  
  /*=== +++ player +++ =========================================================== */
  logout() {
    this.cookie.deleteCookie("player_id");
    this.cookie.deleteCookie("player_token");
    this.cookie.deleteCookie("player_avatar");
    this.cookie.deleteCookie("player_permission");
  }
  player_compass(p_get: boolean, p_compass: string) {

    return this.angel.player_compass(p_get, p_compass);
  }

  private l_player_id = 0; 
  player_id(p_get: boolean, p_id: number) {
    if (!p_get)
      this.l_player_id = p_id;

    if (this.cookie.getCookie("player_id").length <= 0 && !p_get)
    {
      this.cookie.setCookie("player_id", p_id.toString(), 1)
    
      console.log('cookie_token> ' + this.cookie.getCookie("player_id"));
    }
    else this.l_player_id = parseInt(this.cookie.getCookie("player_id"));
  
    return this.l_player_id;
  }
  private l_player_token = ""; 
  player_token(p_get: boolean, p_token: string) {
    if (!p_get)
      this.l_player_token = p_token;

    //console.log('haylie_player_token> ' + this.l_player_token);

    if (this.cookie.getCookie("player_token").length <= 0 && !p_get)
    {
      this.cookie.setCookie("player_token", p_token, 1)
    
      console.log('cookie_token> ' + this.cookie.getCookie("player_token"));
    }
    else this.l_player_token = this.cookie.getCookie("player_token");
  //---> logout = this.cookie.deleteCookie("player_token")
  
    return this.l_player_token;
  }
  private l_player_avatar = ""; 
  player_avatar(p_get: boolean, p_avatar: string) {
    if (!p_get)
      this.l_player_avatar = p_avatar;

    console.log('l_player_avatar> ' + this.l_player_avatar);

    if (!p_get)
    {
      this.cookie.setCookie("player_avatar", p_avatar, 1)
    
      console.log('cookie_avatar> ' + this.cookie.getCookie("player_avatar"));
    }

    return this.l_player_avatar;
  }
  private l_player_permission = 0; 
  player_permission(p_get: boolean, p_permission: number) {
    if (!p_get)
      this.l_player_permission = p_permission;

    //console.log('l_player_permission> ' + this.l_player_permission);

    if (this.cookie.getCookie("player_permission").length <= 0 && p_permission > 0)
    {
      this.cookie.setCookie("player_permission", p_permission.toString(), 1)
    
      console.log('cookie_permission> ' + this.cookie.getCookie("player_permission"));
    }

    return this.l_player_permission;
  }

  player_locker(p_target:string, p_token: string = ''): Promise<any> {
    let l_params = "&p_param=" + p_target + "&p_token=" + (p_token.length <= 0 ? this.player_token(true, '') : p_token);
    this.shootUrl = this.server + this.endpoint_player_key + l_params;

    return this.http
    .get<any>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }

  player_setavatar(p_target:string): Promise<any> {
    let l_params = "&p_param=" + p_target + "&p_token=" + this.player_token(true, '');
    this.shootUrl = this.server + this.endpoint_player_avatar + l_params;

    return this.http
    .get<any>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }

  player_review(p_name:string, p_mail:string, p_phone:string, p_plan:string, p_since:string): Promise<any> {
    let l_params = "&p_name=" + p_name + "&p_mail=" + p_mail;
    l_params = l_params + "&p_phone=" + p_phone + "&p_plan=" + p_plan;
    l_params = l_params + "&p_init=" + p_since + "&p_param=" + this.angel.profile_token(true, '');
    this.shootUrl = this.server + this.endpoint_player_review + l_params;

    return this.http
    .get<any>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  
  /*=== +++ outsider +++ =========================================================== */

  outsider_bringmeback(p_mail: string):Promise<any> {

    var l_params = "&p_mail=" + p_mail;
    
    this.shootUrl = this.server + this.endpoint_player_bringmeback + l_params;
    
    let trythis = this.http
    .get(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise();
  
    console.log('r.bringmeback> ' + trythis);
  
    return trythis;
  }

  outsider_logmein(p_user: string, p_key:string):Promise<any> {

    var l_params = "&p_user=" + p_user + "&p_key=" + p_key;
    
    this.shootUrl = this.server + this.endpoint_player_logmein + l_params;
    
    let trythis = this.http
    .get(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise();
  
    console.log('r.logmein> ' + trythis);
  
    return trythis;
  }
  outsider_letmein(p_name: string, p_mail:string, p_phone:string, p_plan:string, p_mediaid:string, p_init:string):Promise<any> {

    var l_params = "&p_name=" + p_name + "&p_plan=" + p_plan + "&p_mail=" + p_mail + "&p_phone=" + p_phone + "&p_avatar=" + p_mediaid + "&p_init=" + p_init;
    
    this.shootUrl = this.server + this.endpoint_player_letmein + l_params;
    
    let trythis = this.http
    .get(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise();
  
    console.log('r.signup> ' + trythis);
  
    return trythis;

  }

  private l_padawan_mediaid = 0; 
  outsider_photo(p_get: boolean, p_mediaid: number) {
    if (!p_get)
      this.l_padawan_mediaid = p_mediaid;

    console.log('l_padawan_mediaid> ' + this.l_padawan_mediaid);

    return this.l_padawan_mediaid;
  }
  outsider_hi(p_img: File): Observable<any>{

    const formData = new FormData();
    formData.append('image', p_img);

    this.shootUrl = this.server + this.endpoint_content_imgup + "&p_org=padawan";
    
    return this.http.post(this.shootUrl, formData);
  }
  /*===------- get ------------------------------------------ ===*/ 
  get_docker(): Promise<any> {
    //let l_params = "&p_param=" + this.player_token(true, '');
    this.shootUrl = this.endpoint_docker_node;

    return this.http
    .get<any>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.message)
    )
    .toPromise()

  }
  get_docker2(): Promise<any> {
    //let l_params = "&p_param=" + this.player_token(true, '');
    this.shootUrl = this.endpoint_docker_node2;

    return this.http
    .get<any>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.message)
    )
    .toPromise()

  }

  get_dash(): Promise<any> {
    let l_params = "&p_param=" + this.player_token(true, '');
    this.shootUrl = this.server + this.endpoint_reports_dash + l_params;

    return this.http
    .get<any>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  get_profile(p_target:string): Promise<any> {
    //let l_params = "&p_id=" + p_id + "&p_param=" + this.player_token(true, '');
    let l_params = "&p_param=" + p_target;
    this.shootUrl = this.server + this.endpoint_content_profile + l_params;

    return this.http
    .get<any>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()
  }
    
  get_partners(p_alive:string): Promise<any[]> {
    //let l_params = this.player_token(true, '') + "&p_alive=" + p_alive;
    let l_params = this.player_token(true, '');
    this.shootUrl = this.server + this.endpoint_partners_get + l_params;

    return this.http
    .get<any[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }

  get_game(p_alive:string): Promise<any[]> {
    //let l_params = this.player_token(true, '') + "&p_alive=" + p_alive;
    let l_params = this.player_token(true, '');
    this.shootUrl = this.server + this.endpoint_game_get + l_params;

    return this.http
    .get<any[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  
  get_products(p_alive:string): Promise<any[]> {
    //let l_params = this.player_token(true, '') + "&p_alive=" + p_alive;
    let l_params = this.player_token(true, '');
    this.shootUrl = this.server + this.endpoint_products_get + l_params;

    return this.http
    .get<any[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  get_orders(p_alive:string): Promise<any[]> {
    //let l_params = this.player_token(true, '') + "&p_alive=" + p_alive;
    let l_params = this.player_token(true, '');
    this.shootUrl = this.server + this.endpoint_orders_get + l_params;

    return this.http
    .get<any[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  get_padawans(p_alive:string): Promise<any[]> {
    let l_params = this.player_token(true, '') + "&p_alive=" + p_alive;
    this.shootUrl = this.server + this.endpoint_padawans_get + l_params;

    return this.http
    .get<any[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  get_classes(p_day:string): Promise<any[]> {
    let l_params = "&p_param=" + this.player_token(true, '');
    this.shootUrl = this.server + this.endpoint_class_get + l_params;

    return this.http
    .get<any[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  get_classestoday(p_day:string): Promise<any[]> {
    let l_params = "&p_param=" + this.player_token(true, '');
    this.shootUrl = this.server + this.endpoint_class_gettoday + l_params;

    return this.http
    .get<any[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  get_alerts(p_alive:string): Promise<eAlert[]> {
    let l_params = this.player_token(true, '') + "&p_alive=" + p_alive;
    this.shootUrl = this.server + this.endpoint_alerts_get + l_params;

    return this.http
    .get<eAlert[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  get_msgs(p_alive:string, p_filter:string): Promise<eMsg[]> {
    let l_params = "&p_param=" + this.player_token(true, '') + "&p_alive=" + p_alive;
    this.shootUrl = this.server + this.endpoint_msgs_get + l_params + "&p_filter=" + p_filter;

    return this.http
    .get<eMsg[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  get_quotes(p_alive:string): Promise<eQuote[]> {
    let l_params = "&p_param=" + this.player_token(true, '') + "&p_alive=" + p_alive;
    this.shootUrl = this.server + this.endpoint_quotes_get + l_params;

    return this.http
    .get<eQuote[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  get_leads(p_alive:string): Promise<eLead[]> {
    let l_params = this.player_token(true, '') + "&p_alive=" + p_alive;
    this.shootUrl = this.server + this.endpoint_leads_get + l_params;

    return this.http
    .get<eLead[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  get_plans(): Promise<ePlan[]> {
    let l_params = this.player_token(true, '');
    this.shootUrl = this.server + this.endpoint_plans_get + l_params;

    return this.http
    .get<eLead[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  get_categs(): Promise<eCateg[]> {
    let l_params = this.player_token(true, '');
    this.shootUrl = this.server + this.endpoint_categs_get + l_params;

    return this.http
    .get<eCateg[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  get_agenda(): Promise<eAgenda[]> {

    this.shootUrl = this.server + this.endpoint_agenda_get + "&p_param=" + this.player_token(true, '');;

    return this.http
    .get<eAgenda[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  get_agenda_today(): Promise<eAgenda[]> {

    this.shootUrl = this.server + this.endpoint_agenda_gettoday + "&p_param=" + this.player_token(true, '');

    return this.http
    .get<eAgenda[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  get_gallery(): Promise<eMedia[]> {
    this.shootUrl = this.server + this.endpoint_media_gallery;

    return this.http
    .get<eMedia[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }
  get_promo(): Promise<eMedia[]> {
    this.shootUrl = this.server + this.endpoint_media_promo;

    return this.http
    .get<eMedia[]>(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise()

  }

  send_gallery(p_img: File): Observable<any>{

    const formData = new FormData();
    formData.append('image', p_img);

    this.shootUrl = this.server + this.endpoint_content_imgup + "&p_org=gallery";
    
    return this.http.post(this.shootUrl, formData);
  }
  send_high(p_img: File): Observable<any>{

    const formData = new FormData();
    formData.append('image', p_img);

    this.shootUrl = this.server + this.endpoint_content_imgup + "&p_org=promo";
    
    return this.http.post(this.shootUrl, formData);
  }
  send_avatar(p_img: File): Observable<any>{

    const formData = new FormData();
    formData.append('image', p_img);

    this.shootUrl = this.server + this.endpoint_content_imgup + "&p_org=padawan";
    
    return this.http.post(this.shootUrl, formData);
  }
  kill_content(p_what: string, p_id: string): Observable<any>{

    this.shootUrl = this.server + this.endpoint_content_kill + "&p_what=" + p_what + "&p_id=" + p_id + "&p_param=" + this.player_token(true, "");  
   
    return this.http.post(this.shootUrl, httpOptions);
  }
  update_media(p_what: string, p_id: string): Observable<any>{

    this.shootUrl = this.server + this.endpoint_content_update + "&p_what=" + p_what + "&p_id=" + p_id + "&p_param=" + this.player_token(true, "");  
   
    return this.http.post(this.shootUrl, httpOptions);
  }
  player_check(p_target: string, p_alive: string): Observable<any>{
    this.shootUrl = this.server + this.endpoint_player_oneofus
     + "&p_target=" + p_target + "&p_alive=" + p_alive + "&p_param=" + this.player_token(true, "");  
   
    return this.http.post(this.shootUrl, httpOptions);
  }
  
  send_agenda(p_agenda: eAgenda):Promise<any> {
    ///api/agenda/save?p_bot=wanda&p_skill=thay&p_dateto=2020-12-07&p_time=1930&p_padawan=Sample&p_device=2&p_param=inject
    var l_params = "p_skill=" + p_agenda.skill + "&p_dateto=" + p_agenda.dateto;
    l_params += "&p_time=" + p_agenda.time + "&p_padawan=" + p_agenda.padawan;
    l_params += "&p_device=9&p_param=" + this.player_token(true, "");

    this.shootUrl = this.server + this.endpoint_agenda_set + l_params;
    
    let trythis = this.http
    .post(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise();

    console.log('agenda test>' + trythis);
    return trythis;
  }

  send_partner(p_id:any, p_company:any, p_cnpj:any, p_name:any, p_mail:any, p_phone:any) {
    var l_params = "&p_id=" + p_id;
    l_params += "&p_company=" + p_company.toString();
    l_params += "&p_cnpj=" + p_cnpj.toString();
    l_params += "&p_name=" + p_name.toString();
    l_params += "&p_mail=" + p_mail.toString();
    l_params += "&p_phone=" + p_phone.toString();
    l_params += "&p_param=" + this.player_token(true, "");

    this.shootUrl = this.server + this.endpoint_partners_set + l_params;
    
    let trythis = this.http
    .post(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise();

    console.log('plans test>' + trythis);
    return trythis;
  }
  
  send_alert(p_alert: eAlert):Promise<any> {
    ///p_bot=wanda&p_id=0&p_title=Teste&p_txt=Exemplo&p_plan=jiu&p_param=1c7af30c-
    var l_params = "&p_id=" + p_alert.id + "&p_title=" + p_alert.title;
    l_params += "&p_txt=" + p_alert.msg.toString();
    l_params += "&p_plan=" + p_alert.id_plan.toString();
    l_params += "&p_idtype=" + p_alert.id_type.toString();
    l_params += "&p_param=" + this.player_token(true, "");

    this.shootUrl = this.server + this.endpoint_alerts_set + l_params;
    
    let trythis = this.http
    .post(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise();

    console.log('plans test>' + trythis);
    return trythis;
  }

  send_plan(p_plan: ePlan):Promise<any> {
    ///p_bot=wanda&p_id=0&p_name=Teste&p_txt=Exemplo&p_skill=jiu&p_price=420&p_type=mensal&p_param=1c7af30c-
    var l_params = "p_skill=" + p_plan.skill + "&p_id=" + p_plan.id;
    l_params += "&p_name=" + p_plan.name;
    l_params += "&p_type=" + p_plan.type + "&p_txt=teste";
    l_params += "&p_price=" + p_plan.price + "&p_param=" + this.player_token(true, "");

    this.shootUrl = this.server + this.endpoint_plans_set + l_params;
    
    let trythis = this.http
    .post(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise();

    console.log('plans test>' + trythis);
    return trythis;
  }

  send_class(p_obj: eClass):Promise<any> {
    ///api/agenda/save?p_bot=wanda&p_skill=thay&p_dateto=2020-12-07&p_time=1930&p_padawan=Sample&p_device=2&p_param=inject
    var l_params = "&p_id=" + p_obj.id + "&p_skill=" + p_obj.skill + "&p_day=" + p_obj.day + "&p_time=" + p_obj.time;
    l_params += "&p_param=" + this.player_token(true, "");

    this.shootUrl = this.server + this.endpoint_class_set + l_params;
    
    let trythis = this.http
    .post(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise();

    console.log('msg test>' + trythis);
    return trythis;
  }
  send_msg(p_msg: string):Promise<any> {
    ///api/agenda/save?p_bot=wanda&p_skill=thay&p_dateto=2020-12-07&p_time=1930&p_padawan=Sample&p_device=2&p_param=inject
    var l_params = "&p_msg=" + p_msg;
    l_params += "&p_target=" + this.angel.player_target(true, "");
    l_params += "&p_param=" + this.player_token(true, "");

    this.shootUrl = this.server + this.endpoint_msgs_set + l_params;
    
    let trythis = this.http
    .post(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise();

    console.log('msg test>' + trythis);
    return trythis;
  }
  send_game(p_title: string, p_type: string):Promise<any> {
    ///api/game/save?p_bot=wanda& p_id, int p_amount, string p_title, int p_type, string p_param
    var l_params = "&p_title=" + p_title;
    l_params += "&p_id=0&p_amount=1";
    l_params += "&p_type=" + p_type;
    l_params += "&p_param=" + this.player_token(true, "");

    this.shootUrl = this.server + this.endpoint_game_set + l_params;
    
    console.log("gamesend> " + this.shootUrl);

    let trythis = this.http
    .post(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise();

    console.log('msg test>' + trythis);
    return trythis;
  }
  /*
  
  send_lead(p_lead: eLead):void {

    var l_params = "&p_name=" + p_lead.name + "&p_birth=" + p_lead.birth + "&p_mail=" + p_lead.mail + "&p_phone=" + p_lead.phone;
    
    this.shootUrl = this.server + this.endpoint_leads_set + l_params;
    
    let trythis = this.http
    .get(this.shootUrl, httpOptions)
    .pipe(
      map((response: any) => response.Value)
    )
    .toPromise();

    console.log('lead test>' + trythis);

  }*/

}
