<div id="frmnewguy" class="frm" *ngIf="frmoption=='register'">
    <div id="frmmember" class="content box"
    style="opacity: 1; position: absolute;top: 0px;box-shadow: 1px 2px 2px #000;">
        
        <fa-icon [icon]="frmFAIcon" class="pageicon"
        style="font-size: 18px; display: block; margin: 9px;  
                text-align: left; color: #333;"></fa-icon>
        <h1 class="srt" style="top: 16px; left: 34px;">NOVO MEMBRO</h1>
        <p class="sub" id="msgbox"></p>
        <br/>
        <div class="frm" style="padding-bottom: 63px;">

            <app-comp-imgup [activated]="activated" style=""></app-comp-imgup>
            
            <input class="inputtxt" type="text" #newguy_name name="name" value="" placeholder="Nome" />

            <input class="inputtxt" type="number" maxlength="11" 
                #newguy_phone name="phone" value="" placeholder="Telefone" />

            <input class="inputtxt" type="text" #newguy_mail name="mail" value="" placeholder="Email" />
<!-- 
            <input class="inputtxt" type="text" #newguy_birth name="birth" value="" placeholder="Aniversário" /> -->

            <select #newguy_plan class="inputtxt" placeholder="PLANO">
                <option value="">Plano</option>
                <option *ngFor="let p of model_plans" [value]="p.id">{{ p.name }}</option>
            </select>

            <input class="inputtxt" type="text" #newguy_init name="inicio" 
                value="{{ model_since | date: 'dd/MM/yyyy' }}" placeholder="Início" />
            
            <button class="btn-splatter" (click)="goHaylie('register')" 
                style="top: 63px; font-size: 9px; letter-spacing: 3px;">
                SALVAR</button>
            <a class="btnReturn" (click)="goHaylie('back')" >
                VOLTAR</a>

        </div>

    </div>
</div>

<div id="frmnewalert" class="frm" *ngIf="frmoption=='alert'"
    style="position: relative; min-height: 144px;">

    <div id="frmnewalert" class="content box"
    style="opacity: 1; position: absolute;top: 0px;box-shadow: 1px 2px 2px #000;">

        <fa-icon [icon]="FAIconAlert" class="boxicon"
        style="display: block; font-size: 52px; margin: 18px 0; color:#ff9900; text-align: center;"></fa-icon>

        <!-- <fa-icon [icon]="frmFAIcon" class="pageicon"
        style="font-size: 18px; display: block; margin: 9px;  
                text-align: left; color: #333;"></fa-icon> -->
        <!-- <h1 class="srt" style="top: 16px; left: 34px;">NOVO COMUNICADO</h1> -->
        <h1 class="srt" style="position: relative;top: 16px;left: 50%;margin-left: -25%;font-family: mysansbold;padding: 9px 0;width: 50%;border: 1px solid #333;font-size: 9px;letter-spacing: 1px;">NOVO COMUNICADO</h1>
        <p class="sub" id="msgbox" style="height: 27px;"></p>
        <br/>
        <div class="frm" style="padding-bottom: 63px;">

            <input class="inputtxt" type="text" #newalert_title name="title" value="" placeholder="TÍTULO" />

            <textarea class="inputtxt txt" 
                #newalert_txt name="alerttxt" placeholder="MENSAGEM"></textarea>

            <select #newalert_plan class="inputtxt" placeholder="PLANO">
                <option value="">TODOS</option>
                <option *ngFor="let p of model_plans" [value]="p.id">{{ p.name }}</option>
            </select>

            <input class="inputtxt" type="text" #newalert_end name="dateend" 
                value="{{ model_limit | date: 'dd/MM/yyyy' }}" placeholder="DATA FINAL" />
            
            <button class="btn-splatter" (click)="goHaylie('alert')" 
                style="top: 63px; font-size: 9px; letter-spacing: 3px;">
                SALVAR</button>
            <a class="btnReturn" (click)="goHaylie('back')" >
                VOLTAR</a>

        </div>

    </div>
</div>

<div id="frmnewsuggestion" class="frm" *ngIf="frmoption=='suggestion'">
    <!-- <div id="frmnewsuggestion" class="content box"
    style="opacity: 1; position: absolute;top: 0px;box-shadow: 1px 2px 2px #000;">
         -->
        <!-- <fa-icon [icon]="FAIconLight" class="pageicon"
        style="font-size: 18px; display: block; font-size: 25px; margin-bottom: 18px; color: #111;  
                text-align: center; "></fa-icon> -->
        <h1 class="srt" style="position: relative;top: 16px;left: 50%;margin-left: -25%;font-family: mysansbold;padding: 9px 0;width: 50%;border: 1px solid #333;font-size: 9px;letter-spacing: 1px;">
            CAIXA DE SUGESTÕES</h1>
        <p class="sub" id="msgbox"
        style="
        margin-top: 45px;
        font-size: 13px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 18px;
        margin: 30px 25px 3px;
    ">Envie sua sugestão e nos ajude a melhorar sempre</p>
        <br/>
        <div class="frm" style="padding-bottom: 3px;">

            <input class="inputtxt" type="text" 
                #newsugg_title name="title" value="" placeholder="ASSUNTO" /> 

            <textarea class="inputtxt txt" style="height: 160px;"
                #newsugg_txt name="alerttxt" placeholder="MENSAGEM"></textarea>

            <button class="btn-splatter" (click)="goHaylie('suggestion')" 
                style="top: 63px; font-size: 9px; letter-spacing: 3px;">
                ENVIAR</button>
            <a class="btnReturn" (click)="goHaylie('back')" >
                VOLTAR</a>

        </div>

    <!-- </div> -->
</div>

<div id="frmnewpartner" class="frm" *ngIf="frmoption=='partner'">
    
    <fa-icon [icon]="FAIconPartner" class="boxicon"
    style="display: block; font-size: 52px; margin: 18px 0; color:#aa0033; text-align: center;"></fa-icon>

    <h1 class="srt" style="position: relative;top: 16px;left: 50%;margin-left: -25%;font-family: mysansbold;padding: 9px 0;width: 50%;border: 1px solid #333;font-size: 9px;letter-spacing: 1px;">
        FORNECEDOR</h1>
    <p class="sub" id="msgbox" style="height: 27px;"></p>
    <br/>

    <div class="frm" style="padding-bottom: 3px;min-height: 450px;">
        <input class="inputtxt" type="text" 
            #newpartner_company name="title" value="" placeholder="EMPRESA" /> 
        <input class="inputtxt" type="text" 
            #newpartner_name name="title" value="" placeholder="RESPONSÁVEL" /> 
        <input class="inputtxt" type="text" 
            #newpartner_cnpj name="title" value="" placeholder="CNPJ" /> 
        <input class="inputtxt" type="text" 
            #newpartner_mail name="title" value="" placeholder="E-MAIL" /> 
        <input class="inputtxt" type="text" 
            #newpartner_phone name="title" value="" placeholder="TELEFONE" /> 

        <button class="btn-splatter" (click)="goHaylie('partner')" 
            style="top: 63px; font-size: 9px; letter-spacing: 3px;">
            ENVIAR</button>
        <a class="btnReturn" (click)="goHaylie('partners')" >
            VOLTAR</a>

    </div>

    <!-- </div> -->
</div>

<div id="frmnewproduct" class="content box" *ngIf="frmoption=='product'"
style="opacity: 1;position: relative;top: 0px;box-shadow: 1px 2px 2px #000;z-index: 999;height: 100%;
    display: block;float: left;overflow-y: scroll;">

    <fa-icon [icon]="FAIconProduct" class="boxicon"
    style="display: block; font-size: 52px; margin: 18px 0; color:#aa0033; text-align: center;"></fa-icon>

    <h1 class="srt" style="position: relative;top: 16px;left: 50%;margin-left: -25%;font-family: mysansbold;padding: 9px 0;width: 50%;border: 1px solid #333;font-size: 9px;letter-spacing: 1px;">
        PRODUTO</h1>
    <p class="sub" id="msgbox" style="height: 27px;"></p>
    <br/>
    <div class="frm" style="padding-bottom: 63px;">

        <select #newprod_categ class="inputtxt" placeholder="CATEGORIA">
            <option value="">TODOS</option>
            <option *ngFor="let p of model_categs" [value]="p.id">{{ p.name }}</option>
        </select>
        <input class="inputtxt" type="text" 
            #newprod_name name="title" value="" placeholder="NOME" />
        <input class="inputtxt" type="text" 
            #newprod_unittype name="title" value="" placeholder="UNIDADE" />
        <input class="inputtxt" type="text" 
            #newprod_unitqtd name="title" value="" placeholder="QUANTIDADE" />
        <input class="inputtxt" type="text" 
            #newprod_stockmin name="title" value="" placeholder="ESTOQUE MÍN." />
        <input class="inputtxt" type="text" 
            #newprod_partner name="title" value="" placeholder="FORNECEDOR" />
        <input class="inputtxt" type="text" 
            #newprod_pricein name="title" value="" placeholder="PREÇO" />

        <button class="btn-splatter" (click)="goHaylie('product')" 
            style="top: 63px; font-size: 9px; letter-spacing: 3px;">
            SALVAR</button>
        <a class="btnReturn" (click)="goHaylie('products')" >
            VOLTAR</a>

    </div>

</div>
